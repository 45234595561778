import { Injectable, OnDestroy } from '@angular/core';

import { NotebookVariablesService } from '../notebook-variables/notebook-variables.service';

import { MessageService } from '../message/message.service';
import { Subscription } from 'rxjs';
import {
  ChunkContext,
  ExecutionContext,
} from '../../interfaces/chunk/chunk-context.interface';
import { FileService } from '../file/file.service';
import { RELOAD_JS_RUNTIME } from '../../constants/general.constants';
import { JsRunner, JavascriptRunnerService } from './javascript-runner.service';
import { JavaScriptHelper } from './javascript.helper';

// Services for custom methods
import { RenderService } from '../../services/render/render.service';
import { CanvasService } from '../canvas/canvas.service';
import { WasmStoreService } from '../wasm/store/wasm-store.service';
import { ArrowWebsocketService } from '../arrow-websocket/arrow-websocket.service';
import { CacheService } from '../cache/cache.service';
import { LocalforageService } from '../localforage/localforage.service';
import { FileUnifiedService } from '../file-unified/file-unified.service';
import { DuckDbService } from '../duck-db/duck-db.service';
import { ConvertService } from '../convert/convert.service';
import { WasmSearchService } from '../wasm/search/wasm-search.service';
import { WasmViewService } from '../wasm/viewer/wasm-viewer.service';
import { SimpleUIService } from '../simple-ui/simple-ui.service';

import {
  CUSTOM_METHODS_EXPORT,
  CUSTOM_METHODS_IMPORT,
} from '../../constants/additional-methods.constants';
import { GeneralHelpers } from '../../helpers/general.helper';
import { ProjectVariablesService } from '../project-variables/project-variables.service';
import { Sam2WebsocketService } from '../sam2-websocket/sam2-websocket.service';
import { NotebookDefaultFilesService } from '../notebook-default-file/notebook-default-file.service';

@Injectable({
  providedIn: 'root',
})
export class JavascriptService implements OnDestroy {
  private isLoaded: boolean = false;
  private messageShown: boolean = false;
  private messageSubscription!: Subscription;

  constructor(
    private notebookVariablesService: NotebookVariablesService,
    private messageService: MessageService,
    private fileService: FileService,
    private jsRunnerService: JavascriptRunnerService,
    private projectVariablesService: ProjectVariablesService,
    private localforageService: LocalforageService,
    // Services for custom methods
    private wasmStoreService: WasmStoreService,
    private wasmSearchService: WasmSearchService,
    private wasmViewService: WasmViewService,
    private renderService: RenderService,
    private fileUnifiedService: FileUnifiedService,
    private canvasService: CanvasService,
    private arrowWebsocketService: ArrowWebsocketService,
    private cacheService: CacheService,
    private duckDbService: DuckDbService,
    private simpleUIService: SimpleUIService,
    private convertService: ConvertService,
    private sam2WebsocketService: Sam2WebsocketService,
    private notebookDefaultFilesService: NotebookDefaultFilesService
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === RELOAD_JS_RUNTIME) {
          this.jsRunnerService.disposeRuntime();
          this.startRuntime();
        }
      });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  public async init() {
    await this.jsRunnerService.init();
    this.isLoaded = true;
  }

  private async startRuntime() {
    await this.jsRunnerService.startRuntime();
    this.isLoaded = true;
  }

  public createRunner(chunkContext: ChunkContext): JsRunner {
    const runner = this.jsRunnerService.createRunner(chunkContext.getChunk());
    JavaScriptHelper.addConsoleLog(runner.context, chunkContext);
    this.processMethodsWithGlobalNames(runner.context, chunkContext);
    JavaScriptHelper.addFetchToContext(runner.context);
    JavaScriptHelper.addPrintToContext(runner.context, chunkContext);

    return runner;
  }

  public disposeRunner(runner: JsRunner) {
    this.jsRunnerService.disposeRunner(runner);
  }

  public async runScript(
    runner: JsRunner,
    script: string,
    context: ExecutionContext
  ) {
    if (!this.isLoaded) {
      if (!this.messageShown) {
        context.addMessage('JS runtime still loading', 'info');
        this.messageShown = true;
      }
      throw new Error('JS runtime is still loading');
    }

    try {
      JavaScriptHelper.addGlobalData(
        runner.context,
        runner.chunk,
        this.notebookVariablesService
      );

      return await this.jsRunnerService.runScript(runner, script);
    } catch (error: any) {
      if (error.quickJSError) {
        context.addOutput(
          runner.context.dump(error.quickJSError),
          'error',
          'javascript'
        );
      }

      context.addMessage('Script execution failed: ' + error.message, 'danger');

      throw error;
    }
  }

  public async run(chunkContext: ChunkContext) {
    chunkContext.setBusy(true, 'executing');
    chunkContext.clearMessages();
    chunkContext.clearLogs();
    let content = this.getContentWithVariablesReplaced(
      chunkContext.getChunkContent()
    );
    let chunkData = chunkContext.getChunk();

    if (typeof content !== 'string' || !!content === false) {
      return;
    }

    if (this.isLoaded === false) {
      if (!this.messageShown) {
        chunkContext.addMessage('JS runtime still loading', 'info');
        this.messageShown = true;
      }

      return;
    }

    let jsContext = this.jsRunnerService.createContext();

    jsContext = JavaScriptHelper.addGlobalData(
      jsContext,
      chunkData,
      this.notebookVariablesService
    );
    jsContext = JavaScriptHelper.addConsoleLog(jsContext, chunkContext);
    jsContext = this.processMethodsWithGlobalNames(jsContext, chunkContext);
    jsContext = JavaScriptHelper.addFetchToContext(jsContext);
    jsContext = JavaScriptHelper.addPrintToContext(jsContext, chunkContext);

    content = await JavaScriptHelper.processGlobalModules(
      content,
      this.fileService
    );

    const code = await this.jsRunnerService.evalCodeAsync(jsContext, content);
    // let result;
    if (code.error) {
      chunkContext.addOutput(jsContext.dump(code.error), 'error', 'javascript');
      code.error && code.error.dispose();
    } else {
      chunkContext.addOutput(jsContext.dump(code.value), 'success', 'javascript');
      jsContext.unwrapResult(code).dispose();
      this.notebookVariablesService.addVarsFromRawToList(
        jsContext.getProp(jsContext.global, 'data').consume(jsContext.dump),
        chunkData
      );
    }

    jsContext.dispose();
    chunkContext.setBusy(false, 'done');
    return chunkContext.getChunk();
  }

  private processMethodsWithGlobalNames(
    jsContext: any,
    chunkContext: ChunkContext
  ) {
    const allMethods = CUSTOM_METHODS_EXPORT.concat(CUSTOM_METHODS_IMPORT);
    const globalNames = GeneralHelpers.getUniqueGlobalNames(allMethods);

    if (Array.isArray(globalNames) && globalNames.length > 0) {
      for (let index = 0; index < globalNames.length; index++) {
        const globalName = globalNames[index];
        const exportMethods = CUSTOM_METHODS_EXPORT.filter(
          (method: any) => method.globalName === globalName
        );
        const importMethods = CUSTOM_METHODS_IMPORT.filter(
          (method: any) => method.globalName === globalName
        );
        this.processCustomMethods(
          jsContext,
          importMethods,
          exportMethods,
          globalName,
          chunkContext
        );
      }
    }

    return jsContext;
  }

  private processCustomMethods(
    context: any,
    importMethods: any[],
    exportMethods: any[],
    globalName: string,
    chunkContext: ChunkContext
  ) {
    const mainHandler = context.newObject();

    // Export methods
    // Methods will be called from JS
    for (let index = 0; index < exportMethods.length; index++) {
      const element = exportMethods[index];
      const methodHandler = context.newFunction(
        element.method,
        (...args: any) => {
          const calledServiceContext = (this as any)[element.serviceToUse];

          calledServiceContext[element.methodToCall].apply(
            calledServiceContext,
            [args.map(context.dump), chunkContext, 'javascript']
          );
        }
      );

      context.setProp(mainHandler, element.method, methodHandler);

      methodHandler.dispose();
    }
    // end

    // Import methods
    // Methods will be called from C
    for (let index = 0; index < importMethods.length; index++) {
      const element = importMethods[index];
      const methodHandle = context.newAsyncifiedFunction(
        element.method,
        async (...args: any) => {
          const argsProcessed: any[] = [];

          if (args?.length > 0) {
            args.forEach((arg: any) => argsProcessed.push(context.dump(arg)));
          }

          const calledServiceContext = (this as any)[element.serviceToUse];
          const data = await calledServiceContext[element.methodToCall].call(
            calledServiceContext,
            argsProcessed,
            chunkContext
          );

          return JavaScriptHelper.createContextEntity(context, data);
        }
      );
      methodHandle.consume((fn: any) =>
        context.setProp(mainHandler, element.method, fn)
      );
    }
    // end

    context.setProp(context.global, globalName, mainHandler);

    mainHandler.dispose();

    return context;
  }

  private getContentWithVariablesReplaced(content: string) {
    const chunkContent = GeneralHelpers.replaceGlobals(
      content,
      this.projectVariablesService.variableList
    );
    return chunkContent;
  }

  private async preloadModule(moduleName: string) {
    const module = await this.localforageService.getItem(moduleName);
    if (!module) {
      const scriptContent = fetch(`assets/js/${moduleName}.min.js`).then(
        (response) => response.text()
      );
      await this.localforageService.setItem(moduleName, scriptContent);
    }
  }

  private async addScriptToContent(scriptName: string, content: string) {
    const scriptContent = await this.localforageService.getItem(scriptName);
    if (scriptContent) {
      return `${scriptContent}
            ${content}`;
    }

    return content;
  }
}
