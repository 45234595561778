import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  private loaderDelay: any;

  showLoader() {
    this.cancelDelayedLoader();

    this.loaderDelay = setTimeout(() => {
      if (this.apiCount > 0) {
        this.isLoadingSubject.next(true);
      }
    }, 3e3);

    this.apiCount++;
  }

  hideLoader() {
    this.apiCount--;
    if (this.apiCount === 0) {
      this.cancelDelayedLoader();
      this.isLoadingSubject.next(false);
    }
  }

  private cancelDelayedLoader() {
    if (this.loaderDelay) {
      clearTimeout(this.loaderDelay);
      this.loaderDelay = null;
    }
  }
}
