<form>
  <mat-form-field class="search-input no-wrapper w100">
    <input
      matInput
      appearance="outline"
      placeholder="Enter name"
      aria-label="Project"
      [matAutocomplete]="auto"
      [formControl]="projectCtrl"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      [displayWith]="displayItem"
    >
      <mat-option disabled *ngIf="noDataFound"> No data found </mat-option>
      <mat-option *ngFor="let item of filteredProjects | async" [value]="item">
        {{ item.type }}:
        <span
          ><span [innerHTML]="item.name | highlight : toHighlight"></span
        ></span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
