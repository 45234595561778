import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { environment } from '../../../environments/environment';

export const HEADER_TITLE = 'Enlighten';

// ─────────────────────────────────────────────────────────────────────────────
// Message show constants

export const ERROR_MESSAGE_CLASS = 'error-message';

export const SUCCESS_MESSAGE_CLASS = 'success-message';

export const ERROR_MESSAGE_HORIZONTAL_POSITION: MatSnackBarHorizontalPosition =
  'right';

export const ERROR_MESSAGE_VERTICAL_POSITION: MatSnackBarVerticalPosition =
  'bottom';

export const SUCCESS_MESSAGE_VERTICAL_POSITION: MatSnackBarVerticalPosition =
  'top';

export const MESSAGE_TIME = 5e3;

// ─────────────────────────────────────────────────────────────────────────────
// Side navigation constants

export const SIDE_NAV_LEFT_POSITION = 'left';

export const SIDE_NAV_RIGHT_POSITION = 'right';

export const SIDE_NAV_ACTIVE_COMPONENT_BLOCKS = 'blocks';

export const SIDE_NAV_ACTIVE_COMPONENT_FILES = 'files';

// ─────────────────────────────────────────────────────────────────────────────
// API constants

export const DEFAULT_USER_ROLE = 'user';

// ─────────────────────────────────────────────────────────────────────────────
// API constants

export const API_BASE = environment.apiUrl;

export const WSS_API_BASE = environment.wsApiUrl;

// ─────────────────────────────────────────────────────────────────────────────
// Local storage names

export const PROJECT_ID = 'project_id';
export const PROJECT_LIST = 'project_list';
export const NOTEBOOK_ID = 'notebook_id';
export const NOTEBOOK_LIST = 'notebook_list';
export const CHUNK_TYPES = 'chunk_types';
export const CHUNK_COLLAPSED = 'chunk_collapsed';
export const GLOBAL_VAR_LIST = 'global_var_list';
export const PROJECT_CONSTANT_LIST = 'project_constant_list';
export const FILE_MODULE_LIST = 'file_module_list';
export const ACTIVE_VERSION = 'active_version';
export const CACHE_ID = 'cache_id';
export const SNAPSHOT_ID = 'snapshot_id';
export const USER = 'user';
export const IS_MAIN_VERSION = 'is_main_version';
export const CURRENT_EDITOR = 'current_editor';
export const ACTIVE_CONNECTION_SETTINGS = 'active_connection_settings';
export const LEFT_PANEL_VISIBLE = 'left_panel_visible';
export const RIGHT_PANEL_VISIBLE = 'right_panel_visible';
export const RECENT_PROJECTS = 'recent_projects';

// ─────────────────────────────────────────────────────────────────────────────
// Messages

export const SYNC_CHUNKS_FROM_SERVICE = 'sync_chunks_from_service';
export const SET_PERMISSIONS = 'set_permissions';
export const RELOAD_PROJECTS = 'reload_projects';
export const RELOAD_VERSIONS = 'reload_versions';
export const ACTIVE_VERSION_CHANGED = 'active_vertions_changed';
export const RELOAD_NOTEBOOKS = 'reload_notebooks';
export const RELOAD_FILES = 'reload_files';
export const RELOAD_CHUNKS = 'reload_chunks';
export const RELOAD_GLOBAL_VAR = 'reload_global_var';
export const RELOAD_JS_RUNTIME = 'reload_js_runtime';
export const RELOAD_PROJECT_CONSTANTS = 'reload_project_constants';
export const RELOAD_WASM = 'reload_wasm';
export const APPLY_CACHE = 'apply_cache';
export const PROCESS_CHUNKS = 'process_chunks';
export const UPDATE_CHUNK_CONTENT = 'update_chunk_content';
export const INVALIDATE_CHUNKS = 'invalidate_chunks';
export const ADD_CHUNK = 'add_chunk';
export const REORDER_CHUNKS = 'reorder_chunks';
export const RUN_CHUNK = 'run_chunk';
export const SCROLL_TO_CHUNK = 'scroll_to_chunk';
export const DELETE_CHUNKS = 'delete_chunks';
export const PROJECT_FORM_UPDATE = 'project_form_update';
export const NOTEBOOK_FORM_UPDATE = 'notebook_form_update';
export const CLOSE_URL_DIALOG = 'close_url_dialog';
export const RELOAD_NOTEBOOK_FILES = 'reload_notebook_files';
export const REMOVE_DUCKDB_FILE = 'remove_duckdb_file';
export const SYNC_DUCKDB_FILES = 'sync_duckdb_files';
export const EDIT_GLOBAL_VAR = 'edit_global_var';
export const EDIT_PROJECT_CONSTANT = 'edit_project_constant';
export const UPDATE_PANEL_STATE_FROM_CACHE = 'update_panel_state_from_cache';
export const LOAD_SNAPSHOT = "load_snapshot";
export const TOGGLE_LEFT_PANEL = 'toggle_left_panel';
export const TOGGLE_RIGHT_PANEL = 'toggle_right_panel';

// ─────────────────────────────────────────────────────────────────────────────
// Default js modules

export const APACHE_ARROW = 'apache-arrow';
export const TEXT_DECODER = 'text-decoder';

// ─────────────────────────────────────────────────────────────────────────────
// Urls parts

export const CHUNKTYPES = 'chunktypes';
export const USERS = 'users';
export const PROJECT = 'project';
export const NOTEBOOK = 'notebook';
export const BOOK = 'book';
export const FSITEMS = 'fsitems';
export const FILES = 'files';
export const DIRECTORY = 'directory';
export const CHUNKS = 'chunks';
export const ORDER = 'order';
export const CACHE = 'cache';
export const ARROW = 'arrow';
export const NOTIFICATION = 'ws';
export const ROLES = 'roles';
export const PERMISSIONS = 'permissions';
export const SNAPSHOT = 'snapshot';
export const VERSION = 'v';
export const INVITES = 'invites';

// ─────────────────────────────────────────────────────────────────────────────
// WASM NAAMES
// ─────────────────────────────────────────────────────────────────────────────

export const PROJECT_FILE = 'project-file';

export const NOTEBOOK_FILE = 'notebook-file';

export const DEFAULT_CHUNK_ID = -100500;

export const FILE_EXTENSION_FROM_URL = /(?:\.([^.]+))?$/;

export const VALID_URL =
  /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

export const FILE_IN_MARKDOWN = /\!\[([^\]]*)\]\(([^)\s]+)(?:\s"([^"]*)")?\)/g;

export const CODEMIRROR_TYPE_MATCH = {
  js: 'javascript',
  json: 'javascript',
  ts: 'typescript',
  py: 'python',
  txt: 'stex',
  md: 'markdown',
};

export enum ResponseType {
  Empty = '',
  ArrayBuffer = 'arraybuffer',
  Blob = 'blob',
  Document = 'document',
  JSON = 'json',
  Text = 'text',
}

export enum FILE_TYPE {
  video = 'video',
  audio = 'audio',
  image = 'image',
  document = 'document',
  pdf = 'pdf',
  javascript = 'javascript',
  python = 'python',
  json = 'json',
  md = 'md',
  csv = 'csv',
  parquet = 'parquet',
  arrow = 'arrow',
  wasm = 'wasm',
  whl = 'whl',
  text = 'text',
  blob = 'blob',
  binary = 'binary',
  unknown = 'unknown',
}

export enum LOG_SEVERITY {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  LOG = 'log',
}

export enum FILE_EXTENSIONS {
  js = 'js',
  json = 'json',
  csv = 'csv',
  parquet = 'parquet',
  arrow = 'arrow',
  wasm = 'wasm',
  whl = 'whl',
  jpg = 'jpg',
  png = 'png',
  jpeg = 'jpeg',
  mp4 = 'mp4',
  webm = 'webm',
  ogg = 'ogg',
  ogv = 'ogv',
  mp3 = 'mp3',
  wav = 'wav',
  flac = 'flac',
}

export enum WebSocketMessages {
  CONNECT = 'Connect',
  DISCONNECT = 'Disconnect',
  LIST_FLIGHTS = 'ListFlights',
  GET_INFO = 'GetInfo',
  LIST_ACTIONS = 'ListActions',
  DO_ACTION = 'DoAction',
  READ = 'Read',
  PUT_STREAM = 'PutStream',
  UPLOAD_FILE = 'UploadFile',
}

export enum WebSocketResponses {
  ERROR = 'Error',
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
  FLIGHT_LIST = 'FlightList',
  START_BINARY = 'StartBinary',
  UPLOAD_COMPLETE = 'UploadComplete',
  CONTINUE = 'Continue',
  SUCCESS = 'Success',
}

export enum NotificationWebsocketMessages {
  LIST_CHANNELS = 'list-channels',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  LIST_SUBSCRIPTIONS = 'list-subscriptions',
  GET_ENTRIES = 'get-entries',
  MARK_SEEN = 'mark-seen',
  CHANNEL_LIST = 'channel-list',
  NEW_ENTRIES = 'new-entries',
  LOCK_EDITOR = 'lock-editor',
  UNLOCK_EDITOR = 'unlock-editor',
  CURRENT_EDITOR = 'current-editor',
  WATCH_EDITORS = 'watch-editors',
}

export const DUCKDB_IMPORT_FILES_ALLOWED = [
  FILE_EXTENSIONS.json,
  FILE_EXTENSIONS.parquet,
  FILE_EXTENSIONS.csv,
];

// ─────────────────────────────────────────────────────────────────────────────

export const MIME_TYPES: any = {
  html: 'text/html',
  css: 'text/css',
  js: 'application/javascript',
  json: 'application/json',
  png: 'image/png',
  jpg: 'image/jpeg',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  ico: 'image/x-icon',
  pdf: 'application/pdf',
  xml: 'application/xml',
  zip: 'application/zip',
  txt: 'text/plain',
  csv: 'text/csv',
  parquet: 'application/octet-stream',
  arrow: 'application/octet-stream',
  wasm: 'application/octet-stream',
  whl: 'application/octet-stream',
};

export const DATA_TYPES: any = {
  na: 0,
  bool: 1,
  uint8: 2,
  int32: 7,
  int64: 9,
  float: 11,
  double: 12,
  string: 13,
  binary: 14,
  timestamp: 18,
  list: 25,
};

export const OBJECT_TYPES: any = {
  scalar: 0,
  array: 1,
  tensor: 2,
  table: 3,
};

export const DEFAULT_IMAGE_SRC =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0NDQ0NDQ0PDg0NDQ0NDQ0NDQ8NDQ4NFhEWFhURExMYHSggGBolGxUWITEhJSkrLi4uFx8zODMsNygtLisBCgoKBQUFDgUFDisZExkrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAJ8BPgMBIgACEQEDEQH/xAAaAAEBAQEBAQEAAAAAAAAAAAAAAgEDBAUH/8QAMBABAQACAAIGCAcBAQAAAAAAAAECEQMEEiExQVJxFTJRYWKRobEFExQigZKi0XL/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A/RAAAAAAAAAADQ0GaNNAZo0oBOjSzQI0adDQOejTpo0Dno0vRoEaNLYCdGlMBg2sAAAAAAAAAAAAAAAAAAAAAayKBjRoDdEipATpulaboE6NLkNAjRpejQIZpemaBGmLsZYCBVTQZWNYAAAAAAAAAAAAAAAAAAAABFJioDWyEVAFSEipAZMVSNkVICZG6Xo0COizTthw7l1SW+UdOLyuWGPSuu3Wu2wHk0yx1sTYDlYyulibAc9JrpU0EJVUgAAAAAAAAAAAAAAAAAAAARcRF4gqKjIuA2RWMZHfg8HLL1cbff3fMESKmL28LkPHf4x/69Mxw4fZjd+7G2/MHi4XKZ5d2p7+p6ceUwx68rvz6ozPj8S+rhZ77La4ZY53rsyvnKD0Zc1hj1YzflNRWN/N4dl7bueV7nk/Ly8N+Vd+U3LZZdWey9oPBcU2PbzXBvStktl6+qb63C8HPwZf1oPNYmx1sRYDlU10sRkDlUrqAAAAAAAAAAAAAAAAAAAAAI6YucdMQXF4oi8Qev8AD5jc9ZSXc6t+17+Z5n8vUmPbOr2Pk8PKyyztllj6nN49PhzOd2sv47wOT4uWeWXSvdNTuis+a6Ns6O9X2uX4d25eUc+P6+XmD0fq/h+p+q+H6rnLY613+3byZTVs9lsB6f1Xw/Vl5v4fq8yuJhcdb75sHa858P1duX43T31a179vn16uQ7MvOfYHg4vrZed+7lXbi+tfO/dxoIrnXSudBzyRV5IoAAAAAAAAAAAAAAAAAAAAEdMXOOmILi8XOLgLj6n4fn0sLhe77V8qPVyXF6OePsv7b/IPXyWHRzzxvc48f18vN75w9Z3L24yXzj5/Mevl5g7Y8zlJrq8+9y2rluF07u+rO2+33PTxOWl7P2/YHPluFu7vZOz316eJw5lOvuVjjJJJ2RoPnc1h0curss3HbkOzLzn2bz+O8Zl7L9Kn8OvVl5wHh4vrXzv3csl8W/uy8793Ogioq7UZA55IXkgAAAAAAAAAAAAAAAAAAAACLiIqAuKiIqA6Sqlc5VSg+7yvE6eGN79avnHh5jDLp5axys32yVz5Tm/y5ZZuXr7dar0+kp4L/YDHmOJJqcPUnw5N/VcXwf5yPSM8H1PSM8F+YN/VcTwf5yZ+q4vg/wA5HpGeC/M9IzwfUE8Tj8TKWXDqvV6uTr+HY2TLcs652zTn6SngvzZ6Tngv9geHi392X/q/dztbnlu2+22otBlTk2poIqVVIAAAAAAAAAAAAAAAAAAAAEVEqBqpUNBcqtucqtguVUrnK3YOkrduezYOmzbn0i0FbZck7ZsG2stZtloFqaUBiWsAAAAAAAAAAAAAAAAAAAAAaxoDWAKakBcptICzaGgrZtIDdm07Ng3bGAAMArG1gAAAAAAAAAAAAAAAAAAAABsAaMAaMAUJAUJAUJAUJAaMAaMAbWAAAAAAAAAAAD//2Q==';

export enum Panels {
  manageBlock = 'manageBlock',
  notebookVarList = 'notebookVarList',
  snapshotList = 'snapshotList',
  notebookFiles = 'notebookFiles',
  projectList = 'projectList',
  moduleList = 'moduleList',
  projectFiles = 'projectFiles',
  projectVarList = 'projectVarList',
  connections = 'connections',
  versionList = 'versionList',
}
// ─────────────────────────────────────────────────────────────────────────────
// https://codemirror.net/5/doc/manual.html#option_mode
// The value "null" indicates no highlighting should be applied.
// Alternatively, it may be an object containing configuration options for the mode,
// with a name property that names the mode (for example {name: "javascript", json: true}).
