<form name="projectForm" [formGroup]="projectForm" novalidate
  (ngSubmit)="processProjectForm(projectForm.value, projectForm.valid, $event)" [fxLayout]="formAlign"
  fxLayoutAlign="{{formAlign === 'row' ? 'start center' : 'start start'}}" fxLayoutGap="30px">
  <mat-form-field appearance="outline" class="no-wrapper" [ngClass]="{'w100': formAlign === 'column'}">
    <mat-label>Enter project’s name</mat-label>
    <input matInput formControlName="name" />
    <mat-icon matPrefix fontSet="material-icons-outlined">
      description
    </mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" class="no-wrapper" [ngClass]="{'w100': formAlign === 'column'}">
    <mat-label>Enter project’s description</mat-label>
    <input matInput formControlName="description" />
    <mat-icon matPrefix fontSet="material-icons-outlined">
      description
    </mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" class="no-wrapper" *ngIf="workMode === 'create'"
    [ngClass]="{'w100': formAlign === 'column'}">
    <mat-label>Enter first notebook name</mat-label>
    <input matInput formControlName="notebookName" />
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">
    <button type="submit" mat-stroked-button color="primary">
      {{ workMode === "create" ? "Create" : "Update" }} project
    </button>
    <button type="button" mat-stroked-button color="warn" *ngIf="workMode === 'update'"
      (click)="openDeleteConfirmationPopup()">
      Delete
    </button>
    <button type="button" mat-stroked-button color="accent" *ngIf="workMode === 'update'" (click)="cancel()">
      Cancel
    </button>
  </div>
</form>