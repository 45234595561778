<div id="splash-screen" *ngIf="(loadingService.isLoading$ | async)">
  <div class="center">
    <div class="logo">
      <img width="300" alt="logo" src="assets/icons/logo.svg" />
    </div>
    <div class="spinner-wrapper">
      <div class="spinner">
        <div class="inner">
          <div class="gap"></div>
          <div class="left">
            <div class="half-circle"></div>
          </div>
          <div class="right">
            <div class="half-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
