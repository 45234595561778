import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChunkDialogComponent } from './chunk-dialog.component';
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ────────────────────────────────────────────────────────────────────────────────
// Form module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Code editor
import { CodeEditorModule } from '../code-editor/code-editor.module';

@NgModule({
  imports: [
    CommonModule,
    // Materail
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    // Code editor
    CodeEditorModule,
    // Layout
    FlexLayoutModule,
    // Form module
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [ChunkDialogComponent],
  exports: [ChunkDialogComponent],
})
export class ChunkDialogModule {}
