<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>

  <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="30px">
    <mat-card-content class="w100">
      <mat-form-field
        class="w100"
        [ngClass]="{ loading: cdnModuleLoadById }"
        appearance="outline"
        [hidden]="!(sourceMode === 'from_cdn_js')"
      >
        <mat-label>CDN JS autocomplete</mat-label>
        <input
          type="text"
          placeholder="Start type lib name"
          aria-label="Cdn module library list"
          matInput
          [matAutocomplete]="cdnModuleAutocomplete"
          #cdnModuleAutocompleteInput
        />
        <mat-autocomplete
          #cdnModuleAutocomplete="matAutocomplete"
          [displayWith]="displayCdnModule"
        >
          <mat-option
            disabled
            class="loading pt-3 pb-3"
            *ngIf="cdnModuleLoading"
          >
          </mat-option>
          <mat-option disabled *ngIf="noCdnModuleFound">
            No module found
          </mat-option>
          <mat-option
            *ngFor="let cdnModule of cdnModuleAutocompleteData | async"
            [value]="cdnModule"
          >
            {{ cdnModule.latest }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w100"
        [hidden]="!(sourceMode === 'url')"
      >
        <mat-label>Paste direct module url</mat-label>
        <input matInput placeholder="url" #urlInput />
      </mat-form-field>

      <mat-form-field
        class="w100"
        [ngClass]="{ loading: jsdelivrModuleLoadById }"
        appearance="outline"
        [hidden]="!(sourceMode === 'from_js_delivr')"
      >
        <mat-label>Js Delivr autocomplete</mat-label>
        <input
          type="text"
          placeholder="Start type lib name"
          aria-label="Cdn module library list"
          matInput
          [matAutocomplete]="jsdelivrModuleAutocomplete"
          #jsdelivrModuleAutocompleteInput
        />
        <mat-autocomplete
          #jsdelivrModuleAutocomplete="matAutocomplete"
          [displayWith]="displayJsdelivrModule"
          (optionSelected)="setJsDelivrValue($event)"
        >
          <mat-option
            disabled
            class="loading pt-3 pb-3"
            *ngIf="jsdelivrModuleLoading"
          >
          </mat-option>
          <mat-option disabled *ngIf="noJsdelivrModuleFound">
            No module found
          </mat-option>
          <mat-option
            *ngFor="let cdnModule of jsdelivrModuleAutocompleteData | async"
            [value]="cdnModule"
          >
            {{ cdnModule.links.self }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        class="w100"
        [ngClass]="{ loading: pyPiModuleLoadById }"
        appearance="outline"
        [hidden]="!(sourceMode === 'py_pi')"
      >
        <mat-label>PyPi autocomplete</mat-label>
        <input
          type="text"
          placeholder="Start type lib name"
          aria-label="Cdn module library list"
          matInput
          [matAutocomplete]="pyPiModuleAutocomplete"
          #pyPiModuleAutocompleteInput
        />
        <mat-autocomplete
          #pyPiModuleAutocomplete="matAutocomplete"
          [displayWith]="displayPyPiModule"
          (optionSelected)="setPyPiValue($event)"
        >
          <mat-option
            disabled
            class="loading pt-3 pb-3"
            *ngIf="pyPiModuleLoading"
          >
          </mat-option>
          <mat-option disabled *ngIf="noPyPiModuleFound">
            No module found
          </mat-option>
          <mat-option
            *ngFor="let cdnModule of pyPiModuleAutocompleteData | async"
            [value]="cdnModule"
          >
            {{ cdnModule.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div>
        <mat-radio-group [(ngModel)]="sourceMode">
          <mat-radio-button
            *ngFor="let source of sources"
            [checked]="source.value === sourceMode"
            [value]="source.value"
          >
            {{ source.viewValue }}
          </mat-radio-button>
        </mat-radio-group>
        <!-- <mat-checkbox (change)="updateIsGlobal($event)">
          Add to module list
          <br />
          <small><i>(If active module appears in module list)</i></small>
        </mat-checkbox> -->
      </div>
    </mat-card-content>

    <mat-card-actions [align]="'end'" class="w100">
      <button
        mat-stroked-button
        color="primary"
        *ngIf="data?.confirmCaption"
        class="mr-2"
        (click)="processData()"
      >
        {{ data.confirmCaption }}
      </button>
      <button
        mat-stroked-button
        color="warn"
        [mat-dialog-close]="false"
        *ngIf="data?.cancelCaption"
      >
        {{ data.cancelCaption }}
      </button>
    </mat-card-actions>
  </div>
</mat-card>
