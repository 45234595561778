import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import {
  AuthConfig,
  OAuthService,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, filter, first, firstValueFrom, from, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { User } from '../../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.userSubject.asObservable();

  private auth0Service = inject(AuthService);
  private oauthService = inject(OAuthService);
  private router = inject(Router);

  private zitadelConfig: AuthConfig = {
    issuer: environment.auth.zitadel.issuer,
    redirectUri: environment.auth.zitadel.redirectUri,
    clientId: environment.auth.zitadel.clientId,
    scope: 'openid profile email offline_access',
    responseType: 'code',
    oidc: true,
    requireHttps: environment.auth.zitadel.requireHttps,
    showDebugInformation: environment.auth.zitadel.showDebugInformation,
    postLogoutRedirectUri: environment.auth.zitadel.postLogoutRedirectUri,
    strictDiscoveryDocumentValidation: false,
  };

  private loggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn = this.loggedIn.asObservable();

  constructor() {
    this.initializeAuth();
  }

  private initializeAuth(): void {
    if (environment.authProvider === 'zitadel') {
      this.initializeZitadel();
    } else if (environment.authProvider === 'auth0') {
      this.initializeAuth0();
    }
  }

  private initializeZitadel(): void {
    this.oauthService.configure(this.zitadelConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe(() => {
        this.loadZitadelUserProfile();
      });

    this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
      if (this.oauthService.hasValidAccessToken()) {
        this.loadZitadelUserProfile();
      } else {
        this.router.navigate(['/not-authenticated']);
      }
    });
  }

  private initializeAuth0(): void {
    this.auth0Service.user$.subscribe((user) => {
      this.userSubject.next(user as User);
      this.loggedIn.next(!!user);
    });
  }

  async authenticate(): Promise<boolean> {
    if (environment.authProvider === 'zitadel') {
      return this.authenticateZitadel();
    } else if (environment.authProvider === 'auth0') {
      return this.authenticateAuth0();
    }
    return false;
  }

  private async authenticateZitadel(): Promise<boolean> {
    if (this.oauthService.hasValidAccessToken()) {
      await this.loadZitadelUserProfile();
      return true;
    }

    return new Promise<boolean>((resolve) => {
      this.oauthService.events
        .pipe(
          filter((e) => e.type === 'token_received'),
          first()
        )
        .subscribe(() => {
          this.loadZitadelUserProfile().then(() => resolve(true));
        });

      this.oauthService.initLoginFlow();
    });
  }

  private async authenticateAuth0(): Promise<boolean> {
    const isAuthenticated =
      await this.auth0Service.isAuthenticated$.toPromise();
    if (!isAuthenticated) {
      await this.auth0Service.loginWithRedirect();
      return false;
    }
    return true;
  }

  private async handleSuccessfulLogin(): Promise<boolean> {
    if (this.oauthService.hasValidAccessToken()) {
      const user = this.oauthService.getIdentityClaims();
      const roleKey = `urn:zitadel:iam:org:project:${environment.auth.zitadel.projectId}:roles`;
      if (
        Object.prototype.hasOwnProperty.call(user, roleKey) &&
        user[roleKey]['Admin']
      ) {
        this.loggedIn.next(true);
        await this.loadZitadelUserProfile();
        return true;
      }
    }
    this.router.navigate(['/not-authenticated']);
    return false;
  }

  private async loadZitadelUserProfile(): Promise<void> {
    const userProfile = await this.oauthService.loadUserProfile();
    this.userSubject.next(userProfile as User);
  }

  getUser(): Observable<User | null> {
    return this.user$;
  }

  getAuth0User(): Observable<any> {
    return this.auth0Service.user$;
  }

  getZitadelUser(): Observable<any> {
    if (!this.oauthService.hasValidAccessToken()) {
      return of(null);
    }
    return from(this.oauthService.loadUserProfile());
  }

  async isAuthenticated(): Promise<boolean> {
    if (environment.authProvider === 'zitadel') {
      return this.oauthService.hasValidAccessToken();
    } else if (environment.authProvider === 'auth0') {
      let isAuthenticated = false;

      try {
        isAuthenticated = await firstValueFrom(this.auth0Service.isAuthenticated$);
      } catch (error) {
        console.error(error);
      }

      return isAuthenticated;
    }
    return false;
  }

  logout(): void {
    if (environment.authProvider === 'zitadel') {
      this.oauthService.logOut();
    } else if (environment.authProvider === 'auth0') {
      this.auth0Service.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
    this.userSubject.next(null);
    this.loggedIn.next(false);
    this.router.navigate(['/not-authenticated']);
  }

  // Debugging method
  debugTokens(): void {
    console.log('Access Token:', this.oauthService.getAccessToken());
    console.log('ID Token:', this.oauthService.getIdToken());
    console.log('Refresh Token:', this.oauthService.getRefreshToken());
    console.log('Expiration:', this.oauthService.getAccessTokenExpiration());
    console.log('Valid?', this.oauthService.hasValidAccessToken());
  }
}
