import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageTeamComponent } from './manage-team.component';
//------------------------------------------------------------------------------
// Material
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
// ─────────────────────────────────────────────────────────────────────────────
// Form support
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// ─────────────────────────────────────────────────────────────────────────────
// Layput
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatDividerModule,
    MatTooltipModule,
    // Form support
    FormsModule,
    ReactiveFormsModule,

    // Layout
    FlexLayoutModule,
  ],
  declarations: [ManageTeamComponent],
  exports: [ManageTeamComponent],
})
export class ManageTeamModule {}
