import { Injectable, computed, inject } from '@angular/core';
import { GlobalVar } from '../../interfaces/global-var.interface';
import {
  EDIT_GLOBAL_VAR,
  GLOBAL_VAR_LIST,
  RELOAD_GLOBAL_VAR,
} from '../../constants/general.constants';
import { GeneralHelpers } from '../../helpers/general.helper';
import { NotebookService } from '../notebook/notebook.service';
import { MessageService } from '../message/message.service';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { LocalforageService } from '../localforage/localforage.service';
import { Notebook } from '../../interfaces/project.interface';
import { AppStateService } from '../app-state/app-state.service';
import { ProjectService } from '../project/project.service';
import { PermissionId } from '../../interfaces/global-role.interface';

@Injectable({
  providedIn: 'root',
})
export class NotebookVariablesService {
  #appState = inject(AppStateService);
  #projectService = inject(ProjectService);

  public variableList: GlobalVar[] | any = [];
  public globalVarsShown: boolean = true;
  public firstTime: boolean = true;
  private hasWritePermission = computed(() =>
    this.#projectService.getCurrentUserHasProjectPermission(PermissionId.WRITE)
  );
  private messageSubscription!: Subscription;

  constructor(
    private notebookService: NotebookService,
    private messageService: MessageService,
    private localForageService: LocalforageService,
  ) {
    this.getNotebookVariablesFromServer();
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === RELOAD_GLOBAL_VAR) {
          this.clean();
          this.getNotebookVariablesFromServer();
        }
      });
  }

  // Add
  public add(globalVar: GlobalVar): GlobalVar[] {
    const existingIndex = this.getGlobalVarIndex(globalVar.id);
    if (existingIndex > -1) {
      this.update(globalVar.id, globalVar);
    } else {
      this.variableList.push(globalVar);
      this.saveVariables();
    }
    return this.variableList;
  }

  // Update
  public update(id: string, data: GlobalVar): GlobalVar[] {
    const index = this.getGlobalVarIndex(id);
    if (index > -1) {
      Object.assign(this.variableList[index], data);
      this.saveVariables();
    }
    return this.variableList;
  }

  // Delete
  public delete(id: string): GlobalVar[] {
    const index = this.getGlobalVarIndex(id);
    if (index > -1) {
      this.variableList.splice(index, 1);
      this.saveVariables();
    }
    return this.variableList;
  }

  // Edit
  public edit(id: string) {
    const index = this.getGlobalVarIndex(id);
    if (index > -1) {
      this.messageService.sendMessage(EDIT_GLOBAL_VAR, {
        variable: this.variableList[index],
        index: index,
      });
    }
  }

  public deleteGlobalVarsByChunkId(chunkId: string): boolean {
    if (!chunkId) {
      return false;
    }

    const variablesToRemove = this.variableList?.filter(
      (variable: GlobalVar) => chunkId === `${variable.chunkId}`
    );

    if (variablesToRemove?.length > 0) {
      variablesToRemove.forEach((variable: GlobalVar) =>
        this.delete(variable.id)
      );

      return true;
    }

    return false;
  }

  public addVarsFromRawToList(data: any, chunkData: Chunk): void {
    if (GeneralHelpers.detectEmptyObject(data)) {
      return;
    }

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        const type = GeneralHelpers.trueTypeOf(value);

        if (
          ['number', 'string', 'boolean', 'null', 'undefined'].every(
            (typeNotation) => typeNotation !== type
          ) &&
          !!data[key] === false
        ) {
          return;
        }

        const existingIndex = GeneralHelpers.findIndexInArrayByProp(
          this.variableList,
          'name',
          key
        );

        if (existingIndex > -1) {
          Object.assign(this.variableList[existingIndex], {
            name: key,
            value: data[key],
            id: this.variableList[existingIndex].id,
            sortOrder: this.variableList[existingIndex].sortOrder,
            chunkId: this.variableList[existingIndex].chunkId,
            disabled: false,
          });
        } else {
          this.variableList.push({
            name: key,
            value: data[key],
            id: GeneralHelpers.getUuid(),
            sortOrder: chunkData.sortOrder,
            chunkId: chunkData.chunkId,
            disabled: false,
          } as GlobalVar);
        }
      }
    }

    this.saveVariables();
  }

  public syncWithChunkList(chunks: Chunk[]) {
    if (this.variableList?.length > 0) {
      for (let index = 0; index < this.variableList.length; index++) {
        const element: GlobalVar = this.variableList[index];
        const chunk = GeneralHelpers.findElementInArrayByProp(
          chunks,
          'chunkId',
          element.chunkId
        );

        if (chunk !== undefined) {
          element.sortOrder = chunk.sortOrder;
        }
      }

      this.saveVariables();
    }
  }

  // Get Global Var Index
  private getGlobalVarIndex(id: string): number {
    return this.variableList.findIndex((item: GlobalVar) => item.id === id);
  }

  public getGlobalVarByName(name: string): GlobalVar | null {
    const element = this.variableList.find(
      (globalVar: GlobalVar) => globalVar.name === name
    );
    return element || null;
  }

  public getGlobalVarValueDeep(keyString: string): any {
    const dot = '.';
    const dataKey = 'data';
    const splittedKeys = keyString.split(dot);
    if (splittedKeys?.length > 0) {
      const firstElement = splittedKeys.shift();
      if (firstElement !== dataKey && firstElement !== undefined) {
        splittedKeys.unshift(firstElement);
      }
    }
    const globalVarName = splittedKeys[0];
    const globalVar = this.getGlobalVarByName(globalVarName);
    if (splittedKeys?.length > 1) {
      splittedKeys.shift();
      return _.get(globalVar?.value, splittedKeys.join(dot));
    }
    return globalVar ? globalVar.value : globalVar;
  }

  public saveVariables() {
    if (this.#appState.isHeadVersion() && this.hasWritePermission()) {
      this.setNotebookVariablesToServer();
    } else {
      this.setNotebookVariablesToLocalStorage();
    }
  }

  public setNotebookVariablesToLocalStorage(
    variableList: GlobalVar[] | any = this.variableList,
    notebookId: string | any = this.#appState.notebookId().toString()
  ) {
    if (!notebookId || !notebookId?.includes('Object')) {
      return;
    }
    this.localForageService.setItem(
      `${GLOBAL_VAR_LIST}_${notebookId}`,
      GeneralHelpers.jsonStringify(variableList)
    );
  }

  // private getNotebookVariablesFromLocalStorage() {
  //   const notebookId = this.#appState.notebookId();
  //   if (!notebookId) {
  //     return;
  //   }
  //   this.localForageService
  //     .getItem(`${GLOBAL_VAR_LIST}_${notebookId}`)
  //     .then((data: any) => {
  //       this.variableList = GeneralHelpers.jsonParse(data);
  //     });
  // }

  private setNotebookVariablesToServer(
    variableList: GlobalVar[] | any = this.variableList,
    notebookId: string | any = this.#appState.notebookId().toString()
  ) {
    this.notebookService
      .update(
        {
          name: this.#appState.notebook()!.name,
          defaultState: JSON.stringify(variableList),
        },
        notebookId,
        this.#appState.projectId().toString()
      )
      .subscribe({
        next: (data: Notebook | any) => {
          this.setNotebookVariablesToLocalStorage(data);
        },
        error: () => {
          // Handle error
        },
      });
  }

  private getNotebookVariablesFromServer(
    notebookId: string | undefined = this.#appState.notebookId().toString(),
    projectId: string | undefined = this.#appState.projectId().toString()
  ) {
    const hasBothParams = !!notebookId && !!projectId;
    const areZero = notebookId == '0' && projectId == '0';
    if (!hasBothParams || areZero) {
      return;
    }
    this.notebookService.getById(notebookId, projectId).subscribe({
      next: (data: Notebook | any) => {
        if (data?.defaultState) {
          if (!GeneralHelpers.isJSON(data.defaultState)) {
            return;
          }
          this.variableList = GeneralHelpers.jsonParse(data.defaultState);
          this.setNotebookVariablesToLocalStorage(
            notebookId,
            this.variableList
          );
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  public clean() {
    this.variableList.length = 0; // Clear the array without recreating it
  }
}
