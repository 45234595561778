import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Component
import { DuckDbControldDialogComponent } from './duck-db-controls-dialog.component';
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    // Layout
    FlexLayoutModule,
  ],
  declarations: [DuckDbControldDialogComponent]
})
export class DuckDbControldDialogModule { }
