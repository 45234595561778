// TODO Which property is correct ?
export interface FileItem {
  fsitemId: number;
  notebookId: number;
  name: string;
  isDirectory: boolean;
  version: number;
  modifiedAt: Date;
  createdAt: Date;
  filesize: any;

  child_ids?: number[];
  childIds?: number[];

  level?: number | any;
  expandable?: boolean | any;
  children?: FileItem[];

  isInModuleList?: boolean;

  childProp?: 'child_ids' | 'childIds' | any;

  isGlobal?: boolean;

  fileType?: string;
}

export interface FileModule {
  name: string;
  fsitemId: string | any;
  notebookId: string | any;
  isGlobal: boolean;
  extension: string;
}

export interface NotebookFile {
  fileId: number;
  cacheId: number;
  name: string;
  filesize: number;
  modifiedAt: Date;
  fileType?: string;
  projectId?: string | null;
  versionId?: string | null;
  notebookId?: string | null;
  snapshotId?: string | null;
  etag?: string | null;
}

export interface NotebookDefaultFile {
  fileId: number;
  notebookId: number;
  name: string;
  etag?: string;
  filesize: number;
  modifiedAt?: string;
}
