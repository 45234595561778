export interface CdnJsModuleResult {
  name: string;
  latest: string;
  version: string;
}

export interface CdnJsModule {
  results: CdnJsModuleResult[];
  total: number;
  available: number;
}

export interface Tags {
  beta: string;
  latest: string;
}

export interface Links {
  self: string;
  entrypoints: string;
  stats: string;
}

export interface Version {
  version: string;
  links: Links;
}

export interface JsDelivrModuleResult {
  type: string;
  name: string;
  tags: Tags;
  versions: Version[];
  links: Links;
}

export interface PyPiModuleResult {
  [key: string]: any;
}
