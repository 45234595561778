<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>

  <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="30px">
    <mat-card-content class="w100">
      <app-notebook-form
        *ngIf="data.dialogData.activeForm === 'notebookCreate'"
        formAlign="column"
        workMode="create"
      ></app-notebook-form>
      <app-notebook-form
        *ngIf="data.dialogData.activeForm === 'notebookEdit'"
        formAlign="column"
        workMode="update"
      ></app-notebook-form>
      <app-project-form
        *ngIf="data.dialogData.activeForm === 'projectCreate'"
        [project]="data.dialogData?.model"
        formAlign="column"
        workMode="create"
      ></app-project-form>
      <app-project-form
        *ngIf="data.dialogData.activeForm === 'projectEdit'"
        [project]="data.dialogData?.model"
        formAlign="column"
        workMode="update"
      ></app-project-form>
    </mat-card-content>
  </div>
</mat-card>
