import { Component, Input, OnInit, computed } from '@angular/core';
import { SimpleAlertOptions, SimpleAlertOptionsDefaults } from '../simple-ui.service';
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'app-simple-alert',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './simple-alert.component.html',
  styleUrl: './simple-alert.component.scss'
})
export class SimpleAlertComponent implements OnInit {
  ngOnInit() {
    if (this.options.defaultInput) {
      this.inputText = this.options.defaultInput;
    }
  }

  @Input() options: SimpleAlertOptions = SimpleAlertOptionsDefaults;
  @Input() exitModal = (ok: string|undefined) => { };
  @Input() promptForInput = false;
  inputText = '';

  okDisabled = computed(() => {
    return this.promptForInput && this.inputText.length === 0;
  });

  public ok() {
    this.exitModal(this.promptForInput ? this.inputText : 'true');
  }

  public cancel() {
    this.exitModal(undefined);
  }
}
