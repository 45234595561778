import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './layout/header/header.module';
import { FooterModule } from './layout/footer/footer.module';
import { SpinnerModule } from './layout/spinner/spinner.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { DirectivesModule } from './shared/directives/directives.module';
import { JavascriptService } from './shared/services/javascript/javascript.service';
import { PythonService } from './shared/services/python/python.service';
import { DuckDbService } from './shared/services/duck-db/duck-db.service';
import { NotificationWebsocketService } from './shared/services/notification-websocket/notification-websocket.service';
import { Sam2WebsocketService } from './shared/services/sam2-websocket/sam2-websocket.service';
import { PermissionsService } from './shared/services/permissions/permissions.service';

import { Auth0Module } from './auth-modules/auth0.module';
import { ZitadelModule } from './auth-modules/zitadel.module';

export function initializePermissions(
  permissionsService: PermissionsService
): () => Promise<void> {
  return () =>
    new Promise((resolve, reject) => {
      permissionsService.getRoles().subscribe({
        next: () => {
          permissionsService.getPermissions().subscribe({
            next: () => resolve(),
            error: (err) => reject(err),
          });
        },
        error: (err) => reject(err),
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HeaderModule,
    FooterModule,
    SpinnerModule,
    SharedModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
    }),
    DirectivesModule,
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    GuiGridModule,
    Auth0Module,
    ZitadelModule,
  ],
  providers: [
    JavascriptService,
    {
      provide: APP_INITIALIZER,
      useFactory: (javascriptService: JavascriptService) => () =>
        javascriptService.init(),
      deps: [JavascriptService],
      multi: true,
    },
    PythonService,
    {
      provide: APP_INITIALIZER,
      useFactory: (pythonService: PythonService) => () => pythonService.init(),
      deps: [PythonService],
      multi: true,
    },
    DuckDbService,
    {
      provide: APP_INITIALIZER,
      useFactory: (duckDbService: DuckDbService) => () => duckDbService.init(),
      deps: [DuckDbService],
      multi: true,
    },
    PermissionsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializePermissions,
      deps: [PermissionsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (notificationWebsocketService: NotificationWebsocketService) => () =>
          notificationWebsocketService.init(),
      deps: [NotificationWebsocketService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (sam2WebsocketService: Sam2WebsocketService) => () =>
          sam2WebsocketService.init(),
      deps: [Sam2WebsocketService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
