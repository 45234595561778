export const JAVASCRIPT_ACTIONS = [
  {
    icon: 'data_object',
    action: 'js-beautify',
    tooltip: 'format code',
    color: 'primary',
    aria: 'format code',
    testId: 'js-beautify'
  },
  {
    icon: 'remove',
    action: 'clear-code',
    tooltip: 'clear code',
    color: 'primary',
    aria: 'clear code',
    testId: 'clear-code'
  },
]