import { Component, Input, OnInit, inject } from '@angular/core';

import { MessageService } from '../../services/message/message.service';
import { NotebookService } from '../../services/notebook/notebook.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Notebook } from '../../interfaces/project.interface';
import { GeneralHelpers } from '../../helpers/general.helper';

import { Subscription } from 'rxjs';
import { DialogService } from '../../services/dialog/dialog.service';
import { NOTEBOOK_FORM_UPDATE, RELOAD_NOTEBOOKS, RELOAD_FILES, RELOAD_CHUNKS, RELOAD_GLOBAL_VAR, RELOAD_JS_RUNTIME, RELOAD_WASM } from '../../constants/general.constants';
import { AppStateService } from '../../services/app-state/app-state.service'

@Component({
  selector: 'app-notebook-form',
  templateUrl: './notebook-form.component.html',
  styleUrls: ['./notebook-form.component.scss']
})
export class NotebookFormComponent implements OnInit {
  #appState = inject(AppStateService);

  @Input() workMode: 'create' | 'update' = 'create';

  @Input() formAlign: 'row' | "column" = 'row';

  @Input() notebook: Notebook | any;

  public notebookForm!: FormGroup;

  public messageSubscription!: Subscription;

  constructor(private messageService: MessageService,
    private notebookService: NotebookService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,

    private dialogService: DialogService) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && (message.text === NOTEBOOK_FORM_UPDATE)) {
          this.updateForm(this.notebookService.getSelectedNotebook());
        }
      });
  }

  ngOnInit() {
    this.initForm();
    if (this.workMode === 'update') {
      this.updateForm(this.notebookService.getSelectedNotebook());
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // Notebook form

  private initForm() {
    this.notebookForm = this._formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  public processNotebookForm(formValue: any, isValid: boolean, $event: Event) {
    $event.preventDefault();
    if (!isValid) {
      return;
    }


    if (this.workMode === 'create') {
      this.notebookService.create(
        {
          name: formValue.name,
          chunkName: 'Welcome',
          chunkTypeId: 1,
        },
        String(this.#appState.projectId()),
      ).subscribe({
        next: (data: Notebook | any) => {
          this.notebookCallback(data?.notebook);
        },
        error: () => {

        }
      })
    } else if (this.workMode === 'update') {
      this.notebookService.update(
        {
          name: formValue.name,
          defaultState: this.notebookService.getSelectedNotebook().defaultState || ''
        },
        String(this.#appState.notebookId()),
        String(this.#appState.projectId()),
      ).subscribe({
        next: (data: Notebook | any) => {
          this.notebookCallback(data);
        },
        error: () => {

        }
      })
    }
  }

  private updateForm(data: Notebook) {
    this.notebookForm.get('name')?.setValue(data.name);
    this.workMode = 'update';
  }

  private notebookCallback(data?: Notebook) {
    if (data) {
      this.notebookService.setNotebookId(Number(data.notebookId));
    }

    this.messageService.sendMessage(RELOAD_NOTEBOOKS);
    this.messageService.sendMessage(RELOAD_FILES);
    this.messageService.sendMessage(RELOAD_CHUNKS);
    this.messageService.sendMessage(RELOAD_GLOBAL_VAR);
    this.messageService.sendMessage(RELOAD_JS_RUNTIME);
    this.messageService.sendMessage(RELOAD_WASM);

    this.toastr.success('Success', `Notebook ${this.workMode === 'create' ? 'created' : 'updated'}`);

    GeneralHelpers.resetForm(this, 'notebookForm');

    this.workMode = 'create';


  }

  public cancel() {
    this.workMode = 'create';
    this.resetForm();
  }

  private resetForm() {
    this.notebookForm.reset();
    (Object as any).values(this.notebookForm.controls).forEach((control: FormControl) => {
      control.setErrors(null);
      control.markAsUntouched();
      control.markAsPristine();
    });
  }

  public openDeleteConfirmationPopup(id: string = this.#appState.notebookId().toString()) {
    this.dialogService
      .confirmDialog({
        title: 'Are you sure ?',
        message: 'After you delete notebook you will lost data forever',
        confirmCaption: 'Confirm',
        cancelCaption: 'Cancel',
        dialogData: {
          id
        },
      })
      .subscribe((data) => {
        if (data.dialogData && data.dialogData.id) {

          this.notebookService
            .delete(this.#appState.notebookId().toString(), this.#appState.projectId().toString())
            .subscribe({
              next: () => {
                this.toastr.success("Notebook was deleted");

                this.notebookCallback();
              },
              error: () => {
                this.toastr.error("Notebook was not deleted");

              }
            })
        }
      });
  }
}
