<mat-table [dataSource]="dataSource!" #theTable cdkDropList
  (cdkDropListDropped)="handleRowDrop($event)" [cdkDropListData]="dataSource">
  @for (column of columns; track column.field) {
    <ng-container matColumnDef="{{column.field.toString()}}">
      <mat-header-cell *matHeaderCellDef> {{column.header}} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        @if (column.checkbox) {
          @if (element[column.field]) {
            <mat-icon>check</mat-icon>
          }
        } @else {
          {{element[column.field]}}
        }
      </mat-cell>
    </ng-container>
  }
@if (options.showHeader) {
  <mat-header-row class="field-row fw-bold" *matHeaderRowDef="columnNames(); sticky: true"></mat-header-row>
}
<mat-row class="field-row" *matRowDef="let row; columns: columnNames()"
  (click)="selectionModel.toggle(row)"
  (keydown.enter)="selectionModel.toggle(row)"
  [ngClass]="{highlighted: selectionModel.isSelected(row)}"
  cdkDrag [cdkDragDisabled]="!options.dragAndDrop">
</mat-row>
</mat-table>
