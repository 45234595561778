import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private auth0Service: AuthService,
    private oauthService: OAuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.errorHandler(error)));
  }

  private errorHandler(error: unknown): Observable<HttpEvent<unknown>> {
    this.renderErrors(error);
    return throwError(() => error);
  }

  private renderErrors(error: any): void {
    const errorMessages: string[] = this.parseErrorMessages(error);

    switch (error.status) {
      case 401:
        this.handleUnauthorized();
        return;
      case 403:
        this.handle403Error();
        return;
      case 404:
        this.router.navigate(['/404']);
        return;
      case 500:
      case 503:
        this.router.navigate(['/500']);
        return;
    }

    if (Array.isArray(errorMessages) && errorMessages.length > 0) {
      for (const element of errorMessages) {
        this.showError(`${error.status}: ${element}`);
      }
    }
  }

  private handleUnauthorized(): void {
    if (environment.authProvider === 'auth0') {
      this.auth0Service.loginWithRedirect();
    } else if (environment.authProvider === 'zitadel') {
      this.oauthService.initLoginFlow();
    } else {
      this.router.navigate(['/not-authenticated']);
    }
  }

  private handle403Error(): void {
    if (this.isAuthenticated()) {
      this.router.navigate(['/access-denied']);
    } else {
      this.handleUnauthorized();
    }
  }

  private isAuthenticated(): boolean {
    if (environment.authProvider === 'auth0') {
      let isAuthenticated = false;
      this.auth0Service.isAuthenticated$.subscribe(
        (auth) => (isAuthenticated = auth)
      );
      return isAuthenticated;
    } else if (environment.authProvider === 'zitadel') {
      return this.oauthService.hasValidAccessToken();
    }
    return false;
  }

  private parseErrorMessages(error: any): string[] {
    const errorMessages: string[] = [];

    if (typeof error?.error === 'string') {
      errorMessages.push(error?.error);
    }

    if (
      error?.error !== undefined &&
      error?.error !== null &&
      typeof error?.error === 'object'
    ) {
      const errorObj = error.error;

      for (const key in errorObj) {
        if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
          const element = errorObj[key];
          if (Array.isArray(element) && element.length > 0) {
            errorMessages.push(element[0]);
          }
          if (typeof element === 'string' && element !== '') {
            errorMessages.push(element);
          }
        }
      }
    }

    if (typeof error?.error === 'string' && error?.status === 500) {
      errorMessages.push('Internal server error');
    }

    if (
      typeof error?.error === 'object' &&
      typeof error?.error?.error === 'string'
    ) {
      errorMessages.push(error.error.error);
    }

    if (error?.error === null) {
      errorMessages.push(`${error.statusText}`);
    }

    return errorMessages;
  }

  private showError(message: string): void {
    this.toastr.error('Error', message);
  }
}
