<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button mat-icon-button [mat-dialog-close]="false" *ngIf="data.hasClose" class="small close">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
      <button mat-raised-button (click)="dropFiles()" class="mb-2">Drop Databse Files</button>
      <button mat-raised-button (click)="flushFiles()" class="mb-2">Flush Database Files</button>
      <button mat-raised-button (click)="reset()" class="mb-2">Reset Database</button>
      <button mat-raised-button (click)="addFileInput.click()" class="mb-2">Upload file</button>
    </div>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button mat-stroked-button color="primary" [mat-dialog-close]="data" *ngIf="data?.confirmCaption" class="mr-2">
      {{ data.confirmCaption }}
    </button>
    <button mat-stroked-button color="warn" [mat-dialog-close]="false" *ngIf="data?.cancelCaption">
      {{ data.cancelCaption }}
    </button>
  </mat-card-actions>
</mat-card>

<input name="upload" hidden #addFileInput type="file" id="fileUpload" (change)="handleFileInput($event)" />