import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProjectDialogComponent } from './edit-project-dialog.component';

// ─────────────────────────────────────────────────────────────────────────────
// Material
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
// ─────────────────────────────────────────────────────────────────────────────
// Layput
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Project form
import { ProjectFormModule } from '../project-form/project-form.module';
// Notebook form
import { NotebookFormModule } from '../notebook-form/notebook-form.module';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    // Layout
    FlexLayoutModule,
    // Project form
    ProjectFormModule,
    // Notebook form
    NotebookFormModule,
  ],
  declarations: [EditProjectDialogComponent],
  exports: [EditProjectDialogComponent]
})
export class EditProjectDialogModule { }
