import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE } from '../../constants/general.constants';
import { GlobalRole, Permission } from '../../interfaces/global-role.interface';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private readonly rolesEndpoint = `${API_BASE}roles`;
  private readonly permissionsEndpoint = `${API_BASE}/permissions`;

  constructor(private http: HttpClient) {}

  get(): Observable<GlobalRole[]> {
    return this.http.get<GlobalRole[]>(this.rolesEndpoint);
  }

  create(newRole: GlobalRole): Observable<GlobalRole> {
    return this.http.post<GlobalRole>(this.rolesEndpoint, newRole);
  }

  update(roleId: number, updatedRole: GlobalRole): Observable<GlobalRole> {
    const roleEndpoint = `${this.rolesEndpoint}/${roleId}`;
    return this.http.put<GlobalRole>(roleEndpoint, updatedRole);
  }

  delete(roleId: number): Observable<void> {
    const roleEndpoint = `${this.rolesEndpoint}/${roleId}`;
    return this.http.delete<void>(roleEndpoint);
  }

  getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(this.permissionsEndpoint);
  }
}
