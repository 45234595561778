<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <div class="global-var-list-wrapper mt-2 mb-2">
    <ul class="list-group">
      <li
        class="list-group-item"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let item of varList"
      >
        <strong class="d-inline-block text-truncate w-75"
          >{{ item.name }} =
          {{
            generalHelpers.trueTypeOf(item.value) === "array" ||
            generalHelpers.trueTypeOf(item.value) === "object"
              ? (item.value | json)
              : item.value
          }}</strong
        >
        <div>
          <button
            color="primary"
            mat-icon-button
            aria-label="Edit"
            class="no-border menu-button small"
            matTooltip="Edit notebook variable"
            matTooltipPosition="right"
            (click)="edit(item)"
          >
            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
          </button>
          <button
            color="warn"
            mat-icon-button
            aria-label="Delete"
            class="no-border menu-button small"
            matTooltip="Revome notebook variable"
            matTooltipPosition="right"
            (click)="delete(item)"
            *ngIf="isMainVersion"
          >
            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
          </button>
        </div>
      </li>
    </ul>
  </div>
  <form
    name="globalVarForm"
    [formGroup]="globalVarForm"
    novalidate
    (ngSubmit)="
      processGlobalVarForm(
        globalVarForm.getRawValue(),
        globalVarForm.valid,
        $event
      )
    "
  >
    <mat-card-content>
      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="30px"
        class="mb-3"
      >
        <mat-form-field appearance="outline" class="no-wrapper w100">
          <mat-label>Enter variable name</mat-label>
          <input matInput formControlName="name" />
          <mat-hint
            >Only uppercause letters, underscores and digits allowed</mat-hint
          >
        </mat-form-field>
        <app-code-editor
          class="w100"
          (updateContent)="updateContent($event)"
          (startEdit)="setFormTouched()"
          mode="javascript"
          [content]="content"
        ></app-code-editor>
      </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
      <ng-container *ngIf="hasWritePermission()">
        <button
          mat-stroked-button
          color="primary"
          type="submit"
          *ngIf="data?.confirmCaption && workMode === 'create'"
          class="mr-2"
        >
          {{ data.confirmCaption }}
        </button>
      </ng-container>
      <button
        mat-stroked-button
        color="primary"
        type="submit"
        *ngIf="data?.confirmCaption && workMode === 'update'"
        class="mr-2"
      >
        {{ data.updateCaption }}
      </button>
      <button
        mat-stroked-button
        color="warn"
        [mat-dialog-close]="false"
        *ngIf="data?.cancelCaption"
      >
        {{ data.cancelCaption }}
      </button>
    </mat-card-actions>
    <input type="hidden" formControlName="id" />
  </form>
</mat-card>
