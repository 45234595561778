<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <app-manage-team [projectId]="data.model.projectId"></app-manage-team>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data"
      *ngIf="data?.confirmCaption"
      class="mr-2"
    >
      {{ data.confirmCaption }}
    </button>
    <button
      mat-stroked-button
      color="warn"
      [mat-dialog-close]="false"
      *ngIf="data?.cancelCaption"
    >
      {{ data.cancelCaption }}
    </button>
  </mat-card-actions>
</mat-card>
