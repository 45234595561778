import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  projectGuard,
  userGuard,
} from './shared/services/app-state/app-state.service';
import { DynamicAuthGuard } from './shared/guards/dynamic-auth.guard';

const routes: Routes = [
  {
    path: 'project/:projectId',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    loadChildren: () =>
      import('./pages/notebook/notebook.module').then(
        (mod) => mod.NotebookModule
      ),
    canActivate: [DynamicAuthGuard, userGuard, projectGuard],
  },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./pages/my-profile/my-profile.module').then(
        (mod) => mod.MyProfileModule
      ),
    canActivate: [DynamicAuthGuard, userGuard],
  },
  {
    path: 'annotation-tool',
    loadChildren: () =>
      import('./pages/annotation-tool/annotation-tool.module').then(
        (mod) => mod.AnnotationToolModule
      ),
    canActivate: [DynamicAuthGuard, userGuard],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./pages/projects/projects.module').then(
        (mod) => mod.ProjectsModule
      ),
    canActivate: [DynamicAuthGuard, userGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then(
        (mod) => mod.LoginModule
      ),
  },
  {
    path: '500',
    loadChildren: () =>
      import(
        './static-pages/internalServerError/internalServerError.module'
      ).then((mod) => mod.InternalServerErrorModule),
  },
  {
    path: 'access-denied',
    loadChildren: () =>
      import('./static-pages/accessDenied/accessDenied.module').then(
        (mod) => mod.AccessDeniedModule
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./static-pages/pageNotFound/pageNotFound.module').then(
        (mod) => mod.PageNotFoundModule
      ),
  },
  {
    path: 'not-authenticated',
    loadChildren: () =>
      import('./static-pages/notAuthenticated/notAuthenticated.module').then(
        (mod) => mod.NotAuthenticatedModule
      ),
  },
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { bindToComponentInputs: true },
  )],
  exports: [RouterModule],
})
export class AppRoutingModule { }