import { Injectable, computed, inject, signal } from '@angular/core';
import { Notebook, Project, Version } from '../../interfaces/project.interface';
import { User } from '../../interfaces/user.interface';
import {
  CanActivateFn,
  GuardResult,
  Router,
  createUrlTreeFromSnapshot,
} from '@angular/router';
import { ProjectService } from '../project/project.service';
import { UserService } from '../user/user.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { PermissionId } from '../../interfaces/global-role.interface';
import {
  API_BASE,
  LOAD_SNAPSHOT,
  PROJECT,
} from '../../constants/general.constants';
import { MessageService } from '../message/message.service';
import { CurrentEditor } from '../../interfaces/notification.interface';
import { HttpClient } from '@angular/common/http';

type AppState = {
  project?: Project;
  version?: Version;
  notebook?: Notebook;
};

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  #userService = inject(UserService);
  #router = inject(Router);
  #messageService = inject(MessageService);
  #httpClient = inject(HttpClient);

  get #user$() { return this.#userService.getCurrent() };
  #user = signal<User | undefined>(undefined);
  #state = signal<AppState>({});

  user = computed(() => this.#user());

  project = computed(() => this.#state().project);
  projectId = computed(() => this.project()?.projectId ?? 0);
  isProjectOwner = computed(() => {
    return (
      this.user() !== undefined &&
      this.project() !== undefined &&
      this.user()!.userId === this.project()!.userId
    );
  });

  private isHeadVersionSubject = new BehaviorSubject<boolean>(false);
  isHeadVersion$ = this.isHeadVersionSubject.asObservable();

  version = computed(() => this.#state().version);
  versionId = computed(() => this.version()?.versionId || 0);
  isHeadVersion = computed(() => {
    const proj = this.project();
    const version = this.version();
    const result =
      !!proj && proj.currentVersionId === this.versionId() &&
      !!version && version.majorVersion === 0 && version.minorVersion === 0;

    this.isHeadVersionSubject.next(result);

    return result;
  });

  notebook = computed(() => this.#state().notebook);
  notebookId = computed(() => this.notebook()?.notebookId || 0);

  currentEditor = signal<CurrentEditor | undefined>(undefined);
  isCurrentEditor = computed(() => {
    const user = this.user();
    const editor = this.currentEditor();
    return (
      editor !== undefined &&
      user !== undefined &&
      editor.userId === user.userId
    );
  });

  cacheId = signal<number | undefined>(undefined);

  get #projects$() {
    return this.#httpClient.get<Project[]>(`${API_BASE}${PROJECT}`);
  };
  #projects = signal<Project[]>([]);
  /** Read-only signal to get the current list of projects */
  projects = this.#projects.asReadonly();

  /**
   * Updates the projects signal with a new request to the server
   * @returns The updated list of projects
   */
  async loadProjects() {
    const projects = await firstValueFrom(this.#projects$);
    this.#projects.set(projects);
    return projects;
  }

  /** Called by the user Guard to load the user */
  async loadUser(): Promise<User | undefined> {
    if (this.user() === undefined) {
      const user = await firstValueFrom(this.#user$);
      this.#user.set(user);
    }
    return this.user();
  }

  /**
   * Clears the project, version, and notebook from the state
   */
  clearProject() {
    this.#state.set({});
  }

  /** Called by the route Guard, this method sets the private signals.
   */
  async loadProject(
    projectService: ProjectService,
    project?: Project,
    version?: Version,
    notebook?: Notebook,
    snapshotId?: number
  ): Promise<GuardResult> {
    if (project === undefined) {
      this.#state.set({});
      return false;
    }
    if (
      !projectService.getUserHasPermissionForProject(
        project,
        this.user()!,
        PermissionId.READ
      )
    ) {
      console.error(
        `User does not have permission to view project ${project.projectId}`
      );
      return this.#router.parseUrl('permission-denied');
    }
    if (
      !version ||
      !project.versions ||
      project.projectId != version.projectId
    ) {
      console.error(
        `Version ${version?.versionId} not found in project ${project.projectId}`
      );
      return false;
    }
    // get the full version
    if (!version.notebooks) {
      version = await firstValueFrom(
        projectService.getFullVersion(project.projectId, version.versionId)
      );
    }
    // now have a version that the user has permission to view
    // make sure we have a notebook
    if (!notebook) {
      notebook = version.notebooks?.find(
        (n) => n.name === this.notebook()?.name
      );
      if (!notebook) {
        notebook = version.notebooks?.[0];
      }
    }
    if (!notebook) {
      console.error(`No notebooks found in version ${version.versionId}`);
      return false;
    }
    this.#state.update((s) => ({ project, version, notebook }));
    this.cacheId.set(undefined);
    if (snapshotId) {
      this.#messageService.sendMessage(LOAD_SNAPSHOT, {
        snapshotId,
        versionId: version.versionId,
        notebookId: notebook.notebookId,
        projectId: project.projectId,
      });
    }
    return true;
  }

  /**
   * Navigates to the project with the given projectId and optional versionId
   */
  navigateToProject(projectId: number, versionId?: number): void {
    this.#router
      .navigate(['/', PROJECT, projectId], {
        queryParams: { versionId },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
      .then(() => { });
  }

  /**
   * Navigates to the version with the given versionId
   * @param versionId The versionId to navigate to
   */
  async navigateToVersion(versionId: number) {
    const projectId = this.projectId();

    return await this.#router
      .navigate(['/', PROJECT, projectId], {
        queryParams: {
          versionId,
          notebookId: null,
          snapshotId: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
  }

  /**
   * Navigates to the notebook with the given notebookId
   * @param notebookId The notebook to navigate to
   */
  async navigateToNotebook(notebookId: number) {
    return await this.#router
      .navigate([], {
        queryParams: {
          versionId: this.versionId(),
          notebookId,
          snapshotId: null,
        },
        queryParamsHandling: 'merge', // Merge with existing query params
        replaceUrl: true,
      });
  }

  /**
   * Navigates to the snapshot with the given snapshotId
   * @param snapshotId The snapshot to navigate to
   */
  async navigateToSnapshot(snapshotId: number) {
    return await this.#router
      .navigate([], {
        queryParams: {
          versionId: this.versionId(),
          notebookId: this.notebookId(),
          snapshotId,
        },
        queryParamsHandling: 'merge', // Merge with existing query params
        replaceUrl: true, // Replace the current URL instead of adding a new entry
      });
  }
}

/**
 * Guards the app state has loaded the user, setting the user in the app state. It also makes
 * sure the projects have been loaded.
 */
export const userGuard: CanActivateFn = async (route, state) => {
  const appStateService = inject(AppStateService);
  if (appStateService.user() === undefined) {
    await appStateService.loadUser();
  }
  if (appStateService.projects().length === 0) {
    await appStateService.loadProjects();
  }
  return appStateService.user() !== undefined;
};

/**
 * Guards the project, version, and notebook based on the route parameters, setting
 * the state to the project, version, and notebook that are being navigated to.
 * @param route The route to guard
 * @param state The current state
 * @returns The result of the guard
 */
export const projectGuard: CanActivateFn = async (route, state) => {
  const projectService = inject(ProjectService);
  const appStateService = inject(AppStateService);
  const router = inject(Router);

  // Ensure user is loaded
  const user = appStateService.user() || (await appStateService.loadUser());
  if (!user) {
    console.error('User not loaded');
    return router.parseUrl('/not-authenticated');
  }

  // Ensure projects are loaded
  if (appStateService.projects().length === 0) {
    await appStateService.loadProjects();
  }

  const projectId = Number(route.params['projectId']);
  if (isNaN(projectId)) {
    console.error('Invalid project ID');
    return router.parseUrl('/projects');
  }

  const project = appStateService
    .projects()
    .find((p) => p.projectId === projectId);
  if (!project) {
    console.error(`Project with ID ${projectId} not found`);
    return router.parseUrl('/404');
  }

  if (
    !projectService.getUserHasPermissionForProject(
      project,
      user,
      PermissionId.READ
    )
  ) {
    console.error(`User does not have permission to view project ${projectId}`);
    return router.parseUrl('/permission-denied');
  }

  const versionId = Number(route.queryParams['versionId']);
  // TODO: Check if versionId is a presented in the project
  let version = project.versions?.find((v) => v.versionId === versionId);

  if (!version) {
    version = projectService.getHeadVersion(project);
    if (!version) {
      console.error('No head version found');
      return router.parseUrl('/404');
    }

    if (
      !projectService.getUserHasPermissionForProject(
        project,
        user,
        PermissionId.READ
      )
    ) {
      console.error('User does not have permission to view head version');
      return router.parseUrl('/permission-denied');
    }

    return createUrlTreeFromSnapshot(route, [], {
      ...route.queryParams,
      versionId: version.versionId,
    });
  }

  try {
    const fullVersion = await firstValueFrom(
      projectService.getFullVersion(project.projectId, version.versionId)
    );

    if (!fullVersion) {
      console.error(
        `Full version ${version.versionId} not found in project ${projectId}`
      );
      return router.parseUrl('/404');
    }

    const notebookId = Number(route.queryParams['notebookId']);
    let notebook: Notebook | undefined = undefined;

    if (!isNaN(notebookId)) {
      notebook = fullVersion.notebooks?.find(
        (n) => n.notebookId === notebookId
      );
    }

    if (!notebook) {
      notebook = fullVersion.notebooks?.find(
        (n) => n.name === appStateService.notebook()?.name
      );
    }

    if (
      !notebook &&
      fullVersion.notebooks &&
      fullVersion.notebooks.length > 0
    ) {
      notebook = fullVersion.notebooks[0];
    }

    if (!notebook) {
      console.error(`No notebooks found in version ${fullVersion.versionId}`);
      return router.parseUrl('/404');
    }

    if (isNaN(notebookId) && notebook) {
      return createUrlTreeFromSnapshot(route, [], {
        ...route.queryParams,
        notebookId: notebook.notebookId,
      });
    }

    const snapshotId = Number(route.queryParams['snapshotId']);

    return appStateService.loadProject(
      projectService,
      project,
      fullVersion,
      notebook,
      isNaN(snapshotId) ? undefined : snapshotId
    );
  } catch (error) {
    console.error('Error loading full version:', error);
    return router.parseUrl('/404');
  }
};
