import { Injectable } from '@angular/core';
import * as localforage from 'localforage'; // this works!!!
import {
  ERROR_GET_DATA,
  ERROR_NO_DATA,
  ERROR_PUT_DATA,
  ERROR_REMOVE_DATA,
} from '../../constants/additional-methods.constants';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalforageService {
  constructor() {
    // ─────────────────────────────────────────────────────────────
    // Driver is represents default order;
    localforage.config({
      name: 'nc-calc-storage',
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ],
      size: 4980736,
      storeName: 'nc-calc-storage',
      version: 1.0,
      description: '',
    });
  }

  async getItem(key: string) {
    try {
      return await localforage.getItem(key);
    } catch (err) {
      console.error('Error getting item from localforage:', err);
      throw err;
    }
  }

  async setItem(key: string, value: any) {
    try {
      return await localforage.setItem(key, value);
    } catch (err) {
      console.error('Error setting item in localforage:', err);
      throw err;
    }
  }

  async removeItem(key: string) {
    try {
      return await localforage.removeItem(key);
    } catch (err) {
      console.error('Error removing item from localforage:', err);
      throw err;
    }
  }

  async clear() {
    try {
      return await localforage.clear();
    } catch (err) {
      console.error('Error clearing localforage:', err);
      throw err;
    }
  }

  async keys() {
    try {
      return await localforage.keys();
    } catch (err) {
      console.error('Error getting keys from localforage:', err);
      throw err;
    }
  }

  // -----------------------------
  // This method is used in quick.js and pyodide
  // -----------------------------

  async putData(args: any[], chunkContext: ExecutionContext) {
    try {
      const key = args[0];
      const value = args[1];
      
      if (!key || value === undefined) {
        // Adjusted to check for `value === undefined` to allow falsy values like 0 or false
        const error = new Error(ERROR_PUT_DATA);
        chunkContext.addMessage(ERROR_PUT_DATA, 'danger');
        throw error;
      }
      
      return await this.setItem(key, value);
    } catch (error: any) {
      // Only add the message if it's not the standard error we've already handled
      if (error.message !== ERROR_PUT_DATA) {
        chunkContext.addMessage(`Error storing data: ${error.message}`, 'danger');
      }
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  // -----------------------------
  // This method is used in quick.js and pyodide
  // -----------------------------
  async getData(args: any[], chunkContext: ExecutionContext) {
    try {
      const key = args[0];
      
      if (!key) {
        const error = new Error(ERROR_GET_DATA);
        chunkContext.addMessage(ERROR_GET_DATA, 'danger');
        throw error;
      }
      
      chunkContext.setBusy(true);
      const data = await this.getItem(key);
      
      if (data === null) {
        const error = new Error(ERROR_NO_DATA);
        chunkContext.addMessage(ERROR_NO_DATA, 'danger');
        throw error;
      }
      
      return data;
    } catch (error: any) {
      // Only add the message if it's not one of our standard errors that we've already handled
      if (error.message !== ERROR_GET_DATA && error.message !== ERROR_NO_DATA) {
        chunkContext.addMessage(`Error retrieving data: ${error.message}`, 'danger');
      }
      throw error; // Rethrow to allow try/catch blocks to work
    } finally {
      chunkContext.setBusy(false);
    }
  }

  // -----------------------------
  // This method is used in quick.js and pyodide
  // -----------------------------
  async removeData(args: any[], chunkContext: ExecutionContext) {
    try {
      const key = args[0];
      
      if (!key) {
        const error = new Error(ERROR_REMOVE_DATA);
        chunkContext.addMessage(ERROR_REMOVE_DATA, 'danger');
        throw error;
      }
      
      return await this.removeItem(key);
    } catch (error: any) {
      // Only add the message if it's not the standard error we've already handled
      if (error.message !== ERROR_REMOVE_DATA) {
        chunkContext.addMessage(`Error removing data: ${error.message}`, 'danger');
      }
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }
}
