<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <p [innerHtml]="data.message"></p>
    <div *ngIf="data?.dialogData?.chunkData">
      <ul class="list-group">
        <li class="list-group-item">
          Name -
          <strong>{{
            data.dialogData.chunkData.name
              ? data.dialogData.chunkData.name
              : "No name"
          }}</strong>
        </li>
        <li class="list-group-item">
          Chunk id - <strong>{{ data.dialogData.chunkData.chunkId }}</strong>
        </li>
      </ul>
    </div>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data"
      *ngIf="data?.confirmCaption"
      class="mr-2"
    >
      {{ data.confirmCaption }}
    </button>
    <button
      mat-stroked-button
      color="warn"
      [mat-dialog-close]="false"
      *ngIf="data?.cancelCaption"
    >
      {{ data.cancelCaption }}
    </button>
  </mat-card-actions>
</mat-card>
