<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field appearance="outline" class="w100 mt-2">
      <mat-label>{{ data.label }}</mat-label>
      <input
        matInput
        [attr.placeholder]="data.placeholder"
        [(ngModel)]="data.model"
      />
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data"
      *ngIf="data?.confirmCaption"
      class="mr-2"
    >
      {{ data.confirmCaption }}
    </button>
    <button
      mat-stroked-button
      color="warn"
      [mat-dialog-close]="false"
      *ngIf="data?.cancelCaption"
    >
      {{ data.cancelCaption }}
    </button>
  </mat-card-actions>
</mat-card>
