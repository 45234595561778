export enum ResponseType {
  HealthCheck = 0,
  SingleImageReady = 1,
  SingleImageReceived = 2,
  ReceivePrompt = 3,
  RefinePrompt = 4,
}

export enum RequestType {
  HealthCheck = 0,
  SendSingleImage = 1,
  SendPrompt = 2,
  RefinePrompt = 3,
}

export enum ResponseStatus {
  Ok = 0,
  Error = 1,
  Warning = 2,
}
