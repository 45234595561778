import { SelectionModel } from '@angular/cdk/collections'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table'
import { Observable } from 'rxjs'

export type TableListColDef<TData> = {
  header: string;
  field: keyof TData;
  checkbox?: boolean;
}

export type TableListOptions = {
  showHeader?: boolean;
  dragAndDrop?: boolean;
}

@Component({
  selector: 'table-list',
  templateUrl: './table-list.component.html',
  styleUrl: './table-list.component.scss'
})
export class TableListComponent<TData = any> implements OnInit {
  @Input() dataSource!: Observable<readonly TData[]>;
  @Input() columns!: TableListColDef<TData>[];
  @Input() options!: TableListOptions;
  @Output() selectionChanged = new EventEmitter<TData|undefined>();
  @Output() rowDrop = new EventEmitter<CdkDragDrop<TData>>();
  @ViewChild('theTable') theTable!: MatTable<TData>;

  protected selectionModel = new SelectionModel<TData>(false);
  #skipSelection = false;

  ngOnInit(): void {
    if (!this.dataSource) {
      throw new Error('dataSource is required');
    }
    if (!this.columns) {
      throw new Error('columns is required');
    }
    if (!this.options) {
      this.options = { showHeader: true };
    }
    this.selectionModel.changed.subscribe((change) => {
      if (this.#skipSelection) { return; }
      if (change.added.length > 0) {
        this.selectionChanged.emit(change.added[0]);
      } else if (change.removed.length > 0) {
        this.selectionChanged.emit(undefined);
      }
    });
  }

  selectRow(row: TData) {
    this.#skipSelection = true;
    this.selectionModel.select(row);
    this.#skipSelection = false;
  }

  reloadViews() {
    this.theTable.renderRows();
  }

  columnNames(): string[] {
    return this.columns?.map((col) => col.field.toString()) ?? [];
  }

  handleRowDrop(event: any) {
    this.rowDrop.emit(event);
  }
}
