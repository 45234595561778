export enum PARSER_METHODS {
  displayTable = 'displayTable',
  getFile = 'getFile',
}

export interface ParserMethod {
  globalName: string;
  method: string;
  serviceToUse: string;
  methodToCall: string;
}

export const CodemirrorAutocomplete = [
  'ehq.saveFile',
  'ehq.saveProjectFile',
  'ehq.saveFileFromUrl',
  'ehq.displayTable',
  'ehq.displayImage',
  'ehq.displayImageGroup',
  'ehq.displayVideo',
  'ehq.tensorToCanvas',
  'ehq.getFile',
  'ehq.putData',
  'ehq.getData',
  'ehq.removeBytes',
  'ehq.imageProcessing',
  'ehq.addFileToDatabase',
  'ehq.addDataToDatabase',
  'ehq.getFileFromDatabase',
  'ehq.dbQuery',
  'ehq.showSimpleAlert',
  'ehq.showTextPrompt',
  'ehq.goToBlock',
  'ehq.log',

  'store.putNumericArray',
  'store.stackScalarsIntoArray',
  'store.joinArraysIntoTable',
  'store.createSampleData',
  'store.emptyStore',
  'store.removeObject',
  'store.listStore',
  'store.putScalar',
  'store.putArray',
  'store.convertImageToTensor',
  'store.putTableIPC',
  'store.getObjectInfo',
  'store.getObjectJSON',
  'store.getNumericArrayView',
  'store.getTableInfo',
  'store.getTableRows',
  'store.getTableColumn',
  'store.getTableCell',
  'store.getNumericColumnView',
  'store.getTableCellView',
  'store.getTensorView',
  'store.getTableIPC',
  'store.putTableIPC',
  'store.tableFromArrays',
  'store.tableFromJSON',

  'search.doSearch',
  'search.loadIndexData',

  'viewer.toggle',

  'arrowFlight.connect',
  'arrowFlight.disconnect',
  'arrowFlight.createDataSet',
  'arrowFlight.deleteDataSet',
  'arrowFlight.createView',
  'arrowFlight.deleteView',
  'arrowFlight.getSchema',
  'arrowFlight.initCache',
  'arrowFlight.listDataSets',
  'arrowFlight.refreshDataSetList',
  'arrowFlight.listCloud',
  'arrowFlight.listViews',
  'arrowFlight.doAction',
  'arrowFlight.performQuery',
  'arrowFlight.performQueryAsJson',
  'arrowFlight.createPut',
  'arrowFlight.initPut',
  'arrowFlight.continuePut',
  'arrowFlight.endPut',
  'arrowFlight.presignUrl',
  'arrowFlight.listTables',
  'arrowFlight.createTable',
  'arrowFlight.updateTables',
  'arrowFlight.uploadTable',
  'arrowFlight.deleteTable',
  'arrowFlight.cloudBasePathExists',
  'arrowFlight.setActiveConnection',

  'convert.csvToArrow',
  'convert.parquetToArrow',
  'convert.arrowToParquet',
  'convert.jsonToCSV',
  'convert.csvToJSON',

  'sam2.healthcheck',
  'sam2.sendSingleImage',
  'sam2.sendPrompt',
  'sam2.refinePrompt',
];

export const RENDER_METHODS: string[] = ['displayTable'];
export const FILE_METHODS: string[] = ['getFile'];

export const ARROW_FLIGHT = 'arrowFlight';
export const CONVERT = 'convert';
export const STORE = 'store';
export const SEARCH = 'search';
export const VIEWER = 'viewer';
export const SAM2 = 'sam2';
export const EHQ = 'ehq';

// fileService
// renderService
// cacheService
// localforageService
// canvasService
// wasmStoreService
// convertService
// duckDbService
// fileUnifiedService
// wasmSearchService
// arrowWebsocketService
// chunkRunService

const exportMethods = [
  // File service
  [EHQ, 'saveProjectFile', 'fileService', 'saveFile'],
  [EHQ, 'saveFileFromUrl', 'fileService', 'saveFileFromUrl'],
  // Render service
  [EHQ, 'displayTable', 'renderService', 'renderTable'],
  [EHQ, 'displayImage', 'renderService', 'renderImage'],
  [EHQ, 'displayVideo', 'renderService', 'renderVideo'],
  [EHQ, 'displayChart', 'renderService', 'renderChart'],

  [EHQ, 'goToBlock', 'renderService', 'goToBlock'],
  [EHQ, 'displayImageGroup', 'renderService', 'displayImageGroup'],
  [EHQ, 'log', 'renderService', 'log'],
  // Cache service
  [EHQ, 'saveFile', 'cacheService', 'saveNotebookFile'],
  // Localforage service
  [EHQ, 'putData', 'localforageService', 'putData'],
  [EHQ, 'removeData', 'localforageService', 'removeData'],
  // Canvas service
  [EHQ, 'tensorToCanvas', 'canvasService', 'render'],
  // Wasm service
  [STORE, 'putNumericArray', 'wasmStoreService', 'putNumericArray'],
  [STORE, 'stackScalarsIntoArray', 'wasmStoreService', 'stackScalarsIntoArray'],
  [STORE, 'joinArraysIntoTable', 'wasmStoreService', 'joinArraysIntoTable'],
  [STORE, 'createSampleData', 'wasmStoreService', 'createSampleData'],
  [STORE, 'emptyStore', 'wasmStoreService', 'emptyStore'],
  [STORE, 'removeObject', 'wasmStoreService', 'removeObject'],
  [STORE, 'putScalar', 'wasmStoreService', 'putScalar'],
  [STORE, 'putArray', 'wasmStoreService', 'putArray'],
  [STORE, 'convertImageToTensor', 'wasmStoreService', 'convertImageToTensor'],
  [STORE, 'putTableIPC', 'wasmStoreService', 'putTableIPC'],
  // Viewer service
  [VIEWER, 'toggle', 'wasmViewService', 'toggle'],
  // Search service
  [SEARCH, 'loadIndexData', 'wasmSearchService', 'loadIndexData'],
  // Add file to database
  [EHQ, 'addFileToDatabase', 'duckDbService', 'addFileToDatabase'],
  // Add data to database
  [EHQ, 'addDataToDatabase', 'duckDbService', 'addDataToDatabase'],
  // Download table to a file
  [EHQ, 'getFileFromDatabase', 'duckDbService', 'getFileFromDatabase'],
  // SAM2 service
  [SAM2, 'sendSingleImage', 'sam2WebsocketService', 'sendSingleImage'],
];

/**
 * Array of import methods.
 * Each element in the array represents an import method with the following structure:
 * [moduleName, methodName, serviceName, serviceMethod]
 */
const importMethods = [
  // Localforage service
  [EHQ, 'getData', 'localforageService', 'getData'],
  // File service
  [EHQ, 'getFile', 'fileUnifiedService', 'getFile'],
  // Image processing
  [EHQ, 'imageProcessing', 'fileUnifiedService', 'imageProcessing'],
  // Cache service
  // DuckDB
  [EHQ, 'dbQuery', 'duckDbService', 'dbQuery'],
  // UI service
  [EHQ, 'showSimpleAlert', 'simpleUIService', 'showSimpleAlert'],
  [EHQ, 'showTextPrompt', 'simpleUIService', 'showTextPrompt'],
  // Wasm service
  [STORE, 'getObjectInfo', 'wasmStoreService', 'getObjectInfo'],
  [STORE, 'getObjectJSON', 'wasmStoreService', 'getObjectJSON'],
  [STORE, 'getNumericArrayView', 'wasmStoreService', 'getNumericArrayView'],
  [STORE, 'getTableInfo', 'wasmStoreService', 'getTableInfo'],
  [STORE, 'getTableRows', 'wasmStoreService', 'getTableRows'],
  [STORE, 'getTableColumn', 'wasmStoreService', 'getTableColumn'],
  [STORE, 'getTableCell', 'wasmStoreService', 'getTableCell'],
  [STORE, 'getNumericColumnView', 'wasmStoreService', 'getNumericColumnView'],
  [STORE, 'getTableCellView', 'wasmStoreService', 'getTableCellView'],
  [STORE, 'getTensorView', 'wasmStoreService', 'getTensorView'],
  [STORE, 'getTableIPC', 'wasmStoreService', 'getTableIPC'],
  [STORE, 'listStore', 'wasmStoreService', 'listStore'],
  [STORE, 'tableFromArrays', 'wasmStoreService', 'tableFromArrays'],
  [STORE, 'tableFromJSON', 'wasmStoreService', 'tableFromJSON'],
  // Search service
  [SEARCH, 'doSearch', 'wasmSearchService', 'doSearch'],

  // Arrow websocket service
  [ARROW_FLIGHT, 'listActions', 'arrowWebsocketService', 'listActions'],
  [ARROW_FLIGHT, 'initCache', 'arrowWebsocketService', 'initCache'],
  [ARROW_FLIGHT, 'performQuery', 'arrowWebsocketService', 'performQuery'],
  [
    ARROW_FLIGHT,
    'performQueryAsJson',
    'arrowWebsocketService',
    'performQueryAsJson',
  ],
  [ARROW_FLIGHT, 'performSqlQuery', 'arrowWebsocketService', 'performSqlQuery'],
  [
    ARROW_FLIGHT,
    'saveSqlQueryAsTable',
    'arrowWebsocketService',
    'saveSqlQueryAsTable',
  ],
  [
    ARROW_FLIGHT,
    'saveQueryAsTable',
    'arrowWebsocketService',
    'saveQueryAsTable',
  ],
  [
    ARROW_FLIGHT,
    'saveQueryToCloud',
    'arrowWebsocketService',
    'saveQueryToCloud',
  ],
  [
    ARROW_FLIGHT,
    'saveSqlQueryAsTable',
    'arrowWebsocketService',
    'saveSqlQueryAsTable',
  ],
  [ARROW_FLIGHT, 'getSchema', 'arrowWebsocketService', 'getSchema'],
  [ARROW_FLIGHT, 'listDataSets', 'arrowWebsocketService', 'listDataSets'],
  [
    ARROW_FLIGHT,
    'refreshDataSetList',
    'arrowWebsocketService',
    'refreshDataSetList',
  ],
  [ARROW_FLIGHT, 'createDataSet', 'arrowWebsocketService', 'createDataSet'],
  [ARROW_FLIGHT, 'deleteDataSet', 'arrowWebsocketService', 'deleteDataSet'],
  [ARROW_FLIGHT, 'createView', 'arrowWebsocketService', 'createView'],
  [ARROW_FLIGHT, 'deleteView', 'arrowWebsocketService', 'deleteView'],
  [ARROW_FLIGHT, 'listViews', 'arrowWebsocketService', 'listViews'],
  [ARROW_FLIGHT, 'listCloud', 'arrowWebsocketService', 'listCloud'],
  [ARROW_FLIGHT, 'listCloudDirs', 'arrowWebsocketService', 'listCloudDirs'],
  [
    ARROW_FLIGHT,
    'cloudBasePathExists',
    'arrowWebsocketService',
    'cloudBasePathExists',
  ],
  [ARROW_FLIGHT, 'doAction', 'arrowWebsocketService', 'doAction'],
  [ARROW_FLIGHT, 'disconnect', 'arrowWebsocketService', 'disconnect'],
  [ARROW_FLIGHT, 'connect', 'arrowWebsocketService', 'connect'],
  [ARROW_FLIGHT, 'createPut', 'arrowWebsocketService', 'createPut'],
  [ARROW_FLIGHT, 'initPut', 'arrowWebsocketService', 'initPut'],
  [ARROW_FLIGHT, 'continuePut', 'arrowWebsocketService', 'continuePut'],
  [ARROW_FLIGHT, 'endPut', 'arrowWebsocketService', 'endPut'],
  [ARROW_FLIGHT, 'presignUrl', 'arrowWebsocketService', 'presignUrl'],
  [ARROW_FLIGHT, 'listTables', 'arrowWebsocketService', 'listTables'],
  [ARROW_FLIGHT, 'createTable', 'arrowWebsocketService', 'createTable'],
  [ARROW_FLIGHT, 'uploadTable', 'arrowWebsocketService', 'uploadTable'],
  [ARROW_FLIGHT, 'updateTables', 'arrowWebsocketService', 'updateTables'],
  [ARROW_FLIGHT, 'deleteTable', 'arrowWebsocketService', 'deleteTable'],
  [
    ARROW_FLIGHT,
    'setActiveConnection',
    'arrowWebsocketService',
    'setActiveConnection',
  ],
  // Convert service
  [CONVERT, 'csvToArrow', 'convertService', 'csvToArrow'],
  [CONVERT, 'parquetToArrow', 'convertService', 'parquetToArrow'],
  [CONVERT, 'arrowToParquet', 'convertService', 'arrowToParquet'],
  [CONVERT, 'jsonToCSV', 'convertService', 'jsonToCSV'],
  [CONVERT, 'csvToJSON', 'convertService', 'csvToJSON'],
  // SAM2 service
  [SAM2, 'healthcheck', 'sam2WebsocketService', 'healthcheck'],
  [SAM2, 'sendPrompt', 'sam2WebsocketService', 'sendPrompt'],
  [SAM2, 'refinePrompt', 'sam2WebsocketService', 'refinePrompt'],
];

// CUSTOM_METHODS_IMPORT and CUSTOM_METHODS_EXPORT are used
const generateCustomMethods = (methods: any) => {
  return methods.map((methodsMetaData: any) => {
    const [globalName, method, serviceToUse, methodToCall] = methodsMetaData;

    return {
      globalName,
      method,
      serviceToUse,
      methodToCall,
    };
  });
};

export const CUSTOM_METHODS_IMPORT = generateCustomMethods(importMethods);
export const CUSTOM_METHODS_EXPORT = generateCustomMethods(exportMethods);

// ─────────────────────────────────────────────────────────────────────────────
// Custom error messages for import and export methods
// ─────────────────────────────────────────────────────────────────────────────

// Error messages for export methods
export const ERROR_SAVE_PROJECT_FILE =
  'Invalid data received for saving project file.';
export const ERROR_SAVE_FILE_FROM_URL =
  'Invalid data received for saving file from URL.';
export const ERROR_DISPLAY_TABLE =
  'Invalid data received for displaying table.';
export const ERROR_DISPLAY_IMAGE =
  'Invalid data received for displaying image.';
export const ERROR_SAVE_FILE = 'Invalid data received for saving file.';
export const ERROR_PUT_DATA = 'Invalid data received for putting data.';
export const ERROR_REMOVE_DATA = 'Invalid data received for removing data.';
export const ERROR_TENSOR_TO_CANVAS =
  'Invalid data received for rendering tensor to canvas.';
export const ERROR_PUT_NUMERIC_ARRAY =
  'Invalid data received for putting numeric array.';
export const ERROR_STACK_SCALARS_INTO_ARRAY =
  'Invalid data received for stacking scalars into array.';
export const ERROR_JOIN_ARRAYS_INTO_TABLE =
  'Invalid data received for joining arrays into table.';
export const ERROR_CREATE_SAMPLE_DATA =
  'Invalid data received for creating sample data.';
export const ERROR_EMPTY_STORE = 'Invalid data received for emptying store.';
export const ERROR_REMOVE_OBJECT = 'Invalid data received for removing object.';
export const ERROR_PUT_SCALAR = 'Invalid data received for putting scalar.';
export const ERROR_PUT_ARRAY = 'Invalid data received for putting array.';
export const ERROR_CONVERT_IMAGE_TO_TENSOR =
  'Invalid data received for converting image to tensor.';
export const ERROR_PUT_TABLE_IPC =
  'Invalid data received for putting table IPC.';
export const ERROR_TOGGLE_VIEWER = 'Invalid data received for toggling viewer.';
export const ERROR_LOAD_INDEX_DATA =
  'Invalid data received for loading index data.';
export const ERROR_ADD_FILE_TO_DATABASE =
  'Invalid data received for adding file to database.';

// Error messages for import methods
export const ERROR_GET_DATA = 'Invalid data received for getting data.';
export const ERROR_NO_DATA = 'No data found for the given key.';
export const ERROR_GET_FILE = 'Invalid data received for getting file.';
export const ERROR_IMAGE_PROCESSING =
  'Invalid data received for image processing.';
export const ERROR_DB_QUERY = 'Invalid data received for database query.';
export const ERROR_GET_OBJECT_INFO =
  'Invalid data received for getting object info.';
export const ERROR_GET_OBJECT_JSON =
  'Invalid data received for getting object JSON.';
export const ERROR_GET_NUMERIC_ARRAY_VIEW =
  'Invalid data received for getting numeric array view.';
export const ERROR_GET_TABLE_INFO =
  'Invalid data received for getting table info.';
export const ERROR_GET_TABLE_ROWS =
  'Invalid data received for getting table rows.';
export const ERROR_GET_TABLE_COLUMN =
  'Invalid data received for getting table column.';
export const ERROR_GET_TABLE_CELL =
  'Invalid data received for getting table cell.';
export const ERROR_GET_NUMERIC_COLUMN_VIEW =
  'Invalid data received for getting numeric column view.';
export const ERROR_GET_TABLE_CELL_VIEW =
  'Invalid data received for getting table cell view.';
export const ERROR_GET_TENSOR_VIEW =
  'Invalid data received for getting tensor view.';
export const ERROR_GET_TABLE_IPC =
  'Invalid data received for getting table IPC.';
export const ERROR_LIST_STORE = 'Invalid data received for listing store.';
export const ERROR_DO_SEARCH = 'Invalid data received for performing search.';
export const ERROR_INFO_ARROW_FLIGHT =
  'Invalid data received for getting info from Arrow Flight.';
export const ERROR_LIST_ACTIONS_ARROW_FLIGHT =
  'Invalid data received for listing actions in Arrow Flight.';
export const ERROR_DO_ACTION_ARROW_FLIGHT =
  'Invalid data received for doing action in Arrow Flight.';
export const ERROR_DISCONNECT_ARROW_FLIGHT =
  'Invalid data received for disconnecting Arrow Flight.';
export const ERROR_CONNECT_ARROW_FLIGHT =
  'Invalid data received for connecting Arrow Flight.';
export const ERROR_LIST_FLIGHTS_ARROW_FLIGHT =
  'Invalid data received for listing flights in Arrow Flight.';
export const ERROR_READ_TABLE_ARROW_FLIGHT =
  'Invalid data received for reading table in Arrow Flight.';
export const ERROR_PUT_TABLE_ARROW_FLIGHT =
  'Invalid data received for putting table in Arrow Flight.';
export const ERROR_SET_ACTIVE_CONNECTION_ARROW_FLIGHT =
  'Invalid data received for setting active connection in Arrow Flight.';
export const ERROR_CSV_TO_ARROW =
  'Invalid data received for converting CSV to Arrow.';
export const ERROR_PARQUET_TO_ARROW =
  'Invalid data received for converting Parquet to Arrow.';
export const ERROR_ARROW_TO_PARQUET =
  'Invalid data received for converting Arrow to Parquet.';
export const ERROR_JSON_TO_CSV =
  'Invalid data received for converting JSON to CSV.';
export const ERROR_CSV_TO_JSON =
  'Invalid data received for converting CSV to JSON.';

export const ERROR_SAVE_DEFAULT_NOTEBOOK_FILE =
  'Invalid data received for saving default notebook file.';

export const ERROR_SAM2_HEALTHCHECK = 'Failed to perform SAM2 healthcheck.';
export const ERROR_SAM2_SEND_SINGLE_IMAGE =
  'Failed to send single image to SAM2.';
export const ERROR_SAM2_SEND_PROMPT = 'Failed to send prompt to SAM2.';
export const ERROR_SAM2_REFINE_PROMPT = 'Failed to refine prompt with SAM2.';
