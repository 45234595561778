import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, map, of } from 'rxjs';
import { environment } from '../../../environments/environment';

export const DynamicAuthGuard: CanActivateFn = (
  route,
  state
): Observable<boolean> => {
  const router = inject(Router);
  const auth0Service = inject(Auth0Service);
  const oauthService = inject(OAuthService);

  if (environment.authProvider === 'auth0') {
    return auth0Service.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        } else {
          auth0Service.loginWithRedirect({ appState: { target: state.url } });
          return false;
        }
      })
    );
  } else if (environment.authProvider === 'zitadel') {
    if (oauthService.hasValidAccessToken()) {
      return of(true);
    } else {
      router.navigate(['/login']);
      return of(false);
    }
  } else {
    console.error('Invalid authProvider specified');
    router.navigate(['/login']);
    return of(false);
  }
};