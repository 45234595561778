import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { DuckDbService } from '../../services/duck-db/duck-db.service';

@Component({
  selector: 'app-duck-db-controls-dialog',
  templateUrl: './duck-db-controls-dialog.component.html',
  styleUrls: ['./duck-db-controls-dialog.component.scss']
})
export class DuckDbControldDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Dialog, private duckDbService: DuckDbService) { }

  ngOnInit() {
  }

  public dropFiles() {
    this.duckDbService.dropFiles();
  }

  public flushFiles() {
    this.duckDbService.flushFiles();
  }

  public reset() {
    this.duckDbService.reset();
  }

  public handleFileInput(event: Event) {
    const target = event.target as HTMLInputElement | any;
    const file: File = target.files[0];
    this.duckDbService.uploadFile(file);
  }

}
