import {
  Component,
  OnInit,
  Inject,
  inject,
  computed,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotebookVariablesService } from '../../services/notebook-variables/notebook-variables.service';
import { ProjectVariablesService } from '../../services/project-variables/project-variables.service';
import _ from 'lodash';
import { GeneralHelpers } from '../../helpers/general.helper';
import { GlobalVar } from '../../interfaces/global-var.interface';
import { MessageService } from '../../services/message/message.service';
import { Subscription } from 'rxjs';
import { ProjectConstant } from '../../interfaces/project-constants.interface';
import {
  EDIT_GLOBAL_VAR,
  EDIT_PROJECT_CONSTANT,
  INVALIDATE_CHUNKS,
} from '../../constants/general.constants';
import { AppStateService } from '../../services/app-state/app-state.service';
import { PermissionId } from '../../interfaces/global-role.interface';
import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'app-global-var-dialog',
  templateUrl: './global-var-dialog.component.html',
  styleUrls: ['./global-var-dialog.component.scss'],
})
export class GlobalVarDialogComponent implements OnInit, OnDestroy {
  #appState = inject(AppStateService);
  #projectService = inject(ProjectService);

  hasWritePermission = computed(() =>
    this.#projectService.getCurrentUserHasProjectPermission(PermissionId.WRITE)
  );

  public globalVarForm!: FormGroup;

  public workMode: 'create' | 'update' = 'create';

  public serviceToUse: 'notebookVariablesService' | 'projectVariablesService' =
    'notebookVariablesService';

  public messageSubscription!: Subscription;

  public varList: GlobalVar | ProjectConstant | any = [];

  public generalHelpers = GeneralHelpers;

  public content: string = '';

  private chunkId!: number | any;

  public isMainVersion: boolean = this.#appState.isHeadVersion() ?? false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GlobalVarDialogComponent>,
    private _formBuilder: FormBuilder,
    private notebookVariablesService: NotebookVariablesService,
    private projectVariablesService: ProjectVariablesService,
    private messageService: MessageService
  ) {
    this.updateServiceUsage();
    this.varList = this[this.serviceToUse].variableList;
  }

  ngOnInit() {
    this.initForm();
    this.subscribeToMessages();

    // If data is provided, assume we're in edit mode
    if (this.data && this.data.variable) {
      this.updateForm(this.data.variable);
    }
  }

  ngOnDestroy() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  private initForm() {
    this.globalVarForm = this._formBuilder.group({
      name: [
        { value: '', disabled: !this.isMainVersion },
        [Validators.required],
      ],
      id: [],
    });
  }

  private subscribeToMessages() {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (
          message?.text === EDIT_GLOBAL_VAR ||
          message?.text === EDIT_PROJECT_CONSTANT
        ) {
          this.updateForm(message?.data?.variable);
        }
      });
  }

  public processGlobalVarForm(formValue: any, isValid: boolean, $event: Event) {
    $event.preventDefault();
    if (!isValid) {
      return;
    }

    if (!this.isUniqueName(formValue.name) && this.workMode !== 'update') {
      this.globalVarForm.controls['name'].setErrors({ notUnique: true });
      return;
    }

    const id = formValue.id === null ? GeneralHelpers.getUuid() : formValue.id;

    const processGlobalVarForm: any = {
      name: formValue.name,
      value: GeneralHelpers.globalVarSanitize(this.content),
      id: id,
      sortOrder: 0,
    };

    if (this.workMode === 'create') {
      this[this.serviceToUse].add(processGlobalVarForm);
    }

    if (this.workMode === 'update') {
      this[this.serviceToUse].update(id, processGlobalVarForm);
      this.workMode = 'create';
    }
    if (this.chunkId) {
      this.messageService.sendMessage(INVALIDATE_CHUNKS, [this.chunkId]);
      this.chunkId = null;
    }

    this.resetForm();
  }

  public edit(item: GlobalVar | any) {
    this[this.serviceToUse].edit(item.id);
    this.varList = this[this.serviceToUse].variableList;
  }

  public delete(item: GlobalVar | any) {
    this[this.serviceToUse].delete(item.id);
    this.varList = this[this.serviceToUse].variableList;
    this.resetForm();
  }

  private isUniqueName(name: string): boolean {
    return !_.some(
      this[this.serviceToUse].variableList,
      (item) =>
        item.name === name && item.id !== this.globalVarForm.get('id')?.value
    );
  }

  private updateForm(data: GlobalVar | ProjectConstant) {
    this.globalVarForm.patchValue({
      name: data.name,
      id: data.id,
    });
    this.content = this.setContent(data);
    this.workMode = 'update';
  }

  private resetForm() {
    this.content = '';
    this.globalVarForm.reset();
    (Object as any)
      .values(this.globalVarForm.controls)
      .forEach((control: FormControl) => {
        control.setErrors(null);
        control.markAsUntouched();
        control.markAsPristine();
      });
  }

  private updateServiceUsage() {
    if (this.data?.serviceToUse !== undefined) {
      this.serviceToUse = this.data?.serviceToUse;
    }
  }

  public updateContent(data: any) {
    this.content = data;
  }

  public setFormTouched() {
    this.globalVarForm.markAsTouched();
  }

  private setContent(data: any): string {
    let value = data.value;

    // If value is a string, check if it's a stringified JSON
    if (typeof value === 'string') {
      try {
        const parsedValue = JSON.parse(value);
        // If parsing succeeds and the result is an object or array, use the parsed value
        if (typeof parsedValue === 'object' && parsedValue !== null) {
          value = parsedValue;
        }
      } catch (e) {
        // If parsing fails, it's not a valid JSON string, so we'll use it as-is
      }
    }

    const type = GeneralHelpers.trueTypeOf(value);
    if (type === 'object' || type === 'array') {
      this.setChunkId(data);
      return GeneralHelpers.jsonStringify(value);
    }
    return value.toString();
  }

  private setChunkId(data: any) {
    if (data && data.chunkId) {
      this.chunkId = data.chunkId;
    }
  }
}
