import { Injectable, inject } from '@angular/core';
import { ProjectConstant } from '../../interfaces/project-constants.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { EDIT_PROJECT_CONSTANT, RELOAD_PROJECT_CONSTANTS } from '../../constants/general.constants';
import { ProjectService } from '../project/project.service';
import { MessageService } from '../message/message.service';
import { Subscription } from 'rxjs';
// ─────────────────────────────────────────────────────────────────────────────
// Localforage
import { Version, VersionUpdate } from '../../interfaces/project.interface';
import { LocalstorageHelper } from '../../helpers/localstorage.helper';
import { AppStateService } from '../app-state/app-state.service'

@Injectable({
  providedIn: 'root',
})
export class ProjectVariablesService {
  #appState = inject(AppStateService);
  public variableList: ProjectConstant[] | any = [];

  // ─────────────────────────────────────────────────────────────────────
  // Message subscription
  private messageSubscription!: Subscription;

  constructor(
    private projectService: ProjectService,
    private messageService: MessageService,
  ) {
    this.getProjectConstantsList();
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === RELOAD_PROJECT_CONSTANTS) {
          this.getProjectConstantsList();
        }
      });
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Set Project Constants List

  private setProjectConstantsList(
    variableList: ProjectConstant[],
    projectId: string | any = this.#appState.projectId().toString()
  ) {
    if (Array.isArray(variableList)) {

      const verstionId = this.#appState.versionId();

      if (!verstionId) {
        return;
      }

      const defaultState = GeneralHelpers.jsonStringify(variableList);

      const selectedProject = this.#appState.project();

      if (!selectedProject) {
        return;
      }

      this.projectService
        .updateProjectVersion(Number(projectId), verstionId, {
          defaultState: defaultState,
          name: selectedProject.name,
          description: selectedProject.description,
        } as VersionUpdate)
        .subscribe({
          next: (res: Version) => {
            if (res.defaultState && GeneralHelpers.isJSON(res.defaultState)) {
              this.variableList = GeneralHelpers.jsonParse(res.defaultState);
            }
          },
          error: (err: any) => {

          },
        });
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Get Project Constants List

  private getProjectConstantsList() {
    const version = this.#appState.version();
    if (version && version.defaultState && GeneralHelpers.isJSON(version.defaultState)) {
      this.variableList = GeneralHelpers.jsonParse(version.defaultState);
      return;
    }
  }

  public clean() {
    this.variableList = [];
  }

  // ─────────────────────────────────────────────────────────────────────
  // Add

  public add(projectConstants: ProjectConstant): ProjectConstant[] {
    this.variableList.push(projectConstants);
    this.setProjectConstantsList(this.variableList);
    return this.variableList;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update

  public update(id: string, data: ProjectConstant): ProjectConstant[] {
    const index = this.getProjectConstantsIndex(id);
    if (index > -1) {
      this.variableList[index] = data;
      this.setProjectConstantsList(this.variableList);
    }

    return this.variableList;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Delete

  public delete(id: string): ProjectConstant[] {
    const index = this.getProjectConstantsIndex(id);
    if (index > -1) {
      this.variableList.splice(index, 1);
      this.setProjectConstantsList(this.variableList);
    }
    return this.variableList;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Edit

  public edit(id: string) {
    const index = this.getProjectConstantsIndex(id);
    if (index > -1) {
      this.messageService.sendMessage(EDIT_PROJECT_CONSTANT, {
        variable: this.variableList[index],
        index: index,
      });
    }
  }

  private getProjectConstantsIndex(id: string): number {
    const index = this.variableList.findIndex(
      (item: ProjectConstant) => item.id === id
    );
    return index === -1 ? 0 : index;
  }
}
