import { Injectable, Optional } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WSS_API_BASE, NOTIFICATION } from '../../constants/general.constants';
import { Observable, Subject } from 'rxjs';
import { BaseRequest } from '../../interfaces/notification.interface';
import { environment } from 'src/environments/environment';

import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class NotificationWebsocketService {
  private WS_URL!: string;
  private TOKEN!: string;
  private socket!: WebSocket;
  public messages: Subject<any> = new Subject<any>();
  private transId = 0;
  private RETRY_TIMER = 2e3;
  private MAX_RETRY_DURATION = 300000;

  constructor(
    private toastr: ToastrService,
    @Optional() private auth0Service: Auth0Service,
    @Optional() private oauthService: OAuthService
  ) {}

  public init() {
    if (environment.authProvider === 'auth0') {
      this.auth0Service.idTokenClaims$.subscribe({
        next: (token: any) => {
          this.setConnection(token?.__raw);
        },
      });
    } else if (environment.authProvider === 'zitadel') {
      const idToken = this.oauthService.getIdToken();
      this.setConnection(idToken);
    }
  }

  private setConnection(token: string | null): void {
    if (!token) {
      return;
    }

    this.TOKEN = token;
    this.WS_URL = `${WSS_API_BASE}${NOTIFICATION}`;
    this.connectToWebsocket(this.WS_URL, this.TOKEN);
  }

  private connectToWebsocket(url: string, authToken: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return;
    }

    this.socket = new WebSocket(`${url}?token=${authToken}`);

    this.socket.addEventListener('open', (event) => {
      // console.log('WebSocket connection opened:', event);
    });

    this.socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.message === 'Error' && data.details) {
        // Display error using ToastrService
        const ignoreErrors = ['editor locked'];
        if (!ignoreErrors.includes(data.details.description)) {
          this.toastr.error(data.details.description, 'Error!');
        }
      }
      this.messages.next(data);
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed:', event);
      // Handle reconnection logic here if needed
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      // Optionally display an error notification
      this.toastr.error('A WebSocket error occurred.', 'WebSocket Error');
    });
  }

  public sendMessage(message: any): void {
    if (!this.socket) {
      console.warn('WebSocket is not initialized. Message not sent.');
      return;
    }
    if (this.socket.readyState === WebSocket.OPEN) {
      if (message.transId === undefined) {
        message.trans_id = this.getTransId();
      }
      this.socket.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open. Message not sent.');
    }
  }

  public async sendMessageAwait(message: any): Promise<void> {
    const startTime = Date.now();

    return new Promise<void>((resolve, reject) => {
      const initializeAndSend = () => {
        if (!this.socket) {
          this.init();
        }

        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          if (message.trans_id === undefined) {
            message.trans_id = this.getTransId();
          }
          this.socket.send(JSON.stringify(message));
          resolve();
        } else if (Date.now() - startTime < this.MAX_RETRY_DURATION) {
          setTimeout(initializeAndSend, this.RETRY_TIMER);
        } else {
          console.error(
            'Unable to send message: WebSocket unavailable for 5 minutes.'
          );
          reject(new Error('WebSocket unavailable for 5 minutes'));
        }
      };

      initializeAndSend();
    });
  }

  public receiveMessage(): Observable<any> {
    return this.messages.asObservable();
  }

  private getTransId(): number {
    return this.transId + 1;
  }

  public async getSubscriptions() {
    const message: BaseRequest = {
      message: 'list-subscriptions',
      trans_id: this.getTransId(),
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
    }
  }

  public async watchEditors() {
    const message: BaseRequest = {
      version: 'v1',
      message: 'watch-editors',
      trans_id: this.getTransId(),
      watch: true,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Public methods for editor locking functionality ─────────────────
  // ─────────────────────────────────────────────────────────────────────

  /**
   * Request to lock an editor for a specific project.
   * @param projectId The project ID for which the editor is to be locked.
   */
  public async lockEditor(projectId: number) {
    const message: BaseRequest = {
      version: 'v1',
      message: 'lock-editor',
      trans_id: this.getTransId(),
      project_id: projectId,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Request to unlock an editor for a specific project.
   * @param projectId The project ID for which the editor is to be unlocked.
   */
  public async unlockEditor(projectId: number) {
    const message: BaseRequest = {
      version: 'v1',
      message: 'unlock-editor',
      trans_id: this.getTransId(),
      project_id: projectId,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Request the current editor state for a specific project.
   * @param projectId The project ID for which the current editor state is requested.
   */
  public async getCurrentEditor(projectId: number) {
    const message: BaseRequest = {
      version: 'v1',
      message: 'current-editor',
      trans_id: this.getTransId(),
      project_id: projectId,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
    }
  }
}
