import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CanvasResult } from '../../interfaces/canvas.interface';
import { ERROR_TENSOR_TO_CANVAS } from '../../constants/additional-methods.constants';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';

@Injectable({
  providedIn: 'root',
})
export class CanvasService {
  public renderList: CanvasResult[] = [];
  public renderServiceSubject$ = new Subject<CanvasResult>();

  constructor() {}

  public addToRenderList(renderData: CanvasResult): void {
    const { data, chunkId, pixelSize, pixelColor, rowSize } = renderData;
    const renderItem = { data, chunkId, pixelSize, pixelColor, rowSize };
    this.renderServiceSubject$.next(renderItem);
  }

  public render(data: any, chunkContext: ChunkContext): void {
    if (!Array.isArray(data) || data.length < 1) {
      chunkContext.addMessage(ERROR_TENSOR_TO_CANVAS, 'danger');
      return;
    }

    const chunkData = chunkContext.getChunk();

    const canvasData = data[0];
    const pixelSize = data[1] === undefined ? null : data[1];
    const pixelColor = data[2] === undefined ? null : data[2];
    const rowSize = data[3] === undefined ? null : data[3];
    const renderData = {
      pixelSize,
      pixelColor,
      rowSize,
      data: canvasData,
      chunkId: chunkData.chunkId,
    };

    this.addToRenderList(renderData);
  }
}
