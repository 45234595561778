import { NgModule } from '@angular/core';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

const { auth, apiUrl } = environment;

@NgModule({
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [`${apiUrl}*`],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    OAuthService,
    {
      provide: 'ZITADEL_CONFIG',
      useValue: {
        issuer: auth.zitadel.issuer,
        redirectUri: auth.zitadel.redirectUri,
        clientId: auth.zitadel.clientId,
        responseType: 'code',
        scope: 'openid profile email offline_access',
        showDebugInformation: true,
        oidc: true,
      },
    },
  ],
  exports: [OAuthModule],
})
export class ZitadelModule {}
