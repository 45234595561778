import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
// ─────────────────────────────────────────────────────────────────────────────
// Material
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

// ─────────────────────────────────────────────────────────────────────────────
// Layout module
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Pipes
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    // Layout
    FlexLayoutModule,
    // Pipes
    PipesModule,
  ],
  declarations: [TableComponent],
  exports: [TableComponent]
})
export class TableModule { }
