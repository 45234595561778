export interface GlobalRole {
  roleId: number;
  name: string;
  level: number;
  permissions: number[];
}

export interface Permission {
  id: number;
  name: string;
}

export enum PermissionId {
  READ = 1,
  WRITE = 2,
  CREATE = 3,
  DELETE = 4,
  EXECUTE = 5,
  ADMIN = 6,
  INVITE = 7,
}
