import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
// ────────────────────────────────────────────────────────────────────────────────
// Message Service
import { MessageService } from './services/message/message.service';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
// ─────────────────────────────────────────────────────────────────────────────
// Auth interceptor
import { TokenInterceptor } from './interceptors/token.interceptor';
// ─────────────────────────────────────────────────────────────────────────────
// Error interceptor
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
// ─────────────────────────────────────────────────────────────────────────────
// Loading interceptor
import { LoaderInterceptor } from './interceptors/loading.interceptor';
// ─────────────────────────────────────────────────────────────────────────────
// Dialogs
// ─────────────────────────────────────────────────────────────────────────────
// ─────────────────────────────────────────────────────────────────────────────
// Create dialog
import { CreateDialogModule } from './modules/create-dialog/create-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Confirm dialog
import { ConfirmDialogModule } from './modules/confirm-dialog/confirm-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Create Var Dialog
import { GlobalVarDialogModule } from './modules/global-var-dialog/global-var-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Create file from url
import { UrlDialogModule } from './modules/url-dialog/url-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Watch file dialog
import { WatchFileDialogModule } from './modules/watch-file-dialog/watch-file-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Chunk copy to notebook dialog
import { ChunkCopyToDialogModule } from './modules/chunk-copy-to-dialog/chunk-copy-to-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Databse controls dialog
import { DuckDbControldDialogModule } from './modules/duck-db-controls-dialog/duck-db-controls-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Arrow Alias Dialog
import { ArrowAliasModule } from './modules/arrow-alias/arrow-alias.module';
// ─────────────────────────────────────────────────────────────────────────────
// Add project dialog
import { AddProjectDialogModule } from './modules/add-project-dialog/add-project-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Edit project dialog
import { EditProjectDialogModule } from './modules/edit-project-dialog/edit-project-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Manage team dialog
import { ManageTeamDialogModule } from './modules/manage-team-dialog/manage-team-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Version Dialog Module
import { VersionDialogModule } from './modules/version-dialog/version-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Chunk dialog
import { ChunkDialogModule } from './modules/chunk-dialog/chunk-dialog.module';
// -----------------------------------------------------------------------------
// Import project dialog
import { ImportProjectDialogModule } from './modules/import-project-dialog/import-project-dialog.module';
// ─────────────────────────────────────────────────────────────────────────────
// Pipes
import { PipesModule } from './pipes/pipes.module';
// ─────────────────────────────────────────────────────────────────────────────
// Arrow explorer
import { ArrowExplorerModule } from './modules/arrow-explorer/arrow-explorer.module';

// Chunk item info editor dialog
import { ChunkItemInfoModule } from './modules/chunk-item-description/chunk-item-description.module';

@NgModule({
  imports: [
    CommonModule,
    // Router
    RouterModule,
    // Toast
    MatSnackBarModule,
    // Dialogs
    // Create dialog
    CreateDialogModule,
    // Confirm dialog
    ConfirmDialogModule,
    // Create Var dialog
    GlobalVarDialogModule,
    // UrlDialogModule
    UrlDialogModule,
    // Wath file dialog
    WatchFileDialogModule,
    // Chunk copy to notebook dialog
    ChunkCopyToDialogModule,
    // Databse controls dialog
    DuckDbControldDialogModule,
    // Arrow Alias
    ArrowAliasModule,
    // Add project dialog
    AddProjectDialogModule,
    // Edit project dialog
    EditProjectDialogModule,
    // Manage team dialog
    ManageTeamDialogModule,
    // Version dialog
    VersionDialogModule,
    // Chunk dialog
    ChunkDialogModule,
    // Import project dialog
    ImportProjectDialogModule,
    // Pipes
    PipesModule,
    // Arrow explorer
    ArrowExplorerModule,
    // Chunk item info editor dialog
    ChunkItemInfoModule,
  ],
  providers: [
    MessageService,
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      // TODO: should be refactored when token id will be acceptable and auth env will be fixed.
      // useClass: AuthHttpInterceptor,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
