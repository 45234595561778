<section class="card card-block">
  <div class="modal-body container">
    <div class="row">
      <h3 class="col-12">{{ options.message }}</h3>
    </div>
    @if (options.details) { <div class="row">
      <h5 class="col-12">{{ options.details }}</h5>
    </div> }
    @if (promptForInput) { <div class="row mb-2 ml-2 mr-2">
      <input class="col-12" type="text" [(ngModel)]="inputText" />
    </div> }
    <div class="row">
      <div class="col">&nbsp;</div>
      <div class="col-md-auto">
        @if (options.cancelText) { <button (click)="cancel()" class="btn btn-secondary mr-2">{{ options.cancelText }}</button> }
        <button (click)="ok()" class="btn btn-primary" [disabled]="okDisabled()">{{ options.okText }}</button>
      </div>
    </div>
  </div>
