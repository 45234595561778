import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// ─────────────────────────────────────────────────────────────────────────────
// Python pyodide compiler
import 'pyodide/pyodide.js';
// ─────────────────────────────────────────────────────────────────────────────
// Code mirror
// ─────────────────────────────────────────────────────────────────────────────
// Modes
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/stex/stex';
import 'codemirror/mode/python/python';
import 'codemirror/mode/sql/sql';
// ─────────────────────────────────────────────────────────────────────────────
// Addons
// -- display
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/display/fullscreen';
// -- comment
import 'codemirror/addon/comment/comment';
// -- selection
import 'codemirror/addon/selection/active-line';
// -- edit
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';
// -- fold
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
// -- lint
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/lint/javascript-lint';
// -- hint
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
// -- dialog
import 'codemirror/addon/dialog/dialog';
// -- search
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/jump-to-line';
// -- scroll
import 'codemirror/addon/scroll/annotatescrollbar';
// -- formatting
import 'codemirror-formatting';
// -- keymap
import 'codemirror/keymap/sublime';
// ─────────────────────────────────────────────────────────────────────────────
// Libs
import 'codemirror/lib/codemirror';
// ─────────────────────────────────────────────────────────────────────────────
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';

import { AppModule } from './app/app.module';

Sentry.init({
  dsn: 'https://edc6870038e442aba3c52587d8ce1be2@o4505322876174336.ingest.sentry.io/4505322892492800',
  // Called for message and error events
  beforeSendTransaction(event) {
    // Modify or drop the event here
    if (
      !environment.production ||
      event?.spans?.[0]?.description?.includes('pyodide')
    ) {
      return null;
    }

    return event;
  },
  sendClientReports: false,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/dev\.enlightenhq\.com\/api/,
        /^https:\/\/stage\.enlightenhq\.com\/api/,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));

function truncateString(str: any, length: any) {
  if (str.length <= length) {
    return str;
  }
  return str.substring(0, length - 3) + '...';
}
