import { Injectable } from '@angular/core';
import * as localforage from 'localforage'; // this works!!!
import {
  ERROR_GET_DATA,
  ERROR_NO_DATA,
  ERROR_PUT_DATA,
  ERROR_REMOVE_DATA,
} from '../../constants/additional-methods.constants';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalforageService {
  constructor() {
    // ─────────────────────────────────────────────────────────────
    // Driver is represents default order;
    localforage.config({
      name: 'nc-calc-storage',
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ],
      size: 4980736,
      storeName: 'nc-calc-storage',
      version: 1.0,
      description: '',
    });
  }

  async getItem(key: string) {
    const { promise, resolve, reject } = (Promise as any).withResolvers();

    localforage.getItem(key, (err, value) => {
      if (err) {
        reject(err);
      } else {
        resolve(value);
      }
    });

    return promise;
  }

  async setItem(key: string, value: any) {
    const { promise, resolve, reject } = (Promise as any).withResolvers();

    localforage.setItem(key, value, (err, value) => {
      if (err) {
        reject(err);
      } else {
        resolve(value);
      }
    });

    return promise;
  }

  async removeItem(key: string) {
    const { promise, resolve, reject } = (Promise as any).withResolvers();

    localforage.removeItem(key, (err) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });

    return promise;
  }

  async clear() {
    const { promise, resolve, reject } = (Promise as any).withResolvers();

    localforage.clear((err) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });

    return promise;
  }

  async keys() {
    const { promise, resolve, reject } = (Promise as any).withResolvers();

    localforage.keys((err, keys) => {
      if (err) {
        reject(err);
      } else {
        resolve(keys);
      }
    });

    return promise;
  }

  // -----------------------------
  // This method is used in quick.js and pyodide
  // -----------------------------

  async putData(args: any[], chunkContext: ExecutionContext) {
    const key = args[0];
    const value = args[1];
    if (!key || value === undefined) {
      // Adjusted to check for `value === undefined` to allow falsy values like 0 or false
      chunkContext.addMessage(ERROR_PUT_DATA, 'danger');
      return null;
    }
    return await this.setItem(key, value);
  }

  // -----------------------------
  // This method is used in quick.js and pyodide
  // -----------------------------
  async getData(args: any[], chunkContext: ExecutionContext) {
    const key = args[0];
    if (!key) {
      chunkContext.addMessage(ERROR_GET_DATA, 'danger');
      return null;
    }
    chunkContext.setBusy(true);
    const data = await this.getItem(key);
    if (data === null) {
      chunkContext.addMessage(ERROR_NO_DATA, 'danger');
      chunkContext.setBusy(false);
      return null;
    }
    chunkContext.setBusy(false);
    return data;
  }

  // -----------------------------
  // This method is used in quick.js and pyodide
  // -----------------------------
  async removeData(args: any[], chunkContext: ExecutionContext) {
    const key = args[0];
    if (!key) {
      chunkContext.addMessage(ERROR_REMOVE_DATA, 'danger');
      return null;
    }
    return await this.removeItem(key);
  }
}
