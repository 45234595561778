import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectFormComponent } from './project-form.component';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// ────────────────────────────────────────────────────────────────────────────────
// Layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Project list
// import { ProjectListModule } from '../project-list/project-list.module'
// ─────────────────────────────────────────────────────────────────────────────
// Version list
// import { VersionListModule } from '../version-list/version-list.module';
// import { ModuleListModule } from '../module-list/module-list.module'

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    // Form
    ReactiveFormsModule,
    FormsModule,
    // Layout
    FlexLayoutModule,
    // Project list
    // ProjectListModule,
    // Viersion list
    //VersionListModule,
    // Module list
    // ModuleListModule,
    // Notebook form module
  ],
  declarations: [ProjectFormComponent],
  exports: [ProjectFormComponent]
})
export class ProjectFormModule { }

