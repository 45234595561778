import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportProjectDialogComponent } from './import-project-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Material
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// ─────────────────────────────────────────────────────────────────────────────
// Form support
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// ─────────────────────────────────────────────────────────────────────────────
// Layput
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// File upload module
import { FileUploadModule } from '@iplab/ngx-file-upload';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    // Form support
    FormsModule,
    ReactiveFormsModule,
    // Layout
    FlexLayoutModule,
    // File upload module
    FileUploadModule,
  ],
  declarations: [ImportProjectDialogComponent],
  exports: [ImportProjectDialogComponent],
})
export class ImportProjectDialogModule {}
