export const DUCKDB_ACTIONS = [
  {
    icon: 'upgrade',
    action: 'export_to_json',
    tooltip: 'Export to json file',
    color: 'primary',
    aria: 'Export to json file',
    text: 'Export to json'
  },
  {
    icon: 'upgrade',
    action: 'export_to_csv',
    tooltip: 'Export to csv file',
    color: 'primary',
    aria: 'Export to csv file',
    text: 'Export to csv'
  },
]