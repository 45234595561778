export interface DBAlias {
  dbAliasId: number;
  projectId: number;
  name: string;
  host: string;
  port: string;
  username: string;
  password: string;
  dbname: string;
  options?: string;
}

export const EMPTY_DB_ALIAS: DBAlias = {
  dbAliasId: 0,
  projectId: 0,
  name: 'connection',
  host: '',
  port: '5432',
  username: '',
  password: '',
  dbname: '',
  options: '',
};
