import { Injectable, Optional } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { OAuthModuleConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable, of, merge, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  take,
  mergeMap,
  timeout,
} from 'rxjs/operators';
import { GeneralHelpers } from '../helpers/general.helper';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private auth0Service: AuthService,
    private oauthService: OAuthService,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('sentry')) {
      return next.handle(req);
    }

    const skipHeader = GeneralHelpers.getInterceptorSkipHeader();

    if (req.headers.has(skipHeader)) {
      const headers = req.headers.delete(skipHeader);
      return next.handle(req.clone({ headers }));
    }

    if (environment.authProvider === 'auth0') {
      return this.handleAuth0Request(req, next);
    } else if (environment.authProvider === 'zitadel') {
      return this.handleZitadelRequest(req, next);
    }

    return next.handle(req);
  }

  private handleAuth0Request(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth0Service.idTokenClaims$.pipe(
      mergeMap((token) => {
        if (token && token.__raw) {
          const headers = req.headers.set(
            'Authorization',
            `Bearer ${token.__raw}`
          );
          return next.handle(req.clone({ headers }));
        }
        return next.handle(req);
      }),
      catchError((err) => {
        // Handle Auth0 specific errors here if needed
        return this.handleError(err);
      })
    );
  }

  private handleZitadelRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.checkUrl(req.url)) {
      return next.handle(req);
    }

    return merge(
      of(this.oauthService.getIdToken()).pipe(filter((token) => !!token)),
      this.oauthService.events.pipe(
        filter((e) => e.type === 'token_received'),
        timeout(this.oauthService.waitForTokenInMsec || 0),
        catchError(() => of(null)), // timeout is not an error
        map(() => this.oauthService.getIdToken())
      )
    ).pipe(
      take(1),
      mergeMap((token) => {
        if (token) {
          const headers = req.headers.set('Authorization', `Bearer ${token}`);
          req = req.clone({ headers });
        }
        return next
          .handle(req)
          .pipe(catchError((err) => this.handleError(err)));
      })
    );
  }

  private checkUrl(url: string): boolean {
    if (this.moduleConfig?.resourceServer?.customUrlValidation) {
      return this.moduleConfig.resourceServer.customUrlValidation(url);
    }
    if (this.moduleConfig?.resourceServer?.allowedUrls) {
      return this.moduleConfig.resourceServer.allowedUrls.some(
        (allowedUrl: string) => {
          const pattern = allowedUrl.replace(/\*/g, '.*');
          const regex = new RegExp(`^${pattern}$`);
          return regex.test(url.split('://').pop() || '');
        }
      );
    }
    return true;
  }

  private handleError(err: any): Observable<never> {
    // Implement error handling logic here
    // You might want to create a separate error handler service
    console.error('An error occurred', err);
    return throwError(() => err);
  }
}
