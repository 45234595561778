import { Component, computed, inject, signal } from '@angular/core';
import { DBAlias, EMPTY_DB_ALIAS } from '../../../interfaces/db-alias.interface';
import { DBAliasService } from '../../../services/db-alias/dbalias.service';
import { NonNullableFormBuilder } from '@angular/forms'
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service'
import { catchError, EMPTY } from 'rxjs'
import { toObservable } from '@angular/core/rxjs-interop'
import { TableListColDef } from '../../table-list/table-list.component'

@Component({
  selector: 'alias-database-list',
  templateUrl: './database-list.component.html',
  styleUrl: './database-list.component.scss'
})
export class DatabaseListComponent {
  #dbAliasService = inject(DBAliasService);
  #fb = inject(NonNullableFormBuilder);
  #appState = inject(AppStateService);
  selectedDB = signal<DBAlias | undefined>(undefined);
  aliasList = computed(() => this.#dbAliasService.aliases());
  aliases$ = toObservable(this.#dbAliasService.aliases);
  errorMessage = signal<string>("");
  adding = signal(false);

  protected aliasListColumns: TableListColDef<DBAlias>[] = [
    { header: 'Name', field: 'name' },
//    { header: 'Alias ID', field: 'dbAliasId' },
  ];

  dbForm = this.#fb.group({
    'dbAliasId': [''],
    'name': [''],
    'host': [''],
    'port': [''],
    'username': [''],
    'password': [''],
    'dbname': [''],
  });

  disableButtons = computed(() => !this.selectedDB());

  aliasSelected(alias: DBAlias|undefined) {
    this.selectedDB.set(alias);
    if (alias) {
      this.loadAlias(alias);
      this.dbForm.enable();
  } else {
      this.dbForm.reset();
      this.dbForm.disable();
    }
  }

  loadAlias(source: DBAlias): void {
    const { dbAliasId, ...alias } = source;
    this.dbForm.patchValue({ dbAliasId: dbAliasId.toString(), ...alias });
  }

  doAddDB() {
    this.selectedDB.set(undefined);
    this.loadAlias(EMPTY_DB_ALIAS);
    this.errorMessage.set("");
    this.adding.set(true);
  }

  doDuplicateDB() {
    let db = this.selectedDB();
    if (!db) return;
    this.adding.set(false);
    this.errorMessage.set("");
    this.#dbAliasService.duplicateAlias(db.dbAliasId).subscribe();
  }

  doDeleteDB() {
    this.errorMessage.set("");
    let db = this.selectedDB();
    if (!db) return;
    this.#dbAliasService.deleteAlias(db.dbAliasId).subscribe();
    this.selectedDB.set(undefined);
    this.dbForm.reset();
  }

  doSaveDB() {
    this.errorMessage.set("");
    let { dbAliasId, ...db } = this.dbForm.value;
    let aliasId = parseInt(dbAliasId||"0");
    let updated = {dbAliasId: aliasId, projectId: this.#appState.projectId(), ...db };
    console.log(`updating to ${JSON.stringify(updated)}`);
    if (this.adding()) {
      this.#dbAliasService.createAlias(updated)
        .pipe(catchError( err => { this.errorMessage.set(err); return EMPTY; }))
        .subscribe((data) => {
          this.selectedDB.set(data);
          this.adding.set(false);
          this.#dbAliasService.refreshAliases().finally();
      });
    } else {
      this.#dbAliasService.saveAlias(updated)
        .pipe(catchError( err => { this.errorMessage.set(err); return EMPTY; }))
        .subscribe((data) => {
          this.#dbAliasService.refreshAliases().finally();
        });
    }
  }

  doResetForm() {
    let db = this.selectedDB();
    if (db) {
      this.loadAlias(db);
    } else {
      this.dbForm.reset();
    }
    this.errorMessage.set("");
  }
}

