<!-- Filter -->
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  <ng-container *ngIf="isFilterable">
    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="filter" />
    </mat-form-field>
  </ng-container>

  <!-- Control panel -->
  <ng-container *ngIf="isControlPanel">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <ng-container *ngFor="let control of controlList">
        <button
          type="button"
          mat-stroked-button
          [attr.color]="control.color"
          [attr.aria-label]="control.aria"
          [matTooltip]="control.tooltip"
          (click)="processToolbarCommand(control.action)"
        >
          <mat-icon>{{ control?.icon }}</mat-icon>
          <span *ngIf="control?.text"> {{ control?.text }}</span>
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>

<!-- Table -->
<div class="table-responsive">
  <table
    class="mat-elevation-z8"
    mat-table
    [dataSource]="tableDataSource"
    matSort
    (matSortChange)="sortTable($event)"
    summary="table preview for chunk results calculation"
  >
    <!-- action column -->
    <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="rowActionIcon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let element"
        [id]="rowActionIcon"
        (click)="emitRowAction(element)"
      >
        <button mat-button>
          <mat-icon>{{ rowActionIcon }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container
      *ngFor="let tableColumn of tableColumns"
      [matColumnDef]="tableColumn.name"
    >
      <!-- if sortable column header -->
      <ng-container *ngIf="tableColumn.isSortable; else notSortable">
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="tableColumn.name"
          [arrowPosition]="
            tableColumn.position === 'right' ? 'before' : 'after'
          "
        >
          {{ tableColumn.name }}
        </th>
      </ng-container>
      <!-- else not sortable -->
      <ng-template #notSortable>
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.text-right]="tableColumn.position == 'right'"
        >
          {{ tableColumn.name }}
        </th>
      </ng-template>

      <!-- column data -->
      <td
        mat-cell
        *matCellDef="let element"
        [class.text-right]="tableColumn.position == 'right'"
        (click)="emitRowClickAction(element)"
      >
        <ng-container
          *ngIf="
            isImageUrlOrBase64(element[tableColumn.dataKey]);
            else defaultData
          "
        >
          <img
            [src]="element[tableColumn.dataKey]"
            alt="Image"
            width="50"
            height="50"
          />
          <!-- adjust width and height as per your requirement -->
        </ng-container>

        <ng-template #defaultData>
          {{ element[tableColumn.dataKey] }}
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<!-- Pagination -->
<mat-paginator
  *ngIf="isPageable"
  [pageSizeOptions]="paginationSizes"
  [pageSize]="defaultPageSize"
  showFirstLastButtons
>
</mat-paginator>
