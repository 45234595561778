import { Injectable } from '@angular/core';
import { Subject, Observable, timer, BehaviorSubject } from 'rxjs';
import { takeUntil, filter, first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment.local';
import { RequestType, ResponseType, ResponseStatus } from './sam2.enums';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';
import { CacheService } from '../cache/cache.service';
@Injectable({
  providedIn: 'root',
})
export class Sam2WebsocketService {
  private socket!: WebSocket;
  public messages: Subject<any> = new Subject<any>();
  private WS_URL: string | undefined = environment.sam2WebsocketUrl;
  private currentSessionId: number = 0;
  private promptRequests: Map<number, { resolve: Function; reject: Function }> =
    new Map();
  private readonly TIMEOUT = 10000;
  private messageQueue: any[] = [];
  private isConnected$ = new BehaviorSubject<boolean>(false);
  private isEnabled: boolean = false;

  constructor(private cacheService: CacheService) {
    this.isEnabled = !!this.WS_URL;
  }

  public init(): Promise<void> {
    if (!this.isEnabled) {
      console.log('SAM2 WebSocket service is disabled - no URL configured');
      return Promise.resolve();
    }
    return this.connectToWebsocket();
  }

  public isServiceEnabled(): boolean {
    return this.isEnabled;
  }

  private connectToWebsocket(): Promise<void> {
    if (!this.isEnabled) {
      return Promise.resolve();
    }

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      try {
        this.socket = new WebSocket(this.WS_URL!);
        this.socket.binaryType = 'arraybuffer';

        this.socket.addEventListener('open', (event) => {
          console.log('WebSocket connection opened:', event);
          this.isConnected$.next(true);
          this.sendQueuedMessages();
          resolve();
        });

        this.socket.addEventListener('message', (event) => {
          this.handleMessage(event);
        });

        this.socket.addEventListener('close', (event) => {
          console.log('WebSocket connection closed:', event);
          this.isConnected$.next(false);
          // Implement reconnection logic here if service is still enabled
          if (this.isEnabled) {
            setTimeout(() => this.connectToWebsocket(), 5000);
          }
        });

        this.socket.addEventListener('error', (event) => {
          console.error('WebSocket error:', event);
          this.isConnected$.next(false);
          reject(event);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  public sendMessage(message: any): void {
    if (!this.isEnabled) {
      console.warn('SAM2 WebSocket service is disabled');
      return;
    }

    if (this.isConnected$.value) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open. Queueing message.');
      this.messageQueue.push(message);
    }
  }

  private sendQueuedMessages(): void {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      this.socket.send(JSON.stringify(message));
    }
  }

  public receiveMessage(): Observable<any> {
    return this.messages.asObservable();
  }

  public async healthcheck(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<any> {
    if (!this.isEnabled) {
      chunkContext.addLog('SAM2 WebSocket service is disabled', 'warning');
      return null;
    }
    chunkContext.addLog('Initiating healthcheck...', 'info');
    try {
      const message = {
        request_type: RequestType.HealthCheck,
      };
      await this.sendMessageWhenConnected(message);
      chunkContext.addLog('Healthcheck message sent', 'info');

      return new Promise<any>((resolve) => {
        const subscription = this.receiveMessage().subscribe((data) => {
          if (data.response_type === ResponseType.HealthCheck) {
            subscription.unsubscribe();
            chunkContext.addLog('Healthcheck response received', 'info');
            resolve(data);
          }
        });
      });
    } catch (error: any) {
      chunkContext.addLog(
        `Error during healthcheck: ${error.message}`,
        'error'
      );
      return null;
    }
  }

  public async sendSingleImage(
    args: any[],
    chunkContext?: ExecutionContext
  ): Promise<any> {
    if (!this.isEnabled) {
      console.warn('SAM2 WebSocket service is disabled');
      return null;
    }
    console.log('Initiating sendSingleImage...');
    try {
      if (args.length < 1) {
        chunkContext?.addLog(
          'Invalid number of arguments for sendSingleImage.',
          'error'
        );
        return null;
      }

      const [imageDataString, providedSessionId] = args;
      if (typeof imageDataString !== 'string') {
        chunkContext?.addLog(
          'Invalid argument type for imageDataString.',
          'error'
        );
        return null;
      }

      const session = this.getOrCreateSessionId(providedSessionId);
      chunkContext?.addLog(`Using session ID: ${session}`, 'info');

      const imageData = this.convertByteStringToBlob(imageDataString);
      chunkContext?.addLog('Image data converted to Blob', 'info');

      const message = {
        request_type: RequestType.SendSingleImage,
        session: session,
      };

      return new Promise<any>((resolve, reject) => {
        const timeout = setTimeout(() => {
          subscription.unsubscribe();
          reject(new Error('Timeout waiting for server response'));
        }, this.TIMEOUT);

        const subscription = this.receiveMessage().subscribe({
          next: (data) => {
            if (data.response_type === ResponseType.SingleImageReady) {
              clearTimeout(timeout);

              // Send the image data
              this.socket.send(imageData);
              chunkContext?.addLog('Image data sent to server', 'info');

              // Resolve with success response
              resolve({
                response_type: ResponseType.SingleImageReady,
                response_status: ResponseStatus.Ok,
                session: session,
                message: 'Server ready to receive image',
              });

              subscription.unsubscribe();
            }
          },
          error: (error) => {
            clearTimeout(timeout);
            reject(error);
            subscription.unsubscribe();
          },
        });

        // Send the initial message
        this.sendMessage(message);
        chunkContext?.addLog('SendSingleImage request sent', 'info');
      });
    } catch (error: any) {
      chunkContext?.addLog(
        `Error sending single image: ${error.message}`,
        'error'
      );
      return {
        response_type: ResponseType.SingleImageReady,
        response_status: ResponseStatus.Error,
        message: error.message,
      };
    }
  }

  public async sendPrompt(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<any> {
    if (!this.isEnabled) {
      chunkContext.addLog('SAM2 WebSocket service is disabled', 'warning');
      return null;
    }
    chunkContext.addLog('Initiating sendPrompt...', 'info');
    try {
      if (args.length < 2) {
        chunkContext.addLog(
          'Invalid number of arguments for sendPrompt.',
          'error'
        );
        return null;
      }

      const [prompt, providedSessionId] = args;
      const session = this.getOrCreateSessionId(providedSessionId);

      if (typeof prompt !== 'object') {
        chunkContext.addLog('Invalid argument type for prompt.', 'error');
        return null;
      }

      chunkContext.addLog(`Using session ID: ${session}`, 'info');

      const message = {
        request_type: RequestType.SendPrompt,
        session: session,
        prompt: prompt,
      };
      this.sendMessage(message);
      chunkContext.addLog('SendPrompt request sent', 'info');

      return new Promise<any>((resolve, reject) => {
        this.promptRequests.set(session, { resolve, reject });

        // Set up a timeout to ensure the promise is always resolved
        timer(this.TIMEOUT)
          .pipe(takeUntil(this.messages.pipe()))
          .subscribe(() => {
            if (this.promptRequests.has(session)) {
              chunkContext.addLog(
                `Timeout for sendPrompt (session ${session})`,
                'warning'
              );
              this.promptRequests.delete(session);
              resolve({
                response_type: ResponseType.ReceivePrompt,
                response_status: ResponseStatus.Warning,
                message: 'Timeout: No response received from server',
                session: session,
              });
            }
          });
      });
    } catch (error: any) {
      chunkContext.addLog(`Error sending prompt: ${error.message}`, 'error');
      return null;
    }
  }

  private async saveMaskAsNotebookFile(
    maskData: ArrayBuffer,
    maskNumber: number,
    session: number
  ): Promise<void> {
    const fileName = `mask_${session}_${maskNumber}.png`;
    const blob = new Blob([maskData], { type: 'image/png' });
    const file = new File([blob], fileName, { type: 'image/png' });

    const formData = new FormData();
    formData.append('payload', file);

    try {
      await this.cacheService.createCacheContent(formData).toPromise();
      console.log(`Mask ${fileName} saved as notebook file`);
    } catch (error) {
      console.error(`Error saving mask ${fileName}:`, error);
    }
  }

  public async refinePrompt(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<any> {
    if (!this.isEnabled) {
      chunkContext.addLog('SAM2 WebSocket service is disabled', 'warning');
      return null;
    }
    chunkContext.addLog('Initiating refinePrompt...', 'info');
    try {
      if (args.length < 2) {
        chunkContext.addLog(
          'Invalid number of arguments for refinePrompt.',
          'error'
        );
        return null;
      }

      const [session, prompt] = args;
      if (typeof session !== 'number' || typeof prompt !== 'object') {
        chunkContext.addLog(
          'Invalid argument types for refinePrompt.',
          'error'
        );
        return null;
      }

      const message = {
        request_type: RequestType.RefinePrompt,
        session: session,
        prompt: prompt,
      };
      this.sendMessage(message);
      chunkContext.addLog('RefinePrompt request sent', 'info');

      return new Promise<any>(async (resolve) => {
        const subscription = this.receiveMessage().subscribe(async (data) => {
          if (data.response_type === ResponseType.ReceivePrompt) {
            subscription.unsubscribe();

            if (data.response_status !== ResponseStatus.Ok) {
              chunkContext.addLog(
                `Error refining prompt: ${data.message}`,
                'error'
              );
              resolve(data);
              return;
            }

            chunkContext.addLog('Refined prompt response received', 'info');
            const masks: Blob[] = [];
            const scores = data.scores;

            for (let i = 0; i < scores.length; i++) {
              chunkContext.addLog(
                `Receiving refined mask ${i + 1} of ${scores.length}`,
                'info'
              );
              const maskData = await this.receiveBinaryData();
              masks.push(new Blob([maskData], { type: 'image/png' }));
            }

            chunkContext.addLog('All refined masks received', 'info');
            resolve({ ...data, masks });
          }
        });
      });
    } catch (error: any) {
      chunkContext.addLog(`Error refining prompt: ${error.message}`, 'error');
      return null;
    }
  }

  private async receiveBinaryData(): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve) => {
      this.socket.binaryType = 'arraybuffer';
      const onMessage = (event: MessageEvent) => {
        if (event.data instanceof ArrayBuffer) {
          this.socket.removeEventListener('message', onMessage);
          resolve(event.data);
        }
      };
      this.socket.addEventListener('message', onMessage);
    });
  }

  private convertByteStringToBlob(byteString: string): Blob {
    const byteNumbers = byteString.split(',').map(Number);
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'image/png' });
  }

  private getOrCreateSessionId(providedSessionId?: number): number {
    if (typeof providedSessionId === 'number') {
      return providedSessionId;
    }
    return this.currentSessionId++;
  }

  private handleMessage(event: MessageEvent): void {
    if (event.data === undefined) {
      console.warn('Received undefined message from WebSocket');
      return;
    }

    if (event.data instanceof ArrayBuffer) {
      // Handle binary data (masks)
      this.handleBinaryData(event.data);
    } else if (typeof event.data === 'string') {
      // Handle JSON data
      try {
        const data = JSON.parse(event.data);
        this.messages.next(data);

        if (data.response_type === ResponseType.ReceivePrompt) {
          const request = this.promptRequests.get(data.session);
          if (request) {
            this.promptRequests.delete(data.session);
            request.resolve(data);
          }
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
        console.error('Received data:', event.data);
      }
    } else {
      console.warn(
        'Received unexpected data type from WebSocket:',
        typeof event.data
      );
    }
  }

  private async handleBinaryData(arrayBuffer: ArrayBuffer): Promise<void> {
    const session = this.currentSessionId; // You might need to adjust this to get the correct session ID
    const maskNumber = Date.now(); // Use timestamp as a unique identifier
    await this.saveMaskAsNotebookFile(arrayBuffer, maskNumber, session);
  }

  private async sendMessageWhenConnected(message: any): Promise<void> {
    if (this.isConnected$.value) {
      this.sendMessage(message);
    } else {
      await this.isConnected$
        .pipe(
          filter((isConnected) => isConnected),
          first()
        )
        .toPromise();
      this.sendMessage(message);
    }
  }
}
