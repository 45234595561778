import { Injectable } from '@angular/core';
import { MessageService } from '../message/message.service';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import { ChunkService } from '../chunk/chunk.service';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { ToastrService } from 'ngx-toastr';

interface ModuleRegistryEntry {
  chunk: Chunk;
  namespace: any;
  isLoaded: boolean;
  lastUsed: number;
  useCount: number;
  dependencies: string[];
}

interface ChunkDependencyNode {
  chunk: Chunk;
  dependencies: Set<string>;
  dependents: Set<string>;
}

@Injectable({
  providedIn: 'root',
})
export class PythonModulesService {
  private moduleRegistry: Map<string, ModuleRegistryEntry> = new Map();
  private pythonRuntime: any;
  private availableChunks: Chunk[] = [];
  private dependencyGraph: Map<string, ChunkDependencyNode> = new Map();

  // Cache settings
  private readonly MAX_CACHE_SIZE = 50;
  private readonly CACHE_CLEANUP_THRESHOLD = 0.8;

  constructor(
    private messageService: MessageService,
    private chunkService: ChunkService,
    private toastr: ToastrService
  ) { }

  /**
   * Initializes the service with Python runtime and loads all chunks for the notebook
   */
  async initialize(runtime: any) {
    this.pythonRuntime = runtime;

    try {
      this.availableChunks = this.filterPythonChunks(this.chunkService.chunkList);
      await this.registerChunksAsModules(this.availableChunks);
    } catch (error) {
      console.error('Error loading chunks during initialization:', error);
      this.toastr.error('Failed to initialize Python modules', 'Initialization Error');
    }
  }

  /**
   * Registers all chunks as potential Python modules
   */
  private async registerChunksAsModules(chunks: Chunk[]) {
    for (const chunk of chunks) {
      if (chunk.content?.trim()) {
        try {
          await this.registerChunkAsModule(chunk);
        } catch (error) {
          console.error(`Error registering chunk ${chunk.name} as module:`, error);
        }
      }
    }
  }

  /**
   * Registers a chunk as a Python module with caching
   */
  async registerChunkAsModule(
    chunk: Chunk,
    context?: ChunkContext
  ): Promise<boolean> {
    if (!this.pythonRuntime) {
      throw new Error('Python runtime not initialized');
    }

    const moduleName = this.sanitizeModuleName(chunk.name);

    // Validate execution order before registration
    if (context && !(await this.validateExecutionOrder(chunk, context))) {
      return false;
    }

    try {
      const success = await this.createPythonModule(chunk, moduleName);

      if (success) {
        this.moduleRegistry.set(moduleName, {
          chunk,
          namespace: await this.pythonRuntime.globals.get(moduleName),
          isLoaded: true,
          lastUsed: Date.now(),
          useCount: 0,
          dependencies: this.extractDependencies(chunk.content)
        });
      }

      return success;
    } catch (error) {
      const errorMessage = `Error registering chunk ${chunk.name} as module: ${error}`;
      console.error(errorMessage);

      if (context) {
        context.addMessage(errorMessage, 'danger');
      }

      throw error;
    }
  }

  /**
   * Creates a Python module from a chunk
   */
  private async createPythonModule(chunk: Chunk, moduleName: string): Promise<boolean> {
    const setupCode = `
      import sys
      from types import ModuleType

      def create_module(name, code):
          try:
              module = ModuleType(name)
              sys.modules[name] = module
              exec(code, module.__dict__)
              return True
          except Exception as e:
              if name in sys.modules:
                  del sys.modules[name]
              raise e
    `;

    await this.pythonRuntime.runPythonAsync(setupCode);
    return await this.pythonRuntime.runPythonAsync(
      `create_module("${moduleName}", """${chunk.content}""")`
    );
  }

  /**
   * Validates execution order of chunks based on dependencies
   */
  async validateExecutionOrder(currentChunk: Chunk, context: ChunkContext): Promise<boolean> {
    const dependencies = this.extractDependencies(currentChunk.content);
    const currentChunkModuleName = this.sanitizeModuleName(currentChunk.name);

    // Build or update dependency graph
    this.updateDependencyGraph(currentChunk, dependencies);

    // Check for circular dependencies
    if (this.hasCircularDependency(currentChunkModuleName)) {
      this.toastr.error(
        `Circular dependency detected in chunk "${currentChunk.name}"`,
        'Execution Order Error'
      );
      return false;
    }

    // Validate execution order based on sort order
    for (const depName of dependencies) {
      const depChunk = this.findMatchingChunk(depName);
      if (depChunk && depChunk.sortOrder >= currentChunk.sortOrder) {
        this.toastr.warning(
          `Chunk "${currentChunk.name}" imports "${depChunk.name}" which appears later in the notebook. This may cause issues.`,
          'Execution Order Warning'
        );
        context.addMessage(
          `Warning: Imported chunk "${depChunk.name}" should be placed before this chunk`,
          'warning'
        );
        return false;
      }
    }

    return true;
  }

  /**
   * Updates the dependency graph for a chunk
   */
  private updateDependencyGraph(chunk: Chunk, dependencies: string[]) {
    const chunkName = this.sanitizeModuleName(chunk.name);

    if (!this.dependencyGraph.has(chunkName)) {
      this.dependencyGraph.set(chunkName, {
        chunk,
        dependencies: new Set(),
        dependents: new Set()
      });
    }

    const node = this.dependencyGraph.get(chunkName)!;
    node.dependencies.clear();

    for (const dep of dependencies) {
      node.dependencies.add(dep);

      if (!this.dependencyGraph.has(dep)) {
        const depChunk = this.findMatchingChunk(dep);
        if (depChunk) {
          this.dependencyGraph.set(dep, {
            chunk: depChunk,
            dependencies: new Set(),
            dependents: new Set()
          });
        }
      }

      const depNode = this.dependencyGraph.get(dep);
      if (depNode) {
        depNode.dependents.add(chunkName);
      }
    }
  }

  /**
   * Checks for circular dependencies
   */
  private hasCircularDependency(startNode: string): boolean {
    const visited = new Set<string>();
    const recursionStack = new Set<string>();

    const dfs = (node: string): boolean => {
      if (!this.dependencyGraph.has(node)) return false;
      if (recursionStack.has(node)) return true;
      if (visited.has(node)) return false;

      visited.add(node);
      recursionStack.add(node);

      const dependencies = this.dependencyGraph.get(node)!.dependencies;
      for (const dep of dependencies) {
        if (dfs(dep)) return true;
      }

      recursionStack.delete(node);
      return false;
    };

    return dfs(startNode);
  }

  /**
   * Imports necessary modules for a chunk based on its content
   */
  async importModulesForChunk(content: string, context: ChunkContext): Promise<void> {
    const moduleImports = this.extractDependencies(content);

    for (const moduleName of moduleImports) {
      if (this.moduleRegistry.has(moduleName)) {
        try {
          // Update module usage statistics
          const entry = this.moduleRegistry.get(moduleName)!;
          entry.lastUsed = Date.now();
          entry.useCount++;

          await this.importModule(moduleName);
        } catch (error) {
          const errorMessage = `Error importing module ${moduleName}: ${error}`;
          console.error(errorMessage);
          context.addMessage(errorMessage, 'danger');
          throw error;
        }
      }
    }

    // Cleanup cache if necessary
    if (this.moduleRegistry.size > this.MAX_CACHE_SIZE * this.CACHE_CLEANUP_THRESHOLD) {
      this.cleanupCache();
    }
  }

  /**
   * Imports a specific module
   */
  private async importModule(moduleName: string): Promise<any> {
    if (!this.moduleRegistry.has(moduleName)) {
      throw new Error(`Module ${moduleName} not found in registry`);
    }

    const entry = this.moduleRegistry.get(moduleName)!;

    if (!entry.isLoaded) {
      await this.registerChunkAsModule(entry.chunk);
    }

    try {
      await this.pythonRuntime.runPythonAsync(`
        if "${moduleName}" not in sys.modules:
            import ${moduleName}
      `);

      return this.pythonRuntime.globals.get(moduleName);
    } catch (error) {
      console.error(`Error importing module ${moduleName}:`, error);
      throw error;
    }
  }

  /**
   * Updates a module when its chunk content changes
   */
  async updateChunkModule(
    chunk: Chunk,
    context?: ChunkContext
  ): Promise<boolean> {
    const moduleName = this.sanitizeModuleName(chunk.name);

    try {
      // Remove the old module
      await this.pythonRuntime.runPythonAsync(`
        if "${moduleName}" in sys.modules:
            del sys.modules["${moduleName}"]
      `);

      // Register with new code
      return await this.registerChunkAsModule(chunk, context);
    } catch (error) {
      const errorMessage = `Error updating module for chunk ${chunk.name}: ${error}`;
      console.error(errorMessage);

      if (context) {
        context.addMessage(errorMessage, 'danger');
      }

      throw error;
    }
  }

  /**
   * Removes a module when its chunk is deleted
   */
  async removeChunkModule(chunk: Chunk): Promise<boolean> {
    const moduleName = this.sanitizeModuleName(chunk.name);

    if (!this.moduleRegistry.has(moduleName)) {
      return false;
    }

    try {
      await this.pythonRuntime.runPythonAsync(`
        if "${moduleName}" in sys.modules:
            del sys.modules["${moduleName}"]
      `);

      this.moduleRegistry.delete(moduleName);
      return true;
    } catch (error) {
      console.error(`Error removing module for chunk ${chunk.name}:`, error);
      throw error;
    }
  }

  /**
   * Extracts import dependencies from Python code and matches them against available chunks
   */
  private extractDependencies(code: string): string[] {
    const dependencies = new Set<string>();

    const importStatements = code.match(/^(?:from\s+(\S+)\s+import|import\s+([^as\s]+))/gm) || [];

    for (const statement of importStatements) {
      const fromMatch = statement.match(/from\s+(\S+)\s+import/);
      const importMatch = statement.match(/import\s+([^as\s]+)/);

      const moduleName = (fromMatch?.[1] || importMatch?.[1] || '').split('.')[0];

      const matchingChunk = this.findMatchingChunk(moduleName);

      if (matchingChunk) {
        dependencies.add(this.sanitizeModuleName(matchingChunk.name));
      }
    }

    return Array.from(dependencies);
  }

  /**
   * Finds a matching chunk for a given import name
   */
  private findMatchingChunk(importName: string): Chunk | undefined {
    const sanitizedImport = this.sanitizeModuleName(importName);

    return this.availableChunks.find(chunk =>
      this.sanitizeModuleName(chunk.name) === sanitizedImport ||
      chunk.name === importName
    );
  }

  /**
   * Cleans up least recently used modules from cache
   */
  private cleanupCache() {
    const entries = Array.from(this.moduleRegistry.entries());

    entries.sort((a, b) => {
      const timeWeight = 0.7;
      const countWeight = 0.3;

      const timeDiff = b[1].lastUsed - a[1].lastUsed;
      const countDiff = b[1].useCount - a[1].useCount;

      return timeWeight * timeDiff + countWeight * countDiff;
    });

    const entriesToRemove = entries.slice(Math.floor(this.MAX_CACHE_SIZE * 0.6));

    for (const [moduleName, entry] of entriesToRemove) {
      try {
        this.moduleRegistry.delete(moduleName);
        this.pythonRuntime.runPythonAsync(`
          if "${moduleName}" in sys.modules:
              del sys.modules["${moduleName}"]
        `);
      } catch (error) {
        console.error(`Error cleaning up module ${moduleName}:`, error);
      }
    }
  }

  /**
   * Filters Python chunks from a list of chunks
   */
  private filterPythonChunks(chunks: Chunk[]): Chunk[] {
    return chunks.filter(chunk => chunk.chunktypeId === 3);
  }

  /**
   * Updates available chunks when chunks change
   */
  updateAvailableChunks(chunks: Chunk[]) {
    this.availableChunks = this.filterPythonChunks(chunks);
    this.registerChunksAsModules(this.availableChunks).catch(error => {
      console.error('Error updating chunk modules:', error);
    });
  }

  /**
   * Gets cache statistics for monitoring
   */
  getCacheStats() {
    return {
      totalModules: this.moduleRegistry.size,
      maxSize: this.MAX_CACHE_SIZE,
      modules: Array.from(this.moduleRegistry.entries()).map(([name, entry]) => ({
        name,
        lastUsed: new Date(entry.lastUsed).toISOString(),
        useCount: entry.useCount
      }))
    };
  }

  /**
   * Sanitizes chunk name for use as a Python module name
   */
  private sanitizeModuleName(name: string): string {
    return name.replace(/[^a-zA-Z0-9_]/g, '_').toLowerCase();
  }

  /**
   * Lists all registered chunk modules
   */
  listChunkModules(): Chunk[] {
    return Array.from(this.moduleRegistry.values()).map(entry => entry.chunk);
  }

  /**
   * Gets a specific module's information
   */
  getModule(moduleName: string): ModuleRegistryEntry | undefined {
    return this.moduleRegistry.get(moduleName);
  }
}
