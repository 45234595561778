import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tableFromIPC } from 'apache-arrow';
import { fromArrow } from 'arquero';
import { Papa } from 'ngx-papaparse';
import { ConvertService } from '../convert/convert.service';
import { GeneralHelpers } from '../../helpers/general.helper';
import { FileHelpers } from '../../helpers/file.helper';
import { RenderData } from '../../interfaces/table-result.interface';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import {
  ERROR_DISPLAY_IMAGE,
  ERROR_DISPLAY_TABLE,
} from '../../constants/additional-methods.constants';
import { MessageService } from '../message/message.service';
import { RUN_CHUNK, SCROLL_TO_CHUNK } from '../../constants/general.constants';
import { ChunkService } from '../chunk/chunk.service';
import { FileUnifiedService } from '../file-unified/file-unified.service';

@Injectable({
  providedIn: 'root',
})
export class RenderService {
  public renderList: RenderData[] = [];
  public renderServiceSubject$ = new Subject<RenderData>();

  constructor(
    private readonly papa: Papa,
    private readonly convertService: ConvertService,
    private readonly messageService: MessageService,
    private readonly chunkService: ChunkService,
    private readonly fileUnifiedService: FileUnifiedService
  ) { }

  public addToRenderList(data: RenderData): void {
    const { value, chunkId, type } = data;
    const renderItem = { value, chunkId, type };
    this.renderServiceSubject$.next(renderItem);
  }

  public renderVideo(args: any[], context: ChunkContext) {
    if (!Array.isArray(args) || args.length === 0) {
      const error = new Error('Error displaying video');
      context.addMessage(error.message, 'danger');
      throw error;
    }

    const video = args[0];
    const chunkData = context.getChunk();

    if (video === null || video === undefined) {
      const error = new Error('Error displaying video');
      context.addMessage(error.message, 'danger');
      throw error;
    }

    // If it's a URL (including YouTube), handle it directly
    if (typeof video === 'string' && this.isVideoPath(video)) {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
      if (youtubeRegex.test(video)) {
        this.addToRenderList({
          value: video,
          chunkId: chunkData.chunkId,
          type: 'youtube',
        });
        return;
      }
      this.addToRenderList({
        value: video,
        chunkId: chunkData.chunkId,
        type: 'video',
      });
      return;
    }

    // If it's a byte array string, convert it to a video blob
    if (
      typeof video === 'string' &&
      GeneralHelpers.canBeParsedToNumberArray(video)
    ) {
      const numberArray = video.split(',').map(Number);
      const videoBuffer = new Uint8Array(numberArray);
      const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(videoBlob);
      this.addToRenderList({
        value: videoUrl,
        chunkId: chunkData.chunkId,
        type: 'video',
      });
      return;
    }

    // If it's a string that looks like a filename, try to load it from notebook files
    if (typeof video === 'string') {
      // Try to load the file
      context.setBusy(true, 'Loading video file');
      this.fileUnifiedService.getFile([video], context)
        .then((fileData) => {
          if (fileData) {
            try {
              // Convert the file data to a blob URL
              const videoBlob = new Blob([fileData], { type: 'video/mp4' });
              const videoUrl = URL.createObjectURL(videoBlob);
              this.addToRenderList({
                value: videoUrl,
                chunkId: chunkData.chunkId,
                type: 'video',
              });
            } catch (error: any) {
              context.setChunkResultStatus("error");
              const errorMsg = `Error processing video file: ${error.message}`;
              context.addMessage(errorMsg, 'danger');
              throw new Error(errorMsg);
            }
          } else {
            const errorMsg = `Could not load video file: ${video}`;
            context.addMessage(errorMsg, 'danger');
            throw new Error(errorMsg);
          }
        })
        .catch((error) => {
          context.setChunkResultStatus("error");
          context.addMessage(`Error loading video file: ${error.message}`, 'danger');
          throw error;
        })
        .finally(() => {
          context.setBusy(false);
        });
      return;
    }

    const error = new Error('Invalid video data');
    context.addMessage(error.message, 'danger');
    throw error;
  }

  private isVideoPath(str: string): boolean {
    try {
      new URL(str);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async renderTable(args: any[], context: ChunkContext) {
    const chunkData = context.getChunk();
    let tableData = args[0];

    if (!chunkData?.chunkId || args === null || args === undefined) {
      const error = new Error(ERROR_DISPLAY_TABLE);
      context.addMessage(ERROR_DISPLAY_TABLE, 'danger');
      throw error;
    }

    try {
      if (typeof tableData === 'string') {
        const fileType: string =
          FileHelpers.detectFileTypeFromByteString(tableData);

        switch (fileType) {
          case 'CSV':
            tableData = await this.processByteStringToCsv(tableData);
            break;
          case 'JSON':
            tableData = this.processJsonData(tableData);
            break;
          case 'Arrow':
          case 'Parquet':
            tableData = await this.processArrowOrParquetData(
              tableData,
              fileType
            );
            break;
          default:
            if (GeneralHelpers.canBeParsedToNumberArray(tableData)) {
              tableData = await this.processByteStringToCsv(tableData);
            } else {
              throw new Error('Unknown string data type');
            }
        }
      } else if (typeof tableData === 'object') {
        if (GeneralHelpers.isArrowTable(tableData)) {
          tableData = await this.processArrowTable(tableData);
        } else {
          tableData = this.processObjectData(tableData);
        }
      } else {
        throw new Error('Unsupported data type');
      }

      const formattedData = this.formatDataForTableComponent(tableData);
      this.addToRenderList({
        value: formattedData,
        chunkId: chunkData.chunkId,
        type: 'table',
      });
    } catch (error: any) {
      context.addMessage(
        `Error processing table data: ${error.message}`,
        'danger'
      );

      throw error; // Rethrow to allow try/catch to work
    }
  }

  private async processByteStringToCsv(data: string): Promise<any> {
    let csvString: string;

    if (GeneralHelpers.canBeParsedToNumberArray(data)) {
      const numberArray = data.split(',').map(Number);
      const uint8Array = new Uint8Array(numberArray);
      csvString = new TextDecoder().decode(uint8Array);
    } else {
      csvString = data; // Assume it's already a CSV string
    }

    return new Promise((resolve, reject) => {
      this.papa.parse(csvString, {
        complete: (results) => {
          if (results.errors.length > 0) {
            reject(
              new Error('CSV parsing error: ' + results.errors[0].message)
            );
          } else {
            resolve(results.data);
          }
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    });
  }

  private processJsonData(data: string): any {
    const json = GeneralHelpers.jsonParse(data);
    return Array.isArray(json) ? json : [json];
  }

  private async processArrowOrParquetData(
    data: string,
    fileType: string
  ): Promise<any> {
    const numberArray = data.split(',').map(Number);
    let arrowBuffer = new Uint8Array(numberArray);

    if (fileType === 'Parquet') {
      arrowBuffer = await this.convertService.parquetToArrow([data]);
    }

    const arrowTable = tableFromIPC(arrowBuffer);
    return this.processArrowTable(arrowTable);
  }

  private async processArrowTable(arrowTable: any): Promise<any> {
    const arqueroTable = fromArrow(arrowTable);

    try {
      const tableJson = arqueroTable.toJSON();
      return GeneralHelpers.jsonParse(tableJson);
    } catch (error) {
      const csvData = arqueroTable.toCSV();
      console.error('Error converting Arrow table to JSON:', error);
      return this.processByteStringToCsv(csvData);
    }
  }

  private processObjectData(data: any): any {
    return Array.isArray(data) ? data : [data];
  }

  private formatDataForTableComponent(data: any): any {
    let formattedData: any[] = [];
    let columns: any[] = [];

    if (data.schema && data.data) {
      // Handle Arrow-like format
      const fieldNames = data.schema.fields.map((field: any) => field.name);
      const rowCount = data.data[fieldNames[0]].length;

      for (let i = 0; i < rowCount; i++) {
        const row: any = {};
        fieldNames.forEach((fieldName: string) => {
          row[fieldName] = data.data[fieldName][i];
        });
        formattedData.push(row);
      }

      columns = fieldNames.map((fieldName: string) => ({
        name: `${fieldName} (${typeof formattedData[0][fieldName]})`,
        dataKey: fieldName,
        isSortable: true,
      }));
    } else if (Array.isArray(data)) {
      // Handle array of objects
      formattedData = data;
      if (formattedData.length > 0) {
        columns = Object.keys(formattedData[0]).map((key) => ({
          name: `${key} (${typeof formattedData[0][key]})`,
          dataKey: key,
          isSortable: true,
        }));
      }
    } else if (typeof data === 'object') {
      // Handle single object
      formattedData = [data];
      columns = Object.keys(data).map((key) => ({
        name: `${key} (${typeof data[key]})`,
        dataKey: key,
        isSortable: true,
      }));
    } else {
      console.error('Unsupported data format');
      return { data: [], columns: [] };
    }

    return {
      data: formattedData,
      columns: columns,
    };
  }

  public displayImageGroup(args: any[], context: ChunkContext) {
    if (!Array.isArray(args) || args.length === 0) {
      const error = new Error('Error displaying image group');
      context.addMessage(error.message, 'danger');
      throw error;
    }
    try {
      const imageGroup = JSON.parse(args[0]);
      if (
        !imageGroup ||
        typeof imageGroup !== 'object' ||
        imageGroup.kind !== 'imageGroup'
      ) {
        const error = new Error('Invalid image group data');
        context.addMessage(error.message, 'danger');
        throw error;
      }
      context.showImageGroup(imageGroup);
    } catch (error: any) {
      // Add the message but also rethrow so try/catch works
      context.addMessage(
        `Error displaying image group: ${error.message}`,
        'danger'
      );
      throw error; // Rethrow to allow try/catch to work
    }
  }

  public async renderImage(args: any[], context: ChunkContext) {
    if (!Array.isArray(args) || args.length === 0) {
      const error = new Error(ERROR_DISPLAY_IMAGE);
      context.addMessage(ERROR_DISPLAY_IMAGE, 'danger');
      throw error;
    }

    let image = args[0];
    const chunkData = context.getChunk();

    if (!chunkData?.chunkId) {
      const error = new Error(ERROR_DISPLAY_IMAGE);
      context.addMessage(ERROR_DISPLAY_IMAGE, 'danger');
      throw error;
    }

    try {
      // If image is a string that doesn't look like base64 or URL, try to load it as a file
      if (
        typeof image === 'string' &&
        !image.startsWith('data:image/') &&
        !image.startsWith('http') &&
        !GeneralHelpers.canBeParsedToNumberArray(image)
      ) {
        // Try to load the file
        context.setBusy(true, 'Loading image file');
        try {
          const fileData = await this.fileUnifiedService.getFile([image], context);
          if (fileData) {
            image = fileData;
          } else {
            const errorMsg = `Could not load image file: ${image}`;
            context.addMessage(errorMsg, 'danger');
            throw new Error(errorMsg);
          }
        } finally {
          context.setBusy(false);
        }
      }

      if (GeneralHelpers.isValidImageSource(image)) {
        try {
          const base64Data = await GeneralHelpers.convertToBase64(image);
          this.addToRenderList({
            value: `${GeneralHelpers.getMimeType('png')}${base64Data}`,
            chunkId: chunkData.chunkId,
            type: 'image',
          });
        } catch (error: any) {
          context.setChunkResultStatus("error");
          const errorMsg = `Error processing image data: ${error.message}`;
          context.addMessage(errorMsg, 'danger');
          throw new Error(errorMsg);
        }
      } else if (typeof image === 'string' && GeneralHelpers.canBeParsedToNumberArray(image)) {
        try {
          const numberArray = image.split(',').map(Number);
          const imageBuffer = new Uint8Array(numberArray);
          const imageBlob = new Blob([imageBuffer], { type: 'image/png' });
          const imageUrl = URL.createObjectURL(imageBlob);
          this.addToRenderList({
            value: imageUrl,
            chunkId: chunkData.chunkId,
            type: 'image',
          });
        } catch (error: any) {
          context.setChunkResultStatus("error");
          const errorMsg = `Error processing byte array image: ${error.message}`;
          context.addMessage(errorMsg, 'danger');
          throw new Error(errorMsg);
        }
      } else if (typeof image === 'string' && (image.startsWith('data:image/') || image.startsWith('http'))) {
        // Handle base64 images and URLs
        this.addToRenderList({
          value: image,
          chunkId: chunkData.chunkId,
          type: 'image',
        });
      } else {
        const errorMsg = 'Invalid image data: must be a byte array, base64 string, or URL';
        context.addMessage(errorMsg, 'danger');
        context.setChunkResultStatus("error");
        throw new Error(errorMsg);
      }
    } catch (error: any) {
      // Log to Messages tab but also throw so try/catch works
      context.addMessage(`Error displaying image: ${error.message}`, 'danger');
      context.setChunkResultStatus("error");
      throw error;
    }
  }

  public goToBlock(data: any[], context: ChunkContext) {
    try {
      const chunkName = data[0];
      if (!chunkName) {
        const error = new Error('Invalid block name');
        context.addMessage(error.message, 'danger');
        throw error;
      }
      const chunk = this.chunkService.getChunkByName(chunkName);
      if (chunk === null) {
        const error = new Error('Block not found');
        context.addMessage(error.message, 'danger');
        throw error;
      }
      const shouldRun = data[1];
      this.messageService.sendMessage(SCROLL_TO_CHUNK, chunk);
      if (shouldRun) {
        this.messageService.sendMessage(RUN_CHUNK, chunk);
      }
    } catch (error: any) {
      if (error.message !== 'Invalid block name' && error.message !== 'Block not found') {
        context.addMessage(`Error navigating to block: ${error.message}`, 'danger');
      }
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  public log(
    data: any[],
    context: ChunkContext,
  ) {
    try {
      if (!Array.isArray(data)) {
        const error = new Error('Invalid log data: must be an array');
        context.addMessage(error.message, 'danger');
        throw error;
      }

      const message = typeof data[0] === 'boolean' ? data[0].toString() : data[0];
      const severity = data[1];

      context.addLog(message, severity);
    } catch (error: any) {
      context.addMessage(`Error logging data: ${error.message}`, 'danger');
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  public output(
    data: any[],
    context: ChunkContext,
    typeRunner: 'javascript' | 'python'
  ) {
    try {
      if (!Array.isArray(data)) {
        const error = new Error('Invalid output data: must be an array');
        context.addMessage(error.message, 'danger');
        throw error;
      }
      context.addOutput(data[0], data[1], typeRunner);
    } catch (error: any) {
      context.addMessage(`Error adding output: ${error.message}`, 'danger');
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  /**
     * Renders a chart visualization
     * @param args Array of arguments from Python, where args[0] is the chart data
     * @param context The chunk context
     */
  public renderChart(args: any[], context: ChunkContext): void {
    if (!Array.isArray(args) || args.length === 0) {
      const error = new Error('Error displaying chart: No data provided');
      context.addMessage(error.message, 'danger');
      throw error;
    }

    const chartData = args[0];
    const chunkData = context.getChunk();

    if (!chunkData?.chunkId) {
      const error = new Error('Error displaying chart: Invalid chunk context');
      context.addMessage(error.message, 'danger');
      throw error;
    }

    try {
      // Mark the chunk as processing to prevent race conditions
      context.setBusy(true, 'Preparing chart visualization');

      // Add to render list to trigger the component flow
      this.addToRenderList({
        value: chartData,
        chunkId: chunkData.chunkId,
        type: 'chart',
        name: 'Chart' // Or extract a more specific name from the data if available
      });
    } catch (error: any) {
      context.setChunkResultStatus("error");
      const errorMsg = `Error processing chart data: ${error.message}`;
      context.addMessage(errorMsg, 'danger');
      throw error; // Rethrow to allow try/catch blocks to work
    } finally {
      context.setBusy(false);
    }
  }
}
