<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="'close'"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <form
    name="versionForm"
    [formGroup]="versionForm"
    novalidate
    (ngSubmit)="
      processVersionForm(versionForm.value, versionForm.valid, $event)
    "
  >
    <mat-card-content>
      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="30px"
        class="mb-3"
      >
        <mat-form-field appearance="outline" class="no-wrapper w100">
          <mat-label>Enter Major Version</mat-label>
          <input
            matInput
            formControlName="majorVersion"
            [mask]="pattern"
            [validation]="false"
            required
          />
          <mat-hint>Only digits allowed</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="no-wrapper w100">
          <mat-label>Enter Minor Version</mat-label>
          <input
            matInput
            formControlName="minorVersion"
            [mask]="pattern"
            [validation]="false"
            required
          />
          <mat-hint>Only digits allowed</mat-hint>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
      <button
        mat-stroked-button
        color="primary"
        [mat-dialog-close]="data"
        *ngIf="data?.confirmCaption"
        class="mr-2"
        type="submit"
      >
        {{ data.confirmCaption }}
      </button>
      <button
        mat-stroked-button
        color="warn"
        [mat-dialog-close]="'cancel'"
        *ngIf="data?.cancelCaption"
        type="button"
      >
        {{ data.cancelCaption }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
