import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
// ─────────────────────────────────────────────────────────────────────────────
import { Dialog } from '../../interfaces/create-dialog.interface';
// ─────────────────────────────────────────────────────────────────────────────
// Create dialog
import { ConfirmDialogComponent } from '../../modules/confirm-dialog/confirm-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Create dialog
import { CreateDialogComponent } from '../../modules/create-dialog/create-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Create var dialog
import { GlobalVarDialogComponent } from '../../modules/global-var-dialog/global-var-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Create file from url
import { UrlDialogComponent } from '../../modules/url-dialog/url-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Watch file dialog
import { WatchFileDialogComponent } from '../../modules/watch-file-dialog/watch-file-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Chunk copy to notebook dialog
import { ChunkCopyToDialogComponent } from '../../modules/chunk-copy-to-dialog/chunk-copy-to-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Database controls dialog
import { DuckDbControldDialogComponent } from '../../modules/duck-db-controls-dialog/duck-db-controls-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Arrow Explorer Dialog
import { ArrowExplorerComponent } from '../../modules/arrow-explorer/arrow-explorer/arrow-explorer.component';
// ─────────────────────────────────────────────────────────────────────────────
// Add project dialog
import { AddProjectDialogComponent } from '../../modules/add-project-dialog/add-project-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Edit project dialog
import { EditProjectDialogComponent } from '../../modules/edit-project-dialog/edit-project-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Manage team dialog
import { ManageTeamDialogComponent } from '../../modules/manage-team-dialog/manage-team-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Version dialog
import { VersionDialogComponent } from '../../modules/version-dialog/version-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
import { ChunkDialogComponent } from '../../modules/chunk-dialog/chunk-dialog.component';
// Chunk item description edit
import { ChunkItemInfoComponent } from '../../modules/chunk-item-description/chunk-item-description.component';
// ─────────────────────────────────────────────────────────────────────────────
// Import project dialog
import { ImportProjectDialogComponent } from '../../modules/import-project-dialog/import-project-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Alias manager
import { AliasManagerComponent } from '../../modules/arrow-alias/alias-manager/alias-manager.component';
// ─────────────────────────────────────────────────────────────────────────────
// Constants
import { CLOSE_URL_DIALOG } from '../../constants/general.constants';
// ─────────────────────────────────────────────────────────────────────────────
// Message service
import { MessageService } from '../message/message.service';
// ─────────────────────────────────────────────────────────────────────────────

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private createDialogRef!: MatDialogRef<unknown>;

  private confirmDialogRef!: MatDialogRef<unknown>;

  private createVarDialogRef!: MatDialogRef<unknown>;

  private urlDialogRef!: MatDialogRef<unknown>;

  private watchFileDialogRef!: MatDialogRef<unknown>;

  private chunkCopyToDialogRef!: MatDialogRef<unknown>;

  private databaseControlsDialogRef!: MatDialogRef<unknown>;

  private arrowAliasDialogRef!: MatDialogRef<unknown>;

  private arrowExplorerDialogRef!: MatDialogRef<unknown>;

  private addProjectDialogRef!: MatDialogRef<unknown>;

  private editProjectDialogRef!: MatDialogRef<unknown>;

  private manageTeamDialogRef!: MatDialogRef<unknown>;

  private versionDialogRef!: MatDialogRef<unknown>;

  private chunkDialogRef!: MatDialogRef<unknown>;

  private chunkItemInfoDialogRef!: MatDialogRef<unknown>;

  private importProjectDialogRef!: MatDialogRef<unknown>;
  // ─────────────────────────────────────────────────────────────────────

  // Message subscription
  private messageSubscription!: Subscription;

  constructor(
    private dialog: MatDialog,
    private messageService: MessageService
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === CLOSE_URL_DIALOG) {
          this.urlDialogRef.close(message.data);
        }
      });
  }

  createDialog(data: Dialog): Observable<any> {
    this.createDialogRef = this.dialog.open(CreateDialogComponent, {
      data,
      width: '400px',
      disableClose: true,
    });
    return this.createDialogRef.afterClosed();
  }

  confirmDialog(data: Dialog): Observable<any> {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data,
      width: '400px',
      disableClose: true,
    });
    return this.confirmDialogRef.afterClosed();
  }

  createVarDialog(data: any): Observable<any> {
    this.createVarDialogRef = this.dialog.open(GlobalVarDialogComponent, {
      data,
      width: '800px',
      disableClose: true,
    });
    return this.createVarDialogRef.afterClosed();
  }

  createFileFromUrlDialog(data: any): Observable<any> {
    this.urlDialogRef = this.dialog.open(UrlDialogComponent, {
      data,
      width: '600px',
      disableClose: true,
    });
    return this.urlDialogRef.afterClosed();
  }

  watchFileDialog(data: any): Observable<any> {
    this.watchFileDialogRef = this.dialog.open(WatchFileDialogComponent, {
      data,
      width: '1024px',
      disableClose: true,
    });
    return this.watchFileDialogRef.afterClosed();
  }

  chunkCopyToDialog(data: any): Observable<any> {
    this.chunkCopyToDialogRef = this.dialog.open(ChunkCopyToDialogComponent, {
      data,
      width: '480px',
      disableClose: true,
    });
    return this.chunkCopyToDialogRef.afterClosed();
  }

  databaseControlsDialog(data: any): Observable<any> {
    this.databaseControlsDialogRef = this.dialog.open(
      DuckDbControldDialogComponent,
      {
        data,
        width: '480px',
        disableClose: true,
      }
    );
    return this.databaseControlsDialogRef.afterClosed();
  }

  arrowAliasDialog(data: any): Observable<any> {
    this.arrowAliasDialogRef = this.dialog.open(AliasManagerComponent, {
      data,
      width: '1024px',
      disableClose: true,
    });
    return this.arrowAliasDialogRef.afterClosed();
  }

  arrowExplorerDialog(data: any): Observable<any> {
    this.arrowExplorerDialogRef = this.dialog.open(ArrowExplorerComponent, {
      data,
      width: '1400px',
      disableClose: true,
    });
    return this.arrowExplorerDialogRef.afterClosed();
  }

  addProjectDialog(data: any): Observable<any> {
    this.addProjectDialogRef = this.dialog.open(AddProjectDialogComponent, {
      data,
      width: '768px',
      disableClose: true,
    });
    return this.addProjectDialogRef.afterClosed();
  }

  editProjectDialog(data: any): Observable<any> {
    this.editProjectDialogRef = this.dialog.open(EditProjectDialogComponent, {
      data,
      width: '400px',
      disableClose: true,
    });
    return this.editProjectDialogRef.afterClosed();
  }

  manageTeamDialog(data: any): Observable<any> {
    this.manageTeamDialogRef = this.dialog.open(ManageTeamDialogComponent, {
      data,
      width: '800px',
      disableClose: true,
    });
    return this.manageTeamDialogRef.afterClosed();
  }

  versionDialog(data: any): Observable<any> {
    this.versionDialogRef = this.dialog.open(VersionDialogComponent, {
      data,
      width: '400px',
      disableClose: true,
    });
    return this.versionDialogRef.afterClosed();
  }

  chunkDialog(data: any): Observable<any> {
    this.chunkDialogRef = this.dialog.open(ChunkDialogComponent, {
      data,
      width: '1024px',
      disableClose: true,
    });
    return this.chunkDialogRef.afterClosed();
  }

  chunkItemInfoDialog(data: any): Observable<any> {
    this.chunkItemInfoDialogRef = this.dialog.open(ChunkItemInfoComponent, {
      data,
      width: '1024px',
      disableClose: true,
    });
    return this.chunkItemInfoDialogRef.afterClosed();
  }

  importProjectDialog(data: Dialog): Observable<any> {
    this.importProjectDialogRef = this.dialog.open(
      ImportProjectDialogComponent,
      {
        data,
        width: '600px',
        disableClose: true,
      }
    );
    return this.importProjectDialogRef.afterClosed();
  }
}
