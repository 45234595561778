<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>

  <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="30px">
    <mat-card-content class="w100">
      <div mat-dialog-content>
        <form [formGroup]="chunkForm">
          <mat-form-field
            appearance="outline"
            class="w100"
            *ngIf="showNotebookInput"
          >
            <mat-label>Notebook Name</mat-label>
            <input
              matInput
              formControlName="notebookName"
              placeholder="Notebook Name"
            />
            <mat-error
              *ngIf="chunkForm.get('notebookName')?.hasError('required')"
            >
              Notebook Name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w100" *ngIf="mode ==='create'">
            <mat-label>Chunk Name</mat-label>
            <input matInput formControlName="name" placeholder="Chunk Name" />
            <mat-error *ngIf="chunkForm.get('name')?.hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="chunkForm.get('name')?.hasError('nonUniqueName')">
              Name must be unique
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w100" *ngIf="mode ==='create'">
            <mat-label>Type</mat-label>
            <mat-select formControlName="chunktypeId">
              <mat-option
                *ngFor="let type of chunkTypeList"
                [value]="type.chunktypeId"
              >
                {{ type.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="chunkForm.get('chunktypeId')?.hasError('required')"
            >
              Type is required
            </mat-error>
          </mat-form-field>

          <div fxLayout="column" fxLayoutAlign="start" class="mb-3" *ngIf="mode ==='create'">
            <span>Description</span>
            <app-code-editor
              class="w100 mt-2"
              (updateContent)="updateContent($event)"
              (startEdit)="setFormTouched()"
              mode="markdown"
              [content]="chunkForm.value.description"
            ></app-code-editor>
          </div>
        </form>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="onCancel()">Cancel</button>
        <button mat-button (click)="onSave()" [disabled]="!chunkForm.valid">
          {{ showNotebookInput && mode === 'create' ? "Create" : "Save" }}
        </button>
      </div>
    </mat-card-content>
  </div>
</mat-card>
