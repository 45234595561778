import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlDialogComponent } from './url-dialog.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  imports: [
    CommonModule,
    // Materail
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    // Layout
    FlexLayoutModule,
    // Form
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [UrlDialogComponent]
})
export class UrlDialogModule { }
