import { Injectable, inject } from '@angular/core';

import {
  FSITEMS,
  API_BASE,
  DIRECTORY,
  FILE_MODULE_LIST,
  DUCKDB_IMPORT_FILES_ALLOWED,
  FILE_TYPE,
  PROJECT,
  VERSION,
  RELOAD_GLOBAL_VAR,
  REMOVE_DUCKDB_FILE,
  RELOAD_FILES,
  PROJECT_FILE,
  SYNC_DUCKDB_FILES,
} from '../../constants/general.constants';

import {
  map,
  Observable,
  Subscription,
  from,
  of,
  switchMap,
  catchError,
} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { FileItem, FileModule } from '../../interfaces/file.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import _ from 'lodash';
import { MessageService } from '../message/message.service';
import { LocalforageService } from '../localforage/localforage.service';

import { ToastrService } from 'ngx-toastr';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { DialogService } from '../dialog/dialog.service';
import { FileDataService } from '../file-data/file-data.service';
import {
  ERROR_SAVE_FILE_FROM_URL,
  ERROR_SAVE_PROJECT_FILE,
} from '../../constants/additional-methods.constants';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';
import { AppStateService } from '../app-state/app-state.service'

@Injectable({
  providedIn: 'root',
})
export class FileService {
  #appState = inject(AppStateService);
  public selectedId: number | null = null;

  public fileModuleList: FileModule[] | any = [];

  public fileList: FileItem[] = [];

  // ─────────────────────────────────────────────────────────────────────
  // Message subscription
  private messageSubscription!: Subscription;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private localForageService: LocalforageService,
    private toastr: ToastrService,
    private customDialog: DialogService,
    private fileDataService: FileDataService
  ) {
    this.getFileModuleList();

    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === RELOAD_GLOBAL_VAR) {
          this.getFileModuleList();
        }
      });
  }

  // ─────────────────────────────────────────────────────────────────────
  // Create

  public createFile(
    data: any,
    parentId: string | any,
    projectId: any = this.#appState.projectId(),
    versionId: any = this.#appState.versionId()
  ): Observable<Object> {
    return this.http.post<FileItem>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${FSITEMS}/${parentId}`,
      data
    );
  }

  public createDirectory(
    data: { name: string },
    parentId: string | any,
    versionId: any = this.#appState.versionId()
  ): Observable<Object> {
    const projectId = this.#appState.projectId();
    return this.http.post<FileItem>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${FSITEMS}/${parentId}/${DIRECTORY}`,
      data
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get Files Data

  public get(): Observable<FileItem[]> | any {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    if (!projectId || !versionId) {
      return;
    }
    return this.http
      .get<FileItem[]>(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${FSITEMS}`
      )
      .pipe(
        map((res: any) => {
          if (res === null) {
            return;
          }
          this.fileList = this.processFileList(res);
          return res;
        })
      );
  }

  public getFileFromUrl(url: string): Observable<any> {
    const headerData: any = {};
    headerData[GeneralHelpers.getInterceptorSkipHeader()] = '';
    const headers = new HttpHeaders(headerData);
    return this.http.get(url, { responseType: 'blob', headers });
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get file content by id

  public getById(
    fsitemId: string | any,
    responseType: 'text' | 'blob' | 'arraybuffer' | 'document' | 'json' = 'text'
  ): Observable<any> {
    return from(this.getFileFromIndexDBOrAPI(fsitemId, responseType)).pipe(
      switchMap((fileFromIndexDB) => {
        if (fileFromIndexDB) {
          return of(fileFromIndexDB);
        } else {
          const projectId = this.#appState.projectId();
          const versionId = this.#appState.versionId();

          if (!projectId) {
            return of(null);
          }

          return this.http.get<any>(
            `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${FSITEMS}/${fsitemId}`,
            { responseType } as any
          );
        }
      }),
      catchError((error) => error)
    );
  }

  private getFileFromIndexDBOrAPI(
    fsitemId: string | any,
    responseType: string
  ): Observable<any> {
    return from(
      this.fileDataService.checkKeyExists(fsitemId, PROJECT_FILE)
    ).pipe(
      switchMap((isInIndexDB: boolean) => {
        if (isInIndexDB) {
          return from(
            this.fileDataService.getFileFromIndexDB(fsitemId, PROJECT_FILE)
          );
        } else {
          const projectId = this.#appState.projectId();
          const versionId = this.#appState.versionId();

          if (!projectId || !versionId || !fsitemId) {
            return of(null);
          }

          return this.http.get<any>(
            `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${FSITEMS}/${fsitemId}`,
            { responseType } as any
          );
        }
      })
    );
  }

  public getFileItemById(): FileItem | any {
    if (this.fileList?.length > 0) {
      if (this.selectedId === null) {
        this.selectedId = this.fileList[0].fsitemId;
        return this.fileList[0];
      }

      for (let index = 0; index < this.fileList.length; index++) {
        const element = this.fileList[index];
        if (element.fsitemId === this.selectedId) {
          return element;
        }
      }
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update

  public update(data: any, fsitemId: string): Observable<Object> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http.put<FileItem>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${FSITEMS}/${fsitemId}`,
      data
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Delete file

  public delete(fsitemId: string | any): Observable<Object> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http
      .delete<FileItem>(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${FSITEMS}/${fsitemId}`
      )
      .pipe(
        map(async (res) => {
          await this.fileDataService.deleteFileFromIndexDB(
            fsitemId,
            PROJECT_FILE
          );
          return res;
        })
      );
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // File Module List

  public setFileModuleList(fileModuleList: FileModule[]): any {
    if (Array.isArray(fileModuleList)) {
      return this.localForageService
        .setItem(
          `${FILE_MODULE_LIST}_${this.#appState.projectId()}`,
          GeneralHelpers.jsonStringify(fileModuleList)
        )
        .then((data: any) => {
          this.fileModuleList = GeneralHelpers.jsonParse(data);

          return this.fileModuleList;
        })
        .catch((error) => {});
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Get File Module List

  public getFileModuleList(extensions?: string[]): FileModule[] | any {
    let result: any = [];
    return this.localForageService
      .getItem(`${FILE_MODULE_LIST}_${this.#appState.projectId()}`)
      .then((fileModuleList: any) => {
        if (fileModuleList !== null) {
          fileModuleList = GeneralHelpers.jsonParse(fileModuleList);
          this.fileModuleList = fileModuleList;
          result = fileModuleList;
        } else {
          result = this.fileModuleList;
        }
        return extensions?.length
          ? this.getFilteredModuleList(result, extensions)
          : result;
      });
  }

  private getFilteredModuleList(
    fileList: FileModule[],
    extensions: string[]
  ): FileModule[] {
    let filteredModules: FileModule[] = [];
    if (fileList?.length === 0 || extensions?.length === 0) {
      return filteredModules;
    }

    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];
      if (extensions.includes(element?.extension)) {
        filteredModules.push(element);
      }
    }

    return filteredModules;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Local Storage Modules;

  // ─────────────────────────────────────────────────────────────────────
  // Delete

  public deleteInModuleList(id: string): FileModule[] {
    const index = this.getFileModuleIndex(id);
    if (index > -1) {
      const removed: FileModule[] = this.fileModuleList.splice(index, 1);
      if (
        removed?.length > 0 &&
        DUCKDB_IMPORT_FILES_ALLOWED.includes(removed[0].extension as any)
      ) {
        this.messageService.sendMessage(REMOVE_DUCKDB_FILE, removed[0].name);
      }
      if (
        removed?.length > 0 &&
        removed[0] !== undefined &&
        removed[0].name !== undefined
      ) {
        this.removeLocalforageItem(removed[0].name);
      }
      this.setFileModuleList(this.fileModuleList);
    }
    return this.fileModuleList;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Add to file module list
  // Add or Update

  public async addToModuleList(node: FileItem | any, data?: string | any) {
    let list = await this.getFileModuleList();
    const projectId = this.#appState.projectId();
    const index = GeneralHelpers.findIndexInArrayByProp(
      list,
      'fsitemId',
      node.fsitemId
    );
    const extension = GeneralHelpers.fileExtensionFromString(node.name);
    this.localForageService
      .setItem(`${projectId}_${node.name}`, data)
      .then(() => {
        const fileModuleItem = {
          name: node.name,
          fsitemId: node.fsitemId,
          notebookId: node.notebookId,
          isGlobal: node.isGlobal === undefined ? true : node.isGlobal,
          extension: extension,
        } as FileModule;
        if (index > -1) {
          list[index] = fileModuleItem;
        } else {
          list.push(fileModuleItem);
        }
        if (
          DUCKDB_IMPORT_FILES_ALLOWED.includes(fileModuleItem.extension as any)
        ) {
          this.messageService.sendMessage(SYNC_DUCKDB_FILES);
        }
        this.setFileModuleList(list);
      })
      .catch((e) => {});
  }

  public isInModuleList(node: FileItem): boolean {
    let list = this.fileModuleList;
    const index = GeneralHelpers.findIndexInArrayByProp(
      list,
      'fsitemId',
      node.fsitemId
    );
    return index > -1;
  }

  public async getFileModuleByName(name: string) {
    const list = await this.getFileModuleList();
    const projectId = this.#appState.projectId();
    const fileModule: FileModule = GeneralHelpers.findElementInArrayByProp(
      list,
      'name',
      `${projectId}_${name}`
    );
    return fileModule;
  }

  private getFileModuleIndex(id: string): number {
    const index = this.fileModuleList.findIndex(
      (item: FileModule) => item.fsitemId === id
    );
    return index === -1 ? 0 : index;
  }

  private getFileModuleIndexbyName(name: string): number {
    const index = this.fileModuleList.findIndex(
      (item: FileModule) => item.name === name
    );
    return index === -1 ? 0 : index;
  }

  public clean() {
    this.fileModuleList = [];
  }

  public updateModuleGlobal(name: string, isGlobal: boolean) {
    const index = this.getFileModuleIndexbyName(name);
    this.fileModuleList[index].isGlobal = isGlobal;
    this.setFileModuleList(this.fileModuleList);
  }

  private processFileList(data: FileItem[]): FileItem[] {
    for (let index = 0; index < data.length; index++) {
      data[index].isInModuleList = this.isInModuleList(data[index]);
    }
    return data;
  }

  public async getParentId() {
    // Check if fileList is empty and get the list if needed
    if (!this.fileList || this.fileList.length === 0) {
      await this.populateFileList();
    }

    const fileItem = this.getFileItemById();
    const parentId = this.findFileItemParent();
    if (fileItem?.isDirectory) {
      return this.selectedId;
    }
    if (parentId) {
      return parentId;
    }
    if (this.fileList?.length > 0) {
      return this.fileList[0].fsitemId;
    }
    return null;
  }

  private async populateFileList(): Promise<void> {
    try {
      const files = await this.get().toPromise();
      this.fileList = files || [];
    } catch (error) {
      console.error('Error fetching file list', error);
    }
  }

  public findFileItemParent(id: any = this.selectedId): number | null {
    if (!this.fileList || this.fileList.length === 0) {
      return null;
    }

    const mainParentId = this.fileList.find(
      (fileItem) => fileItem.name === 'files'
    )?.fsitemId;

    if (this.fileList.length > 0 && id !== null) {
      for (let index = 0; index < this.fileList.length; index++) {
        const element: FileItem = this.fileList[index];

        if (
          Array.isArray(element.childIds) &&
          element.childIds.some((childId: any) => childId === id)
        ) {
          return element.fsitemId;
        }
      }

      return mainParentId ? mainParentId : null;
    }

    return null;
  }

  public async saveToFile(
    fileContent: any,
    chunkData: Chunk,
    fileExtension: string,
    fileName: string
  ) {
    const parentId = await this.getParentId();
    const formData = this.fileDataService.prepareFileData(
      fileName,
      fileExtension,
      fileContent,
      chunkData
    );
    this.createFile(formData, parentId).subscribe({
      next: (data: FileItem | any) => {
        this.toastr.success(`Item ${name} exported`);
        this.messageService.sendMessage(RELOAD_FILES);
      },
      error: () => {},
    });
  }

  public getLocalforageItem(key: string) {
    return this.localForageService.getItem(key);
  }

  public setLocalforageItem(key: string, value: any) {
    return this.localForageService.setItem(key, value);
  }

  public removeLocalforageItem(key: string) {
    return this.localForageService.removeItem(key);
  }

  // ─────────────────────────────────────────────────────────────────────
  // This method is used in quick.js and pyodide
  // ─────────────────────────────────────────────────────────────────────
  public async saveFile(data: any[], chunkContext: ExecutionContext) {
    const fileNameWithExtension = data[0];
    const fileContent = data[1];
    if (!data || data.length < 2 || typeof data[0] !== 'string' || !data[1]) {
      chunkContext.addMessage(ERROR_SAVE_PROJECT_FILE, 'danger');
      return;
    }
    const fileName = GeneralHelpers.fileNameFromString(fileNameWithExtension);
    const fileExtension = GeneralHelpers.fileExtensionFromString(
      fileNameWithExtension
    );
    if (Array.isArray(this.fileList) && this.fileList.length === 0) {
      this.fileList = await this.get().toPromise();
    }
    const presentedFileIndex = GeneralHelpers.findIndexInArrayByProp(
      this.fileList,
      'name',
      `${fileName}.${fileExtension}`
    );

    if (presentedFileIndex > -1) {
      const fsItem: FileItem | any = this.fileList.find(
        (item: FileItem) => item.name === fileNameWithExtension
      );

      if (fsItem) {
        const parentId = await this.getParentId();
        const formData = this.fileDataService.prepareFileData(
          fileName,
          fileExtension,
          fileContent
        );
        this.delete(fsItem.fsitemId).subscribe({
          next: () => {
            this.createFile(formData, parentId).subscribe({
              next: (data: FileItem | any) => {
                this.toastr.success(`Item ${name} exported`);
                this.messageService.sendMessage(RELOAD_FILES);
              },
              error: () => {},
            });
          },
          error: () => {},
        });
      }
    } else {
      this.saveToFile(fileContent, {} as any, fileExtension, fileName);
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // This method is used in quick.js and pyodide
  // ─────────────────────────────────────────────────────────────────────
  public saveFileFromUrl(data: any[], chunkContext: ExecutionContext) {
    const fileUrl = data[0];

    if (!data || data.length === 0 || !GeneralHelpers.isValidUrl(data[0])) {
      chunkContext.addMessage(ERROR_SAVE_FILE_FROM_URL, 'danger');
      return;
    }

    const nameWithExtension = GeneralHelpers.getNameWithExtension(fileUrl);
    window
      .fetch(fileUrl)
      .then((res: any) => {
        return res.blob();
      })
      .then(async (data: any) => {
        const fileName = GeneralHelpers.fileNameFromString(nameWithExtension);
        const fileExtension =
          GeneralHelpers.fileExtensionFromString(nameWithExtension);
        const fullFileName = `${fileName}.${fileExtension}`;
        const file = new File([data], fullFileName, { type: fileExtension });

        const parentId = await this.getParentId();

        // Check if file already exists
        const presentedFileIndex = GeneralHelpers.findIndexInArrayByProp(
          this.fileList,
          'name',
          fullFileName
        );

        if (presentedFileIndex > -1) {
          return;
        }

        const formData: FormData = new FormData();
        formData.append('payload', file);

        this.createFile(formData, parentId).subscribe({
          next: (data: FileItem | any) => {
            this.toastr.success(`Item ${name} exported`);
            this.messageService.sendMessage(RELOAD_FILES);
          },
          error: () => {},
        });
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  public async getFile(args: any[]) {
    const fileName = args[0];
    const fileExtension = GeneralHelpers.fileExtensionFromString(fileName);
    const responseType = args[1]
      ? args[1]
      : this.fileDataService.getResponseType(fileExtension);
    const fileList: any = await this.get().toPromise();

    if (!fileList) {
      return null;
    }

    const fileFromList = GeneralHelpers.findElementInArrayByProp(
      fileList,
      'name',
      fileName
    );

    if (fileFromList === undefined) {
      // try to get from localforage
      const fileModule = await this.getFileModuleByName(fileName);

      if (fileModule) {
        const file = await this.getLocalforageItem(fileName);
        return await this.fileDataService.getFileCallback(file, fileExtension);
      } else {
        return null;
      }
    }

    let file = await this.getById(
      fileFromList.fsitemId,
      responseType
    ).toPromise();

    file = await this.fileDataService.getFileCallback(
      file,
      fileFromList,
      responseType
    );

    return file;
  }

  public openWatchFileDialog(node: FileItem) {
    node.fileType = GeneralHelpers.fileTypeFromFsItem(node);
    const canSave: boolean = [
      FILE_TYPE.json,
      FILE_TYPE.javascript,
      FILE_TYPE.python,
    ].some((fileType: string) => fileType === node.fileType);
    const dialogData: Dialog = {
      title: 'View / Edit file',
      confirmCaption: canSave ? 'Save' : '',
      cancelCaption: 'Cancel',
      hasClose: true,
      dialogData: node,
    };
    return this.customDialog.watchFileDialog(dialogData);
  }
}
