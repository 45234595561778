<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>

  <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="30px">
    <mat-card-content class="w100">
      <form
        [formGroup]="projectForm"
        fxLayout="column"
        fxLayoutAlign="space-around start"
        class="mt-2"
        novalidate
        (ngSubmit)="submitForm(projectForm, projectForm.valid, $event)"
      >
        <mat-form-field appearance="outline" class="w100">
          <mat-label>Project Name</mat-label>
          <input
            matInput
            placeholder="Enter project name"
            formControlName="name"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="w100">
          <mat-label>Project Description</mat-label>
          <input
            matInput
            placeholder="Enter project description"
            formControlName="description"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="w100">
          <mat-label>First Notebook Name</mat-label>
          <input
            matInput
            placeholder="Enter first notebook name"
            formControlName="notebookName"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="w100">
          <mat-label>First Block Name</mat-label>
          <input
            matInput
            placeholder="Enter first block name"
            formControlName="chunkName"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="w100">
          <mat-label>First Block Type</mat-label>
          <mat-select formControlName="chunkTypeId" required>
            <mat-option
              *ngFor="let type of chunkTypeList"
              [value]="type.chunktypeId"
            >
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="primary" type="submit">
          Create Project
        </button>
      </form>
    </mat-card-content>
  </div>
</mat-card>
