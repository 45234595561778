import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionDialogComponent } from './version-dialog.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
// Form support
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Mask
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    // Form support
    FormsModule,
    ReactiveFormsModule,
    // Layout
    FlexLayoutModule,
    // Mask
    NgxMaskModule.forRoot(),
  ],
  declarations: [VersionDialogComponent],
  exports: [VersionDialogComponent]
})
export class VersionDialogModule { }
