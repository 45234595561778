import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../services/project/project.service';
import { ChunkTypeService } from '../../services/chunk-type/chunk-type.service';
import { ProjectCreate } from '../../interfaces/project.interface';
import { AppStateService } from '../../services/app-state/app-state.service';
import { debounce } from 'lodash';
import { ToastrService } from 'ngx-toastr';

type ProjectFormGroup<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K] | null>;
}>;

@Component({
  selector: 'app-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss'],
})
export class AddProjectDialogComponent implements OnInit {
  public chunkTypeList: any[] = [];

  projectForm = this._formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    notebookName: ['', Validators.required],
    chunkName: ['', Validators.required],
    chunkTypeId: [null, Validators.required],
  }) as ProjectFormGroup<ProjectCreate>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private chunkTypeService: ChunkTypeService,
    private dialogRef: MatDialogRef<AddProjectDialogComponent>,
    private appStateService: AppStateService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.chunkTypeService.chunkTypes$.subscribe((types: any) => {
      this.chunkTypeList = types;
    });
  }

  public submitForm = debounce((formValue: ProjectFormGroup<ProjectCreate>, isValid: boolean, $event: Event) => {
    $event.preventDefault();

    if (!isValid) {
      return;
    }

    const {
      value: { name, description, notebookName, chunkName, chunkTypeId },
    } = formValue;

    const projectData: ProjectCreate = {
      name: String(name).trim(),
      description: String(description).trim(),
      notebookName: String(notebookName).trim(),
      chunkName: String(chunkName).trim(),
      chunkTypeId: Number(chunkTypeId),
    };

    this.projectService.create(projectData).subscribe(async (res: any) => {
      try {
        await this.appStateService.loadProjects();

        if (res.projectId && res.currentVersionId) {
          this.appStateService.navigateToProject(res.projectId, res.currentVersionId);
        }
      } catch (error) {
        this.toastr.error('Failed to create project');
        console.error(error);
      } finally {
        this.dialogRef.close();
      }
    });
  }, 500);
}
