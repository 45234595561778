import { NgModule } from '@angular/core';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { environment } from '../../environments/environment';

const { auth, apiUrl } = environment;
const { domain, clientId, cacheLocation, useRefreshTokens, redirectUri } =
  auth.auth0;

@NgModule({
  imports: [
    AuthModule.forRoot({
      domain,
      clientId,
      cacheLocation,
      useRefreshTokens,
      authorizationParams: {
        redirect_uri: redirectUri,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `https://${domain}/api/*`,
            tokenOptions: {
              authorizationParams: {
                audience: `https://${domain}/api/`,
                scope: 'read:current_user',
              },
            },
          },
          { uri: `${apiUrl}*` },
        ],
      },
    }),
  ],
  providers: [AuthService],
  exports: [AuthModule],
})
export class Auth0Module {}
