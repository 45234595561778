import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageTeamDialogComponent } from './manage-team-dialog.component';
// Material
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
// Custom modules
import { ManageTeamModule } from '../manage-team/manage-team.module';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    // Cutom modules
    ManageTeamModule,
  ],
  declarations: [ManageTeamDialogComponent],
  exports: [ManageTeamDialogComponent]
})
export class ManageTeamDialogModule { }
