import { Table, tableFromIPC } from 'apache-arrow';
import { GeneralHelpers } from './general.helper';

export class FileHelpers {
  static detectFileTypeFromByteString(byteString: string): string {
    const byteArray = FileHelpers.byteStringToArray(byteString);
    const uint8Array = new Uint8Array(byteArray);

    // Check for JSON
    if (FileHelpers.isJSON(byteString)) {
      return 'JSON';
    }

    // Check for Arrow
    if (FileHelpers.isArrowFile(uint8Array)) {
      return 'Arrow';
    }

    // Check for CSV
    if (GeneralHelpers.isCSV(FileHelpers.byteArrayToString(byteArray))) {
      return 'CSV';
    }

    // Check for Parquet
    if (GeneralHelpers.isParquetFile(uint8Array)) {
      return 'Parquet';
    }

    return 'Unknown';
  }

  private static isJSON(byteString: string): boolean {
    try {
      JSON.parse(
        FileHelpers.byteArrayToString(FileHelpers.byteStringToArray(byteString))
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  static isArrowFile(input: Uint8Array | string | Table): boolean {
    // Case 1: Input is already a Table object from apache-arrow
    if (input instanceof Table) {
      return true;
    }

    let uint8Array: Uint8Array;

    // Case 2: Input is a string (byte string)
    if (typeof input === 'string') {
      uint8Array = new Uint8Array(
        input.split(',').map((byte) => parseInt(byte.trim(), 10))
      );
    }
    // Case 3: Input is already a Uint8Array
    else if (input instanceof Uint8Array) {
      uint8Array = input;
    } else {
      return false; // Invalid input type
    }

    // Use tableFromIPC to validate Arrow data
    try {
      const table = tableFromIPC(uint8Array);
      return true;
    } catch (error) {
      return false;
    }
  }

  private static byteStringToArray(byteString: string): number[] {
    return byteString.split(', ').map(Number);
  }

  private static byteArrayToString(byteArray: number[]): string {
    return String.fromCharCode.apply(null, byteArray);
  }
}
