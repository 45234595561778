<app-spinner></app-spinner>
<app-header></app-header>
<div *ngIf="networkStatus">
  <main>
    <router-outlet *ngIf="true"></router-outlet>
  </main>
</div>
<div class="offline-page-wrapper" *ngIf="!networkStatus">
  <div class="offline-page" fxLayout="column" fxLayoutAlign="center center">
    <h1>Sorry, but you are offline</h1>
    <a href="#" [routerLink]="['/projects']">Go to the main page</a>
  </div>
</div>
<app-footer></app-footer>
