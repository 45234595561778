import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WatchFileDialogComponent } from './watch-file-dialog.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Code editor
import { CodeEditorModule } from '../code-editor/code-editor.module';
// ─────────────────────────────────────────────────────────────────────────────
// Pipes
import { PipesModule } from '../../pipes/pipes.module';
// ─────────────────────────────────────────────────────────────────────────────
// Table module
import { TableModule } from '../table/table.module';
// ─────────────────────────────────────────────────────────────────────────────

@NgModule({
  imports: [
    CommonModule,
    // Materail
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    // Layout
    FlexLayoutModule,
    // Code editor
    CodeEditorModule,
    // Pipes
    PipesModule,
    // Table
    TableModule,
  ],
  declarations: [WatchFileDialogComponent],
})
export class WatchFileDialogModule {}
