<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      [mat-dialog-close]="null"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content class="my-4">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <div>
        <h3 class="mb-0">Selected chunks:</h3>
        <ul>
          <li *ngFor="let chunk of chunks">{{ getChunkName(chunk) }}</li>
        </ul>
      </div>
      <mat-form-field appearance="outline" class="no-wrapper position-relative">
        <mat-label>Select notebook</mat-label>
        <mat-select (selectionChange)="selectNotebook($event)">
          <mat-option *ngFor="let notebook of notebooks" [value]="notebook">
            {{ notebook.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="{
        chunks,
        notebook: selectedNotebook
      }"
      *ngIf="data?.confirmCaption"
      class="mr-2"
    >
      {{ data.confirmCaption }}
    </button>
    <button
      mat-stroked-button
      color="warn"
      [mat-dialog-close]="null"
      *ngIf="data?.cancelCaption"
    >
      {{ data.cancelCaption }}
    </button>
  </mat-card-actions>
</mat-card>
