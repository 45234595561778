<div class="row m-2">
  <div class="col" style="max-width: 200px; height: 300px;">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>
          <span class="h5">Tables</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="colblock">
        <table-list #tableList [dataSource]="tables$" [options]="{showHeader: false}" [columns]="tableListColumns" (selectionChanged)="tableSelected($event)"></table-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col" style="max-width: 400px;">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>
          <span class="h5">Table Info</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row align-items-baseline">
          <div class="col p-2 h6">
            name:
          </div>
          <div class="col p-2">
            {{ selectedTable()?.name || '-' }}
        </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col p-2 h6">
            table_id:
          </div>
          <div class="col p-2">
          {{ selectedTable()?.table_id }}
        </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col p-2 h6">
            bucket_name:
          </div>
          <div class="col p-2">
            {{ selectedTable()?.bucket_name }}
        </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col p-2 h6">
            bucket_path:
          </div>
          <div class="col p-2">
            {{ selectedTable()?.bucket_path }}
        </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col p-2 h6">
            src_bucket:
          </div>
          <div class="col p-2">
            {{ selectedTable()?.src_bucket }}
        </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col p-2 h6">
            src_path:
          </div>
          <div class="col p-2">
            {{ selectedTable()?.src_path }}
        </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="row">
      <div class="col my-2">
        <button (click)="loadSchema()" class="btn btn-sm btn-primary me-2" [disabled]="selectedTable() === undefined">Load Schema</button>
        <button (click)="deleteTable()" class="btn btn-sm btn-primary me-2" [disabled]="selectedTable() === undefined">Delete Table</button>
      </div>
    </div>
</div>

  <div class="col" style="max-width: 400px;">
    <div class="row">
      <div class="col">
        <mat-card style="overflow: auto;" class="mat-elevation-z4">
          <mat-card-header>
            <mat-card-title>
              <span class="h5">Schema</span>
            </mat-card-title>
            <button class="small-icon-button ml-2" tooltip="Copy Column Names" (click)="copyColumnsToClipboard()" tabindex="-1" [disabled]="selectedSchema().length < 1"><mat-icon>content_copy</mat-icon></button>
          </mat-card-header>
          <mat-card-content>
              <div style="max-height: 300px;">
            <table-list class="overflow-auto" [dataSource]="schemas$" [columns]="schemaListColumns"></table-list>
          </div>
        </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
