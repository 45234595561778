import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, map, mergeMap, shareReplay } from 'rxjs'
import { RawChunkType } from '../../interfaces/chunk/chunk.interface'
import { API_BASE, CHUNKTYPES } from '../../constants/general.constants'
import { ChunkType } from '../../logic/chunk-type'

@Injectable({
  providedIn: 'root'
})
export class ChunkTypeService {
  private _rawData$ = new BehaviorSubject<void>(undefined);
  apiRequest$ = this.http.get<RawChunkType[]>(`${API_BASE}${CHUNKTYPES}`).pipe(
    map((data: RawChunkType[]) => {
      return data?.map((rawChunkType: RawChunkType) => {
        return new ChunkType(rawChunkType);
      })
    })
  );

  public chunkTypes$ = this._rawData$.pipe(
    mergeMap(() => this.apiRequest$),
    shareReplay(1)
  );

  constructor(private http: HttpClient) { }

  public async getChunkType(chunkTypeId: number): Promise<ChunkType|undefined> {
    return firstValueFrom(this.chunkTypes$).then((chunkTypes: ChunkType[]) => {
      return chunkTypes.find((chunkType: ChunkType) => chunkType.chunktypeId === chunkTypeId);
    });
  }

  public async getChunkTypeOrDefault(chunkTypeId: number): Promise<ChunkType> {
    return firstValueFrom(this.chunkTypes$).then((chunkTypes: ChunkType[]) => {
      const c = chunkTypes.find((chunkType: ChunkType) => chunkType.chunktypeId === chunkTypeId);
      return c || chunkTypes[0];
    });
  }

  public async getChunkTypeName(chunktypeId: number): Promise<string> {
    return firstValueFrom(this.chunkTypes$).then((chunkTypes: ChunkType[]) => {
      const chunkType = chunkTypes.find((chunkType: ChunkType) => chunkType.chunktypeId === chunktypeId);
      return chunkType?.name || 'Unknown type';
    });
  }

  updateData() {
    this._rawData$.next(undefined);
  }
}
