<div class="d-flex justify-content-between">
  <h4 class="p-2">Arrow Explorer</h4>
  <div class="p-2 align-items-center">
    @if (busySignal()) {
      <mat-spinner diameter="20" style="display: inline-block; margin-right: 4px;"></mat-spinner>
      <span>{{ busyMessageSignal() }}</span>
    }
  </div>
  <div class="p-2">
    <button (click)="close()" type="button" class="btn-close" tabindex="-1" aria-label="Close"></button>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-10">
      <mat-tab-group preserveContent #tabGroup mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Data Sets">
          @if (arrowClient()) {
            <dataset-viewer [arrowClient]="arrowClient()!" [context]="this" class="top-container">
            </dataset-viewer>
          }
        </mat-tab>
        <mat-tab label="Tables">
          @if (arrowClient()) {
            <table-explorer [arrowClient]="arrowClient()!" [context]="this" class="top-container">
            </table-explorer>
          }
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="col-2">
      <div class="row align-items-center justify-content-between">
        <div class="col">
          <h5 class="p-2">Messages</h5>
        </div>
        <div class="col">
          <button (click)="clearMessages()" tabindex="-1" matTooltip="Clear all Messages" class="icon-button" [disabled]="alerts.length < 1"><mat-icon>delete_sweep</mat-icon></button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          @for (alert of alerts; let i = $index; track i) {
            <alert
              [type]="alert.kind"
              [dismissible]="true"
              (onClosed)="removeMessage(i)"
              role="alert"
            >
              {{ alert.message }}
            </alert>
            }
        </div>
      </div>
    </div>
  </div>
</div>
