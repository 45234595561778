import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeEditorComponent } from './code-editor.component';

// ─────────────────────────────────────────────────────────────────────────────
// Forms support
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// ─────────────────────────────────────────────────────────────────────────────
// Material
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
// Layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// Codemirror
import { CodemirrorModule } from '../codemirror/codemirror.module'

@NgModule({
  imports: [
    CommonModule,
    // Code mirror
    CodemirrorModule,
    // Forms support
    ReactiveFormsModule,
    FormsModule,
    // Material
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    // Layout
    FlexLayoutModule,
  ],
  declarations: [CodeEditorComponent],
  exports: [CodeEditorComponent]
})
export class CodeEditorModule { }
