import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableColumn } from '../../interfaces/table-column.interface';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DUCKDB_ACTIONS } from './toolbars/duckdb.toolbar';
import { FileService } from '../../services/file/file.service';
import { DialogService } from '../../services/dialog/dialog.service';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { saveAs } from 'file-saver';
import { Papa } from 'ngx-papaparse';
import { GeneralHelpers } from '../../helpers/general.helper';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  public tableDataSource = new MatTableDataSource<any>([]);
  public displayedColumns!: string[];

  @ViewChild(MatPaginator, { static: false }) matPaginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;

  @Input() isPageable = false;
  @Input() isFilterable = false;
  @Input() isControlPanel = false;
  @Input() controlPanelType = '';
  @Input() tableColumns: TableColumn[] = [];
  @Input() rowActionIcon!: string;
  @Input() paginationSizes: number[] = [5, 10, 15];
  @Input() defaultPageSize = this.paginationSizes[1];

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClickAction: EventEmitter<any> = new EventEmitter<any>();

  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  public controlList: any = [];

  constructor(
    private fileService: FileService,
    private dialogService: DialogService,
    private papa: Papa
  ) {}

  ngOnInit(): void {
    const columnNames = this.tableColumns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    if (this.rowActionIcon) {
      this.displayedColumns = [this.rowActionIcon, ...columnNames];
    } else {
      this.displayedColumns = columnNames;
    }
    this.setToolbarVisibility();
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }

  ngOnDestroy(): void {}

  private setTableDataSource(data: any) {
    this.tableDataSource.data = data;
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort) {
    sortParameters.active = (this.tableColumns as any).find(
      (column: any) => column.name === sortParameters.active
    ).dataKey;
    this.sort.emit(sortParameters);
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  emitRowClickAction(data: any) {
    this.rowClickAction.emit(data);
  }

  private setToolbarVisibility() {
    if (!this.isControlPanel) {
      return;
    }
    switch (this.controlPanelType) {
      case 'duckdb':
        this.controlList = DUCKDB_ACTIONS;
        break;
      default:
        break;
    }
  }

  public processToolbarCommand(name: string) {
    switch (name) {
      case 'export_to_json':
        this.openCreateFileDialog(this.tableDataSource, 'json');
        break;
      case 'export_to_csv':
        this.openCreateFileDialog(this.tableDataSource, 'csv');
        break;
      default:
        break;
    }
  }

  private openCreateFileDialog(data: any, extension: string) {
    this.dialogService
      .createDialog({
        title: 'Create new file',
        label: 'Enter file name',
        placeholder: 'file name',
        confirmCaption: 'Create',
        cancelCaption: 'Cancel',
        hasClose: true,
      } as Dialog)
      .subscribe((data: any) => {
        if (data.model && data.model !== '') {
          const fileName = data.model;
          switch (extension) {
            case 'csv':
              const jsonToCSV = this.papa.unparse(this.tableDataSource.data);
              const csvBlob = new Blob([jsonToCSV], {
                type: 'text/csv',
              });
              saveAs(csvBlob, fileName + '.csv');
              break;

            case 'json':
              const jsonBlob = new Blob(
                [GeneralHelpers.jsonStringify(this.tableDataSource.data)],
                {
                  type: 'application/json',
                }
              );
              saveAs(jsonBlob, fileName + '.json');
              break;
            default:
              break;
          }
        }
      });
  }

  isImageUrlOrBase64(data: string): boolean {
    const imageUrlPattern =
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.(jpg|jpeg|png|gif)$/i;
    const base64ImagePattern =
      /^data:image\/[a-zA-Z+.-]+;base64,(?:[a-zA-Z0-9+/]{4})*(?:[a-zA-Z0-9+/]{2}==|[a-zA-Z0-9+/]{3}=|[a-zA-Z0-9+/]{4})$/i;

    return imageUrlPattern.test(data) || base64ImagePattern.test(data);
  }
}
