import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import  { TableListComponent } from './table-list.component';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon'

@NgModule({
  declarations: [TableListComponent, ],
  exports: [TableListComponent, ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    DragDropModule,
  ]
})
export class TableListModule { }
