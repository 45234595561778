
export class PutStreamMachine {
  #chunkSize = 48 * 1024; // 128 KB per message
  #offset = 0;
  #putId: number|undefined = undefined;
  #data: Buffer
  #path: string = 'new'
  errorHandler: (reason: any) => void = () => {}
  resolveHandler: (putId: number) => void = () => {}

  public constructor(
    readonly transId: number,
    destPath: string|undefined,
    private app_metadata: string|undefined,
    data: Buffer|string,
    private webSocket: WebSocket,
  ) {
    if (typeof data === 'string') {
      const numberArray = data.split(',').map(Number);
      let arrowBuffer = new Uint8Array(numberArray);
      this.#data = Buffer.from(arrowBuffer);
      this.#path = destPath || 'new'
    } else {
      this.#data = data;
    }
  }

  /// client calls this to send the initial put with metadata
  start(put_id: number|undefined = undefined) {
    const me = this
    this.#putId = put_id
    const json = {
        message: 'PutStream',
        trans_id: me.transId,
        path: this.#path,
        put_type: 'Stream',
        app_metadata: me.app_metadata,
        put_id
    }
    me.webSocket.send(JSON.stringify(json))
  }

  /// client calls when a response related to the put is received
  handleResponse(response: any) {
    switch (response.message) {
      case 'StartBinary':
        this.#sendNextChunk()
        break
      case 'Continue':
        this.#sendNextChunk()
        break
      case 'PutComplete':
        if (this.#putId === undefined && response.app_metadata) {
          const meta = JSON.parse(response.app_metadata)
          if (meta && meta.end_command && meta.end_command.put_id) {
            this.#putId = meta.end_command.put_id as number
          }
        }
        console.log('Data transmission completed successfully.', this.#putId)
        this.resolveHandler(this.#putId||-1) //should always be defined
        break
      case 'Error':
        this.errorHandler(response.description)
        break
    }
  }

  #sendNextChunk() {
    const chunk_len = this.#data.length - this.#offset
    const size = Math.min(this.#chunkSize, chunk_len)
    let chunk = this.#data.slice(this.#offset, this.#offset + size)
    console.log(`Sending chunk of size ${chunk.length}`)
    let header = this.#transAsBuffer()
    let combined = Buffer.concat([header, chunk])
    this.webSocket.send(combined)
    this.#offset += this.#chunkSize
  }

  #transAsBuffer() {
    return Buffer.from(Number(this.transId).toString().padStart(20, '0'))
  }

}

