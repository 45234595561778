<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <ng-container [ngSwitch]="importStep">
    <!-- Step 1: File Upload -->
    <ng-container *ngSwitchCase="1">
      <form [formGroup]="fileUploadForm" (ngSubmit)="onFileSelected()">
        <file-upload formControlName="files" name="files"></file-upload>
        <button mat-raised-button color="primary" type="submit" [disabled]="!fileUploadForm.valid">
          Upload and Analyze
        </button>
      </form>
    </ng-container>

    <!-- Step 2: Version Selection (if multiple versions available) -->
    <ng-container *ngSwitchCase="2">
      <h3>Select Version to Import</h3>
      <mat-radio-group [(ngModel)]="selectedVersionId">
        <mat-radio-button *ngFor="let version of versions" [value]="version.versionId">
          Version {{ version.majorVersion }}.{{ version.minorVersion }}
        </mat-radio-button>
      </mat-radio-group>
      <button mat-raised-button color="primary" (click)="onVersionSelected(selectedVersionId)">Continue</button>
    </ng-container>

    <!-- Step 3: Connection Secrets -->
    <ng-container *ngSwitchCase="3">
      <h3>Enter Connection Secrets</h3>
      <form [formGroup]="connectionsForm" (ngSubmit)="onConnectionsSubmitted()">
        <ng-container *ngFor="let conn of connections">
          <mat-form-field>
            <mat-label>{{ conn.name }}</mat-label>
            <input matInput [formControlName]="conn.name" type="password">
          </mat-form-field>
        </ng-container>
        <button mat-raised-button color="primary" type="submit">Continue</button>
      </form>
    </ng-container>

    <!-- Step 4: Project Name Confirmation -->
    <ng-container *ngSwitchCase="4">
      <form [formGroup]="projectNameForm" (ngSubmit)="onProjectNameSubmitted()">
        <mat-form-field>
          <mat-label>Project Name</mat-label>
          <input matInput formControlName="projectName">
          <mat-error *ngIf="projectNameForm.get('projectName')?.hasError('notUnique')">
            A project with this name already exists. Please choose a different name.
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit">Import Project</button>
      </form>
    </ng-container>

    <!-- Step 5: Import Progress -->
    <ng-container *ngSwitchCase="5">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p>Importing project, please wait...</p>
    </ng-container>

    <!-- Step 6: Import Results -->
    <ng-container *ngSwitchCase="6">
      <h3>Import Completed</h3>
      <ng-container *ngIf="warnings.length > 0">
        <h4>Warnings:</h4>
        <ul>
          <li *ngFor="let warning of warnings">{{ warning }}</li>
        </ul>
        <button mat-raised-button color="primary" (click)="copyWarnings()">Copy Warnings</button>
      </ng-container>
      <ng-container *ngIf="warnings.length === 0">
        <p>Import completed successfully!</p>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="closeDialog()" *ngIf="importStep !== 5">Cancel</button>
  <button mat-raised-button color="primary" (click)="closeDialog()" *ngIf="importStep === 6">Close</button>
</mat-dialog-actions>