import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from '../../interfaces/create-dialog.interface';

@Component({
  selector: 'app-manage-team-dialog',
  templateUrl: './manage-team-dialog.component.html',
  styleUrls: ['./manage-team-dialog.component.scss']
})
export class ManageTeamDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Dialog) { }

  ngOnInit() {
  }

}
