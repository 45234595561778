<mat-card>
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <button
      mat-icon-button
      (click)="toggleFullscreen()"
      class="small fullscreen-control"
    >
      @if (isFullscreen) {
        <mat-icon>close_fullscreen</mat-icon>
      } @else {
        <mat-icon>fullscreen</mat-icon>
      }
    </button>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>

  <form
    name="chunkItemInfoForm"
    novalidate
    [formGroup]="chunkItemInfoForm"
    (ngSubmit)="
      submitChunkInfoForm(
        chunkItemInfoForm.getRawValue(),
        chunkItemInfoForm.valid,
        $event
      )
    "
  >
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="start" class="mb-3">
        <app-code-editor
          class="w100 mt-2"
          (updateContent)="updateContent($event)"
          (startEdit)="setFormTouched()"
          mode="markdown"
          [content]="chunkData.description || ''"
        ></app-code-editor>
      </div>
    </mat-card-content>

    <mat-card-actions [align]="'end'">
      <button
        mat-stroked-button
        color="primary"
        type="submit"
        *ngIf="data?.updateCaption"
        class="mr-2"
      >
        {{ data.updateCaption }}
      </button>
      <button
        mat-stroked-button
        color="warn"
        [mat-dialog-close]="null"
        *ngIf="data?.cancelCaption"
      >
        {{ data.cancelCaption }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
