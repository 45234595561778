import {
  Injectable,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'safe',
})
@Injectable({
  providedIn: 'root'
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) { }

  public transform(
    value: any,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl | null {
    const bypass = 'bypassSecurityTrust';

    switch (type) {
      case 'html':
        return this.sanitizer[`${bypass}${'Html'}`](value);

      case 'style':
        return this.sanitizer[`${bypass}${'Style'}`](value);

      case 'script':
        return this.sanitizer[`${bypass}${'Script'}`](value);

      case 'url':
        return this.sanitizer[`${bypass}${'Url'}`](value);

      case 'resourceUrl':
        return this.sanitizer[`${bypass}${'ResourceUrl'}`](value);

      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
