<form
  name="notebookForm"
  [formGroup]="notebookForm"
  novalidate
  (ngSubmit)="
    processNotebookForm(notebookForm.value, notebookForm.valid, $event)
  "
  [fxLayout]="formAlign"
  fxLayoutAlign="{{ formAlign === 'row' ? 'start center' : 'start start' }}"
  fxLayoutGap="30px"
  test-id="notebook-form"
>
  <mat-form-field
    appearance="outline"
    class="no-wrapper"
    [ngClass]="{ w100: formAlign === 'column' }"
  >
    <mat-label>Enter notebook’s name</mat-label>
    <input matInput formControlName="name" test-id="notebook-form-name" />
    <mat-icon matPrefix fontSet="material-icons-outlined">
      description
    </mat-icon>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">
    <button
      type="submit"
      mat-stroked-button
      color="primary"
      test-id="notebook-form-create-button"
    >
      {{ workMode === "create" ? "Create" : "Update" }} notebook
    </button>
    <button
      type="button"
      mat-stroked-button
      color="warn"
      *ngIf="workMode === 'update'"
      (click)="openDeleteConfirmationPopup()"
      test-id="notebook-form-delete-button"
    >
      Delete
    </button>
    <button
      type="button"
      mat-stroked-button
      color="accent"
      *ngIf="workMode === 'update'"
      (click)="cancel()"
      test-id="notebook-form-cancel-button"
    >
      Cancel
    </button>
  </div>
</form>
