import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalVarDialogComponent } from './global-var-dialog.component';

// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Mask
import { NgxMaskModule } from 'ngx-mask';
// Code editor
import { CodeEditorModule } from '../code-editor/code-editor.module';

@NgModule({
  imports: [
    CommonModule,
    // Materail
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatTooltipModule,
    // Layout
    FlexLayoutModule,
    // Form
    ReactiveFormsModule,
    FormsModule,
    // Ngx Mask
    NgxMaskModule.forRoot(),
    // Code Editor
    CodeEditorModule,
  ],
  declarations: [GlobalVarDialogComponent]
})
export class GlobalVarDialogModule { }
