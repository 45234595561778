import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from "./trancate.pipe";
import { PrettyJsonPipe } from "./prettyjson.pipe";
import { HighlightPipe } from './hightlight.pipe';

@NgModule({
  declarations: [SafePipe, TruncatePipe, PrettyJsonPipe, HighlightPipe],
  exports: [SafePipe, TruncatePipe, PrettyJsonPipe, HighlightPipe],
  providers: [SafePipe],
})
export class PipesModule { }
