import {
  DATA_TYPES,
  FILE_EXTENSIONS,
  FILE_EXTENSION_FROM_URL,
  FILE_TYPE,
  OBJECT_TYPES,
  ResponseType,
  VALID_URL,
} from '../constants/general.constants';
import { EHQ, ParserMethod } from '../constants/additional-methods.constants';
import { ArqueroTable } from '../interfaces/arquero-table.interafce';
import { Chunk } from '../interfaces/chunk/chunk.interface';
import { FileItem } from '../interfaces/file.interface';
import { Buffer } from 'buffer';
import { Observable, filter } from 'rxjs';

export const ONE = 1;

export const ZERO = 0;

export const acceptedFileImages: string = 'image/*';
export const acceptedFilesAttachments: string = 'image/*,video/*,.pdf';
export const acceptedFileImport: string =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// Supported files
const videoFiles: string = 'webm,mp4,ogg';
const audioFiles: string = 'mp3,acc,wav';
const imageFiles: string = 'jpg,jpeg,gif,tiff,webp,png';
// this files will be open with google preview
const documentFiles: string = 'doc,docx,xls,xlsx,ppt,pptx';
// this files will be open with iframe
const pdfFiles: string = 'pdf';
const jsFiles: string = `${FILE_EXTENSIONS.js},ts,${FILE_EXTENSIONS.json}`;
const mdFiles: string = 'md';
const pythonFiles: string = 'py';
const csvFiles: string = FILE_EXTENSIONS.csv;
const parquetFile: string = FILE_EXTENSIONS.parquet;
const arrowFile: string = FILE_EXTENSIONS.arrow;
const wasmFile: string = FILE_EXTENSIONS.wasm;
const whlFile: string = FILE_EXTENSIONS.whl;

/** Function for use in Observable pipes to remove null and undefined values (for typescript inferred typing, too) */
export function isNonNullable<T>() {
  return (source$: Observable<null | undefined | T>) =>
    source$.pipe(
      filter((v): v is NonNullable<T> => v !== null && v !== undefined)
    );
}

export class GeneralHelpers {
  static jsonStringify(data: any, replacer?: any) {
    if (typeof data !== 'object' || data === null) {
      const message: string = 'Data is not a valid object';
      console.error(message);
      return data;
    }

    if (Object.keys(data).length === 0) {
      const message: string = 'Object is empty';
      console.error(message);
      return data;
    }

    try {
      const stringifyData: string = JSON.stringify(data, replacer);
      return stringifyData;
    } catch (error) {
      const message: string = 'Cannot stringify data';
      console.error(message);
      return message;
    }
  }

  static jsonParse(data: any): any {
    if (typeof data !== 'string' || data === '') {
      return data;
    }

    try {
      const parsedData: any = JSON.parse(data);
      if (typeof parsedData !== 'object' || parsedData === null) {
        const message: string = 'Parsed data is not a valid object';
        console.error(message);
        return message;
      }
      return parsedData;
    } catch (error) {
      const message: string = 'Cannot parse data';
      console.error(message);
      return message;
    }
  }

  static isJSON(str: string) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  static isEmail(value: string): boolean {
    value = value.trim();
    return EMAIL_REGEXP.test(value);
  }

  static randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static processSearch(seacrh: string = ''): string {
    if (GeneralHelpers.isEmail(seacrh)) {
      return seacrh;
    }
    return seacrh === ''
      ? ''
      : seacrh.replace(/[&\/\\#,+()$~%.'":*?<>{}]/gi, '');
  }

  static removeSpaces(val: string) {
    return val.trim();
  }

  static filterNullObject(object: any) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (object[key] === null) {
          delete object[key];
        }
      }
    }
    return object;
  }

  static randomNumber() {
    return Math.floor(Math.random() * 100);
  }

  static randomDate(start: any = new Date(2022, 0, 1), end: any = new Date()) {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  }

  static resetForm(context: any, formName: string): void {
    if (context[formName] === undefined) {
      return;
    }
    context[formName].reset();
    for (const control in context[formName]?.controls) {
      if (
        Object.prototype.hasOwnProperty.call(
          context[formName]?.controls,
          control
        )
      ) {
        context[formName].controls[control]?.setErrors(null);
        context[formName].controls[control]?.markAsUntouched();
        context[formName].controls[control]?.markAsPristine();
      }
    }
  }

  static sortArrayOfObjects<T>(
    arr: Array<T>,
    order: 'asc' | 'desc',
    prop: string
  ): Array<T> {
    if (!Array.isArray(arr) || arr.length === 0) {
      return [];
    }

    arr.sort((first: any, second: any) => {
      const isFirstValueMoreSecondValue = first[prop] > second[prop];
      const isSecondValueMoreFirstValue = second[prop] > first[prop];
      const sortToAsc = isFirstValueMoreSecondValue
        ? 1
        : isSecondValueMoreFirstValue
        ? -1
        : 0;
      const sortToDesc = isFirstValueMoreSecondValue
        ? -1
        : isSecondValueMoreFirstValue
        ? 1
        : 0;

      return order === 'asc' ? sortToAsc : sortToDesc;
    });

    return arr;
  }

  static findElementInArrayByProp(
    data: any[],
    prop: string,
    value: string | any
  ) {
    return data.find((item) => item[prop] === value);
  }

  static findIndexInArrayByProp(
    data: any[],
    prop: string,
    value: string | any
  ) {
    return data.findIndex((item) => item[prop] === value);
  }

  static processFileTreeActiveNodes(data: any, property: any): any[] {
    for (let i = 0; i < data.length; i++) {
      data[i].isActive = property;
      if (Array.isArray(data[i].children) && data[i].children.length > 0) {
        GeneralHelpers.processFileTreeActiveNodes(data[i].children, property);
      }
    }
    return data;
  }

  static findNode(id: any, data: any[]) {
    for (const node of data) {
      if (node.fsitemId === id) return node;
      if (node.children) {
        const child: any = GeneralHelpers.findNode(id, node.children);
        if (child) return child;
      }
    }
  }

  static detectHtmlTag(data: string): boolean {
    // Detect any type of html tag
    const parsedDOM = new DOMParser().parseFromString(data, 'text/html');
    return Array.from(parsedDOM.body.childNodes).some(
      (node) => node.nodeType === 1
    );
  }

  static detectWord(data: string): boolean {
    // Detect word from 3 and more charactes separated by spaces
    const regex = /(^|\s)\w{3,}(?=\s|$)/gi;
    return regex.test(data);
  }

  static detectGlobalVariable(data: string) {
    const matchDataRegex = /data(.*?)(\w+|\d+)/gi;
    let usedVariableList = [];
    const splitData = 'data.';
    if (matchDataRegex.test(data)) {
      const usedVariablesRaw: string[] | any = data.match(matchDataRegex)
        ? data.match(matchDataRegex)
        : [];
      if (usedVariablesRaw?.length === 0) {
        return false;
      }
      for (let index = 0; index < usedVariablesRaw.length; index++) {
        const element = usedVariablesRaw[index]?.match(matchDataRegex);
        if (element?.length === 1) {
          let matchedVariable = element[0];
          matchedVariable = matchedVariable.replace(splitData, '');
          matchedVariable =
            GeneralHelpers.replaceSpecialCharacters(matchedVariable);
          matchedVariable = matchedVariable.trim();
          usedVariableList.push(matchedVariable);
        }
      }
    }
    if (usedVariableList?.length > 0) {
      usedVariableList = [...new Set(usedVariableList)];
      return usedVariableList;
    }
    return false;
  }

  static replaceSpecialCharacters(data: string): string {
    return data.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }

  static getArrayIntersection(
    originalArray: any[],
    checkedArray: any[]
  ): any[] {
    const setA = new Set(originalArray);
    const setB = new Set(checkedArray);
    const intersection = new Set([...setA].filter((x) => setB.has(x)));
    return Array.from(intersection);
  }

  static detectEmptyObject(data: any): boolean {
    if (data === null || typeof data !== 'object') {
      return false;
    }
    return (
      data &&
      Object.keys(data).length === 0 &&
      Object.getPrototypeOf(data) === Object.prototype
    );
  }

  static getUuid() {
    return URL.createObjectURL(new Blob()).substr(-36);
  }

  static isValidUrl(url: string): boolean {
    return VALID_URL.test(url);
  }

  static replaceAll(string: string, search: string, replace: string) {
    return string.replaceAll(`^${search}`, `${replace}`);
  }

  static getInterceptorSkipHeader() {
    return 'X-Skip-Interceptor';
  }

  static getNameWithExtension(url: string): string {
    //TODO Dirty hack for JsDelivrCDN. need to be refactored;
    const possibleExtension: any = url.split('.').slice(-1)[0];
    const isNumber = isNaN(possibleExtension);
    const defaultFileExtension = 'js';
    const nameWithExtension = url.substring(url.lastIndexOf('/') + 1);
    return isNumber
      ? nameWithExtension
      : `${nameWithExtension}.${defaultFileExtension}`;
  }

  static trueTypeOf(data: any) {
    /**
     * trueTypeOf([]); // array
       trueTypeOf({}); // object
       trueTypeOf(''); // string
       trueTypeOf(new Date()); // date
       trueTypeOf(1); // number
       trueTypeOf(function () {}); // function
       trueTypeOf(/test/i); // regexp
       trueTypeOf(true); // boolean
       trueTypeOf(null); // null
       trueTypeOf(); // undefined
     */
    return {}.toString.call(data).slice(8, -1).toLowerCase();
  }

  static fileNameFromString(data: string) {
    return data.substr(0, data.lastIndexOf('.'));
  }

  static fileExtensionFromString(data: string): string {
    /**
     *
    (?:         # begin non-capturing group
     \.         #   a dot
      (         #   begin capturing group (captures the actual extension)
       [^.]+    #     anything except  a dot, multiple times
      )         #   end capturing group
    )?          # end non-capturing group, make it optional
    $           # anchor to the end of the string
     */
    // ────────────────────────────────────────────────────────────────────────────────

    const regexp: RegExp = FILE_EXTENSION_FROM_URL;
    const matched: RegExpExecArray | any = regexp.exec(data);
    if (!Array.isArray(matched) || matched.length === 0) {
      console.error('Cannot parse url');
      return '';
    }

    return matched[1];
  }

  static formatBytes(bytes: number, decimals = 2) {
    if (typeof bytes === 'string') return bytes;
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static replaceGlobals(data: string, list: any[]): string {
    if (Array.isArray(list) && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (data.indexOf(element.name) > -1) {
          data = GeneralHelpers.replaceAll(data, element.name, element.value);
        }
      }
    }
    return data;
  }

  static getFileType(fileExtension: string) {
    const fileExtensionRegex = new RegExp(fileExtension, 'i');
    let fileType: string = '';
    if (fileExtensionRegex.test(videoFiles)) {
      fileType = FILE_TYPE.video;
    } else if (fileExtensionRegex.test(audioFiles)) {
      fileType = FILE_TYPE.audio;
    } else if (fileExtensionRegex.test(imageFiles)) {
      fileType = FILE_TYPE.image;
    } else if (fileExtensionRegex.test(documentFiles)) {
      fileType = FILE_TYPE.document;
    } else if (fileExtensionRegex.test(pdfFiles)) {
      fileType = FILE_TYPE.pdf;
    } else if (fileExtensionRegex.test(jsFiles)) {
      fileType = FILE_TYPE.javascript;
    } else if (fileExtensionRegex.test(mdFiles)) {
      fileType = FILE_TYPE.md;
    } else if (fileExtensionRegex.test(pythonFiles)) {
      fileType = FILE_TYPE.python;
    } else if (fileExtensionRegex.test(csvFiles)) {
      fileType = FILE_TYPE.csv;
    } else if (fileExtensionRegex.test(parquetFile)) {
      fileType = FILE_TYPE.parquet;
    } else if (fileExtensionRegex.test(arrowFile)) {
      fileType = FILE_TYPE.arrow;
    } else if (fileExtensionRegex.test(wasmFile)) {
      fileType = FILE_TYPE.wasm;
    } else if (fileExtensionRegex.test(whlFile)) {
      fileType = FILE_TYPE.whl;
    }

    return fileType;
  }

  static blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve((reader.result as any).split(',')[1]);
      reader.readAsDataURL(blob);
    });
  }

  static blobToBase64WithFileItem(blob: any, fileItem: FileItem) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve({
          base64: (reader.result as any).split(',')[1],
          fileItem: fileItem,
        });
      reader.readAsDataURL(blob);
    });
  }

  static typedArrayToBase64(buffer: ArrayBuffer | ArrayBufferView): string {
    let binary = '';
    let bytes: Uint8Array;

    if (ArrayBuffer.isView(buffer)) {
      bytes = new Uint8Array(
        buffer.buffer,
        buffer.byteOffset,
        buffer.byteLength
      );
    } else {
      bytes = new Uint8Array(buffer);
    }

    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static getMimeType(fileType: any, fileExtension: any = '*') {
    if (fileType === FILE_TYPE.pdf) {
      fileType = 'application';
    }

    return `data:${fileType}/${fileExtension};base64,`;
  }

  static prepareTableFromArqueroJSON(arqueroTable: ArqueroTable): any {
    if (!arqueroTable || !arqueroTable.data || !arqueroTable.schema) {
      return;
    }

    if (
      GeneralHelpers.detectEmptyObject(arqueroTable.data) ||
      GeneralHelpers.detectEmptyObject(arqueroTable.schema)
    ) {
      return;
    }

    const { data, schema } = arqueroTable;

    // Initialize an array to hold the transformed rows
    let transformedData: any = [];

    // Extract field names from the schema
    const fields = schema.fields.map((field) => field.name);

    // Assuming each array in data has the same length
    const dataLength = data[fields[0]].length;

    for (let i = 0; i < dataLength; i++) {
      let row: any = {};
      fields.forEach((field) => {
        row[field] = data[field][i];
      });
      transformedData.push(row);
    }

    // Generate columns based on the first row of the transformed data
    const columns = fields.map((field) => ({
      name: `${field} (${typeof transformedData[0][field]})`,
      dataKey: field,
      isSortable: true,
    }));

    return {
      data: transformedData,
      columns,
    };
  }

  static addTableColumns(data: any[]): any {
    const columns: any[] = [];

    if (data.length === 0) {
      return { data, columns };
    }

    for (const key in data[0]) {
      if (data[0].hasOwnProperty(key)) {
        const columnHeader = this.generateColumnHeader(key, data[0][key]);
        columns.push({
          name: columnHeader,
          dataKey: key,
          isSortable: true,
        });
      }
    }

    return {
      data: data,
      columns: columns,
    };
  }

  private static generateColumnHeader(key: string, sampleData: any): string {
    if (!key) {
      return 'Unknown';
    }

    const dataType = typeof sampleData;
    switch (dataType) {
      case 'number':
        return `${key} (Number)`;
      case 'string':
        return `${key} (String)`;
      case 'boolean':
        return `${key} (Boolean)`;
      default:
        return key;
    }
  }

  static fromMapToObject(map = new Map()): Object {
    if (!(map instanceof Map)) return map;
    return Object.fromEntries(
      Array.from(map.entries(), ([k, v]) => {
        if (v instanceof Array) {
          return [k, v.map(GeneralHelpers.fromMapToObject)];
        } else if (v instanceof Map) {
          return [k, GeneralHelpers.fromMapToObject(v)];
        } else {
          return [k, v];
        }
      })
    );
  }

  static jsonToCsv(items: object[]): string {
    const header = Object.keys(items?.[0] ?? {});
    const headerString = header.join(',');
    // handle null or undefined values here
    const replacer = (_: any, value: string) => value ?? '';
    const rowItems = items.map((row: any) =>
      header
        .map((fieldName) =>
          GeneralHelpers.jsonStringify(row[fieldName], replacer)
        )
        .join(',')
    );
    // join header and body, and break into separate lines
    const csv = [headerString, ...rowItems].join('\r\n');

    return csv;
  }

  static convertPapaparseToArquero(
    inputArray: string[][]
  ): { [key: string]: string | number | null }[] {
    if (!inputArray?.length) {
      return inputArray as any;
    }
    const header = inputArray[0].map((cell) => {
      return cell.replaceAll('"', '').trim();
    });
    let data = inputArray.slice(1);

    data = data.filter((row) => row?.length !== 1 && row[0] !== '');

    return data.map((row) => {
      const obj: { [key: string]: string | number | null } = {};
      row.forEach((cell, index) => {
        cell = cell.replace(/[\n\r]/g, '').trim();
        if (!!cell) {
          obj[header[index]] =
            cell !== '' ? (isNaN(Number(cell)) ? cell : Number(cell)) : null;
        }
      });
      return obj;
    });
  }

  static getCustomMethods(
    content: string
  ): { method: string; fileName: string }[] {
    const methodPattern = new RegExp(
      `${EHQ}\\.([\\w]+)\\(['"]?([^'")]+)['"]?\\)`,
      'g'
    );
    const matches = content.matchAll(methodPattern);
    const result = [];

    for (const match of matches) {
      const [_, methodName, fileName] = match;
      result.push({ method: methodName, fileName });
    }

    return result;
  }

  static arrayBufferToBase64(buffer: any) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static arrayBufferToNumberArray(buffer: any) {
    let reader = new FileReader();

    reader.readAsArrayBuffer(buffer);
  }

  static base64ToArrayBuffer(base64: any) {
    let binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  static blobBuilder(data: string) {
    let blob;
    try {
      blob = new Blob([data], { type: 'application/javascript' });
    } catch (e) {
      (window as any).BlobBuilder =
        (window as any).BlobBuilder ||
        (window as any).WebKitBlobBuilder ||
        (window as any).MozBlobBuilder;
      blob = new (window as any).BlobBuilder();
      blob.append(data);
      blob = blob.getBlob();
    }
    return blob;
  }

  /**
   * Function to wait for predicates.
   * @param {function() : Boolean} predicate - A function that returns a bool
   * @param {Number} [timeout] - Optional maximum waiting time in ms after rejected
   */
  static waitFor(predicate: any, timeout: number) {
    return new Promise((resolve, reject) => {
      const check = () => {
        if (!predicate()) return;
        clearInterval(interval);
        resolve(true);
      };
      const interval = setInterval(check, 100);
      check();

      if (!timeout) return;
      setTimeout(() => {
        clearInterval(interval);
        reject();
      }, timeout);
    });
  }

  static reorderAfterCreate(
    chunnkList: Chunk[],
    selectedChunkId: number,
    createdChunkId: number
  ): number[] {
    const chunkIds = chunnkList.map((chunk) => chunk.chunkId);
    let selectedChunkIndex = chunkIds.indexOf(selectedChunkId);
    const createdChunkIndex = chunkIds.indexOf(createdChunkId);
    const element = chunkIds.splice(createdChunkIndex, 1)[0];
    chunkIds.splice(selectedChunkIndex + 1, 0, element);
    return chunkIds;
  }

  static bytesFromHex(str: string, pad?: number): number[] {
    str = str.toString();
    if (str.length % 2) str = '0' + str;
    let bytes = str.match(/../g)?.map((s: string) => parseInt(s, 16)) || [];
    if (pad) for (let i = bytes.length; i < pad; ++i) bytes.unshift(0);
    return bytes;
  }

  static blobToBuffer(blob: Blob): Promise<Buffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const { result } = reader;
        if (result instanceof ArrayBuffer) {
          resolve(Buffer.from(result));
        } else {
          reject(new Error('Could not convert Blob to Buffer'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  static getUniqueGlobalNames(data: ParserMethod[]) {
    const globalNames = data.map((item) => item.globalName);
    const uniqueGlobalNames = [...new Set(globalNames)];
    return uniqueGlobalNames;
  }

  static generateProjectName(): string {
    const adjectives = ['Red', 'Swift', 'Brave', 'Mighty', 'Sly', 'Quiet'];
    const animals = ['Fox', 'Lion', 'Bear', 'Owl', 'Hawk', 'Wolf'];

    // Randomly pick an adjective and an animal
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const animal = animals[Math.floor(Math.random() * animals.length)];

    // Return combined name
    return `${adjective}${animal}`;
  }

  static fileTypeFromFsItem(node: FileItem): string {
    if (node.fileType) {
      return node.fileType;
    }
    const fileExtension = GeneralHelpers.fileExtensionFromString(node.name);
    const fileType = GeneralHelpers.getFileType(fileExtension);
    return fileType;
  }

  static arrayOfObjectsUniqueByKey(array: any[], key: string) {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  }

  static getResponseType(fileType: string | undefined): string {
    let responseType = ResponseType.Text;
    switch (fileType) {
      case FILE_TYPE.javascript:
      case FILE_TYPE.python:
      case FILE_TYPE.csv:
        responseType = ResponseType.Text;
        break;
      case FILE_TYPE.parquet:
      case FILE_TYPE.arrow:
        responseType = ResponseType.ArrayBuffer;
        break;
      case FILE_TYPE.image:
      case FILE_TYPE.pdf:
      case FILE_TYPE.video:
      case FILE_TYPE.audio:
        responseType = ResponseType.Blob;
        break;

      default:
        responseType = ResponseType.Text;
        break;
    }
    return responseType;
  }

  static arrayBufferToString(buffer: ArrayBuffer) {
    var bufView: any = new Uint16Array(buffer);
    var length = bufView.length;
    var result = '';
    var addition = Math.pow(2, 16) - 1;

    for (var i = 0; i < length; i += addition) {
      if (i + addition > length) {
        addition = length - i;
      }
      result += String.fromCharCode.apply(
        null,
        bufView.subarray(i, i + addition)
      );
    }

    return result;
  }

  static isBase64(str: any) {
    const regex =
      /^\s*data:(image\/(png|jpeg|gif|bmp|webp));base64,([A-Za-z0-9+/]+={0,2})\s*$/;
    return regex.test(str);
  }

  static base64ToBlob(base64Image: string) {
    const base64ImageSplit = base64Image.split(',');
    const mimeType = base64ImageSplit[0].split(':')[1].split(';')[0];
    const data = base64ImageSplit[1];
    const byteCharacters = atob(data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    const blob = new Blob(byteArrays, { type: mimeType });
    return blob;
  }

  static numberArrayToBuffer(array: number[]) {
    return Buffer.from(array);
  }

  static fileFromBuffer(buffer: Buffer, fileName: string) {
    return new File([buffer], fileName);
  }

  static isArrowTable(obj: any) {
    // Check for the basic structure of an Arrow table
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    // Check for the presence of key properties: 'schema', 'batches', and '_offsets'
    const hasSchema = 'schema' in obj;
    const hasBatches = 'batches' in obj && Array.isArray(obj.batches);
    const hasOffsets = '_offsets' in obj;

    if (!hasSchema || !hasBatches || !hasOffsets) {
      return false;
    }

    // Further checks can be added here for more robust validation
    // For example, check the structure of each batch, verify schema format, etc.

    return true;
  }

  static isStringArray(str: string) {
    try {
      // Attempt to parse the string as JSON
      const parsed = JSON.parse(str);

      // Check if the parsed object is an array
      return Array.isArray(parsed);
    } catch (e) {
      // If an error occurs during parsing, the string is not valid JSON
      return false;
    }
  }

  static containsFilename(str: string) {
    if (typeof str !== 'string') return false;
    // Regular expression to match a filename with an extension
    // This pattern looks for any character sequence that ends with a dot followed by alphanumeric characters
    const regex = /[\w,\s-]+\.[A-Za-z0-9]+$/;
    return regex.test(str);
  }

  static isCSV(str: string): boolean {
    // First, check if the input is a byte string
    if (GeneralHelpers.canBeParsedToNumberArray(str)) {
      // Convert byte string to actual string
      try {
        const bytes = str.split(',').map(Number);
        str = new TextDecoder('utf-8').decode(new Uint8Array(bytes));
      } catch (error) {
        return false;
      }
    }

    // Now we have a string, either from direct input or converted from byte string
    // Split the string into lines
    const lines = str.trim().split('\n');

    // Check if there are at least two lines (header + data)
    if (lines.length < 2) return false;

    // Check if all lines have the same number of fields
    const fieldCounts = lines.map((line) => line.split(',').length);
    if (new Set(fieldCounts).size !== 1) return false;

    // Check if the first line (header) contains only string fields
    const headerFields = lines[0].split(',');
    if (headerFields.some((field) => !isNaN(Number(field.trim()))))
      return false;

    // Check at least one data line
    const dataLine = lines[1].split(',');
    if (dataLine.every((field) => field.trim() === '')) return false;

    // If all checks pass, it's likely a CSV
    return true;
  }

  static canBeParsedToNumberArray(str: string): boolean {
    if (typeof str !== 'string') return false;
    // Split the string by commas and check if every element is a valid number
    return str.split(',').every((element) => {
      const num = parseFloat(element.trim());
      return !isNaN(num) && isFinite(num);
    });
  }

  static isParquetFile(byteArray: Uint8Array) {
    const magicNumber = 'PAR1';
    const magicNumberLength = 4;

    // Convert magic number to byte array for comparison
    const magicNumberBytes = new TextEncoder().encode(magicNumber);

    // Check the start of the file
    if (
      !byteArray
        .subarray(0, magicNumberLength)
        .every((val, index) => val === magicNumberBytes[index])
    ) {
      return false;
    }

    // Check the end of the file
    if (
      !byteArray
        .subarray(byteArray.length - magicNumberLength)
        .every((val, index) => val === magicNumberBytes[index])
    ) {
      return false;
    }

    return true;
  }

  static async convertToUint8Array(data: any, dataType: FILE_TYPE) {
    const encodeStringToUint8Array = (str: string) => {
      const encoder = new TextEncoder();
      return encoder.encode(data);
    };
    const encodeFileToUint8Array = async (data: Blob | File) => {
      return new Uint8Array(await data.arrayBuffer());
    };

    if (typeof data === 'string') {
      return encodeStringToUint8Array(data);
    }

    if (data instanceof Blob || data instanceof File) {
      return encodeFileToUint8Array(data);
    }

    if (data instanceof Uint8Array) {
      return data;
    }

    console.warn(`Unsupported data type: ${dataType}`);

    return null;
  }

  static stringToUint8Array(data: string) {
    const numbericArray = data.split(',').map(Number);
    return new Uint8Array(numbericArray);
  }

  static getDataType(dtype: number) {
    let dtype_s = 'unknown';
    switch (dtype) {
      case DATA_TYPES.na:
        dtype_s = 'na';
        break;
      case DATA_TYPES.bool:
        dtype_s = 'bool';
        break;
      case DATA_TYPES.uint8:
        dtype_s = 'uint8';
        break;
      case DATA_TYPES.int32:
        dtype_s = 'int32';
        break;
      case DATA_TYPES.float:
        dtype_s = 'float';
        break;
      case DATA_TYPES.double:
        dtype_s = 'double';
        break;
      case DATA_TYPES.string:
        dtype_s = 'string';
        break;
      case DATA_TYPES.binary:
        dtype_s = 'binary';
        break;
      case DATA_TYPES.timestamp:
        dtype_s = 'timestamp';
        break;
      case DATA_TYPES.list:
        dtype_s = 'list';
        break;
    }
    return dtype_s;
  }

  static getObjectType(otype: number) {
    let otype_s = 'unknown';
    switch (otype) {
      case OBJECT_TYPES.scalar:
        otype_s = 'scalar';
        break;
      case OBJECT_TYPES.array:
        otype_s = 'array';
        break;
      case OBJECT_TYPES.tensor:
        otype_s = 'tensor';
        break;
      case OBJECT_TYPES.table:
        otype_s = 'table';
        break;
    }
    return otype_s;
  }

  // Encode a JS string as UTF-8 bytes
  static toUTF8Array(str: string) {
    // https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array
    let utf8 = [];
    for (let i = 0; i < str.length; i++) {
      let charcode = str.charCodeAt(i);
      if (charcode < 0x80) utf8.push(charcode);
      else if (charcode < 0x800) {
        utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
      } else if (charcode < 0xd800 || charcode >= 0xe000) {
        utf8.push(
          0xe0 | (charcode >> 12),
          0x80 | ((charcode >> 6) & 0x3f),
          0x80 | (charcode & 0x3f)
        );
      } else {
        i++;
        charcode =
          0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
        utf8.push(
          0xf0 | (charcode >> 18),
          0x80 | ((charcode >> 12) & 0x3f),
          0x80 | ((charcode >> 6) & 0x3f),
          0x80 | (charcode & 0x3f)
        );
      }
    }
    return utf8;
  }

  static globalVarSanitize(data: any): string {
    if (typeof data === 'string') {
      // Remove leading and trailing quotes
      let sanitizedData = data.replace(/^"|"$/g, '');
      return sanitizedData;
    }
    return data;
  }
}
