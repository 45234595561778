import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CdnJsModuleResult, JsDelivrModuleResult, PyPiModuleResult } from '../../interfaces/cdn-module.interface'
import { map, Observable } from 'rxjs';
import { GeneralHelpers } from '../../helpers/general.helper';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CdnModuleService {

  constructor(private http: HttpClient) { }

  public getFromCdnJs(search: string): Observable<CdnJsModuleResult> {
    const headerData: any = {};
    headerData[GeneralHelpers.getInterceptorSkipHeader()] = '';
    const headers = new HttpHeaders(headerData);
    return this.http.get<CdnJsModuleResult>(
      `https://api.cdnjs.com/libraries?search=${search}&fields=version`, {
      headers
    }
    ).pipe(map((res: any) => {
      if (res === null) {
        return;
      }
      return res;
    }));
  }

  public getFromJsDelivr(search: string): Observable<JsDelivrModuleResult> {
    const headerData: any = {};
    headerData[GeneralHelpers.getInterceptorSkipHeader()] = '';
    const headers = new HttpHeaders(headerData);
    return this.http.get<JsDelivrModuleResult>(
      `https://data.jsdelivr.com/v1/packages/npm/${search}`, {
      headers
    }
    ).pipe(map((res: any) => {
      if (res === null) {
        return;
      }
      return res;
    }));
  }

  public getFromPyPi(pkgName: string = "") {
    const headerData: any = { 'Accept': 'application/vnd.pypi.simple.v1+json' };
    const pypiUrlRequest = pkgName ? `${environment.origin}/pypi/simple/${pkgName}/` : 'https://pypi.org/simple/';
    headerData[GeneralHelpers.getInterceptorSkipHeader()] = '';
    const headers = new HttpHeaders(headerData);

    return this.http.get<PyPiModuleResult>(pypiUrlRequest, { headers }).pipe(map((res: any) => {
      return res ? res : null;
    }));
  }

}
