<div class="row">
  <div class="col dataset-info">
    <div class="card m-2">
      <div class="card-body">
      <div class="row align-items-baseline">
        <div class="col p-2">
          <strong>Data Set:</strong>
        </div>
        <div class="col p-2">
          <div class="input-group">
          <select class="form-select dataset-select" (change)="selectDataSet($event)" [selectedIndex]="selectedOption()">
            <option value="" disabled selected>Select Data Set</option>
            <option *ngFor="let dataSet of dataSets" [value]="dataSet">{{ dataSet.name }}</option>
          </select>
          <button (click)="loadDataSets()" class="btn"><mat-icon>sync</mat-icon></button>
          </div>
        </div>
      </div>
      <div class="row align-items-baseline">
        <div class="col p-2">
          <strong>dataset_id:</strong>
        </div>
        <div class="col p-2">
        {{ selectedDataSet?.dataset_id }}
      </div>
      </div>
      <div class="row align-items-baseline">
        <div class="col p-2">
          <strong>base_path:</strong>
        </div>
        <div class="col p-2">
          {{ selectedDataSet?.name }}
      </div>
      </div>
      <div class="row align-items-baseline">
        <div class="col p-2">
          <strong>bucket_name</strong>:
        </div>
        <div class="col p-2">
          {{ selectedDataSet?.bucket_name }}
      </div>
      </div>
      <div class="row align-items-baseline">
        <div class="col p-2">
          <strong>batch_size:</strong>
        </div>
        <div class="col p-2">
          {{ selectedDataSet?.batch_size }}
        </div>
      </div>
      <div class="row align-items-top">
        <div class="col p-2">
          <strong>partition_cols:</strong>
        </div>
        <div class="col p-2">
          @if (selectedDataSet && selectedDataSet.partitionCols) {
            <div *ngFor="let partitionCol of selectedDataSet.partitionCols">
              <div>{{ partitionCol[0] }} ({{ partitionCol[1] }})</div>
            </div>
          }
        </div>
      </div>
      <div class="row align-items-baseline">
        <div class="col p-2">
          &nbsp;
        </div>
        <div class="col p-2">
          <button (click)="deleteSelectedDataSet()" class="btn btn-sm btn-primary" [disabled]="selectedOption() === 0">Delete Data Set</button>
        </div>
      </div>

      </div>
    </div>
  </div>

  @if (selectedDataSet) {
  <div class="col m-2 dataset-info">
    <tabset class="dataset-tabset">
      <tab heading="Schema">
        @if (currentSchema) {
          <arrow-schema-viewer [schema]="currentSchema"></arrow-schema-viewer>
        } @else {
          <div class="alert alert-info">No schema available</div>
        }
      </tab>
      <tab heading="Views">
        <arrow-views-list [views]="currentViews()" [dataset]="selectedDataSet" (deleteView)="deleteView($event)"></arrow-views-list>
      </tab>
    </tabset>
  </div>
  }
</div>
