import { Injectable, inject } from '@angular/core';

import {
  BOOK,
  NOTEBOOK,
  PROJECT,
  API_BASE,
  NOTEBOOK_ID,
  NOTEBOOK_LIST,
  VERSION,
} from '../../constants/general.constants';

import { EMPTY, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  NewNotebook,
  Notebook,
  NotebookCreate,
  NotebookUpdate,
} from '../../interfaces/project.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { LocalstorageHelper } from '../../helpers/localstorage.helper';
import { AppStateService } from '../app-state/app-state.service';

@Injectable({
  providedIn: 'root',
})
export class NotebookService {
  #appState = inject(AppStateService);

  public $setNotebookIdSubject = new Subject<string>();

  public notebookList: Notebook[] = [];

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create Notebook

  public create(
    data: NotebookCreate,
    projectId: string | null
  ): Observable<NewNotebook | never> {
    if (projectId === null) {
      return EMPTY;
    }
    return this.http.post<NewNotebook>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${NOTEBOOK}`,
      data
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get by id

  public getById(notebookId: string, projectId: string): Observable<Notebook> {
    return this.http.get<Notebook>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}`
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update

  public update(
    data: NotebookUpdate,
    notebookId: string | null,
    projectId: string | null
  ): Observable<Object> {
    if (notebookId === null || projectId === null) {
      return EMPTY;
    }
    return this.http.put<Notebook>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}`,
      data
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Delete

  public delete(
    notebookId: string | null,
    projectId: string | null
  ): Observable<Object> {
    if (notebookId === null && projectId === null) {
      return EMPTY;
    }
    return this.http.delete<Notebook>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}`
    );
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Set Active Notebook Id

  public setNotebookId(id: number) {
    if (typeof id === 'number') {
      this.#appState.navigateToNotebook(id);
      this.$setNotebookIdSubject.next(`${id}`);
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Get Notebook List

  public getNotebookList(): Notebook[] | any {
    let notebookList = LocalstorageHelper.getNotebookList();

    if (notebookList !== null) {
      notebookList = GeneralHelpers.jsonParse(notebookList);
      return notebookList;
    }

    return [];
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get selected notebook

  public getSelectedNotebook(): Notebook | any {
    const notebookId = this.#appState.notebookId().toString();
    const notebookList = this.getNotebookList();
    let selectedNotebook: Notebook | any = {};

    if (notebookId !== '' && notebookList.length > 0) {
      for (let index = 0; index < notebookList.length; index++) {
        const element = notebookList[index];

        if (notebookId == element.notebookId) {
          selectedNotebook = element;
          break;
        }
      }
    }

    if (notebookId === '' && notebookList.length > 0) {
      selectedNotebook = notebookList[0];
      this.setNotebookId(selectedNotebook.notebookId);
    }

    return selectedNotebook;
  }
}
