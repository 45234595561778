export const MARKDOWN_ACTIONS = [
  {
    icon: 'format_bold',
    action: 'bold',
    tooltip: 'bold',
    color: 'primary',
    aria: 'bold',
  },
  {
    icon: 'format_italic',
    action: 'italic',
    tooltip: 'italic',
    color: 'primary',
    aria: 'italic',
  }, {
    icon: 'title',
    action: 'heading',
    tooltip: 'heading',
    color: 'primary',
    aria: 'heading',
  }, {
    icon: 'format_quote',
    action: 'reference',
    tooltip: 'reference',
    color: 'primary',
    aria: 'reference',
  }, {
    icon: 'link',
    action: 'link',
    tooltip: 'link',
    color: 'primary',
    aria: 'link',
  }, {
    icon: 'image',
    action: 'image',
    tooltip: 'image',
    color: 'primary',
    aria: 'image',
  }, {
    icon: 'list',
    action: 'ul',
    tooltip: 'ul',
    color: 'primary',
    aria: 'ul',
  }, {
    icon: 'format_list_numbered',
    action: 'ol',
    tooltip: 'ol',
    color: 'primary',
    aria: 'ol',
  }, {
    icon: 'code',
    action: 'code',
    tooltip: 'code',
    color: 'primary',
    aria: 'code',
  }
]